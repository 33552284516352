import OwnerModel from './OwnerModel'
import Instance from '@/models/Instance'

export default class Format extends OwnerModel {
  static entity = 'externalFormat'

  static primaryKey = ['instance_id', 'external_format_id']

  static fields () {
    return {
      ...super.fields(),
      external_format_id: this.number(null).nullable(),
      __type: this.attr('external_format'),
      type: this.string(null).nullable(),
      format_options: this.string(null).nullable(),
      creation_time: this.string(null).nullable(),
      modify_time: this.string(null).nullable(),
      can_alter: this.boolean(false).nullable(),
      can_drop: this.boolean(false).nullable(),
      can_usage: this.boolean(false).nullable()
    }
  }

  get id() {
    return this.external_format_id
  }

  get format_type() {
    return this.type
  }
}
