import { defineComponent } from 'vue'
import d3 from '@/lib/d3'
import { dom, Logger } from '@/util'
import './tooltipService.css'

const log = Logger.get('yb.tooltip')

function createTooltipService() {
  // Get the tip element (singleton).
  let tipElement = window.document.querySelector('#yb-tooltip')
  let tip
  if (!tipElement) {
    tip = d3.select('body')
      .append('div')
      .attr('id', 'yb-tooltip')
      .attr('class', 'yb-tooltip')
      .style('opacity', 1e-6)
      .style('display', 'none')
    tipElement = tip.node()
  } else {
    tip = d3.select(tipElement)
  }

  tip.cleanup = function cleanup() {
    dom.removeChildren(tip.node())
    tip.tipComponent = null
    tip.ComponentConstructor = null
  }

  tip.setup = function setup(component, propsData) {
    if (tip.ComponentConstructor) {
      return
    }

    // Create the render component.
    tip.ComponentConstructor = defineComponent(component)
  }

  tip.update = function update(propsData) {
    if (tip.tipComponent) {
      dom.removeChildren(tip.node())
      tip.tipComponent = null
    }
    // Create the component and mount it.
    const containerElement = document.createElement('div')
    const { component } = this.$renderComponent(containerElement, tip.ComponentConstructor, propsData || {})
    tip.tipComponent = component
    tip.node().appendChild(tip.tipComponent.$el)
  }

  tip.setElement = function setElement(el) {
    dom.removeChildren(tip.node())
    tip.node().appendChild(el)
  }

  tip.reparent = function reparent(event) {
    const tipParent = event.target.closest('*[yb-tooltip-target]')
    if (tipParent) {
      tipParent.appendChild(tip.node())
    } else {
      document.body.appendChild(tip.node())
    }
  }

  // http://javascript.info/tutorial/coordinates
  function getOffsetRect(elem) {
    // (1)
    const box = elem.getBoundingClientRect()

    const body = document.body
    const docElem = document.documentElement

    // (2)
    const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft

    // (3)
    const clientTop = docElem.clientTop || body.clientTop || 0
    const clientLeft = docElem.clientLeft || body.clientLeft || 0

    // (4)
    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return { top: Math.round(top), left: Math.round(left), height: box.height, width: box.width }
  }

  tip.adjustTip = function adjustTip(event) {
    log.debug('ADJUST BEGIN: ', event.pageX, ',', event.pageY)

    // Default left, top
    let left = event.pageX + 0
    let top = event.pageY + 0
    const element = tip.node()

    // Calculate parent rect.
    let parentRect
    const windowRect = {
      left: 0,
      top: 0,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    }
    let xOffset = 0
    let yOffset = 0
    if (element.parentNode !== document.body) {
      parentRect = getOffsetRect(element.parentNode)
      parentRect.left -= element.parentNode.offsetLeft
      parentRect.top -= element.parentNode.offsetTop
      xOffset = 10
      yOffset = 10
    } else {
      parentRect = windowRect
    }

    // Adjust for off-screen
    const width = element.offsetWidth
    const height = element.offsetHeight
    log.debug('ELEMENT DIM: ', width, ',', height, 'WINDOW DIM:', windowRect.width, ',', windowRect.height)
    left = Math.min(left + 5, windowRect.width - width - 10 - xOffset)
    top = Math.min(top + 5, windowRect.height - height - 10 - yOffset)
    left -= parentRect.left
    top -= parentRect.top
    log.debug('ADJUST END: ', left, ',', top)

    // Position tooltip
    tip.style('left', left + 'px').style('top', top + 'px')
  }

  tip.attach = function attach(vm, node, component, dataFn, itemName, delay) {
    tip.cleanup()
    tip.setup(component)

    function mouseover(event) {
      if (event.ctrlKey) {
        return
      }
      tip.reparent(event)
      tip.style('display', 'block')
      tip
        .transition()
        .delay(delay || 0)
        .duration(500)
        .style('opacity', 1)
        .on('end', () => {
          vm.$emit('smcTooltip.show')
        })
      vm.$emit('smcTooltip.show')
    }

    function mousemove(event, d) {
      if (event.ctrlKey) {
        return
      }
      log.debug('MOUSE MOVE: ', event.pageX, ',', event.pageY)
      tip.reparent(event)

      // Get the item; dataFn can return falsy so dodge that case.
      const item = dataFn ? dataFn(d.data, event) : d.data
      if (!item) {
        return
      }

      // Resolve the props data as a possible promise, then setup the tooltip template
      Promise.resolve(item).then((item) => {
        tip.update(Object.assign({ meta: event.metaKey || event.altKey }, { item }))
        tip.adjustTip(event)
      })
    }

    function mouseout(event) {
      log.debug('MOUSE OUT: ', event.pageX, ',', event.pageY)
      tip
        .transition()
        .duration(500)
        .style('opacity', 1e-6)
        .on('end', () => tip.style('display', 'none'))
      vm.$emit('smcTooltip.hide')
    }

    node
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseout)
      .on('mousedown.attach', mouseout)
      .on('mouseclick.attach', mouseout)
  }
  tip.hide = (duration) => {
    tip
      .transition(duration || 0)
      .style('opacity', 1e-6)
      .on('end', () => tip.style('display', 'none'))
  }
  tip.show = (duration) => {
    tip.style('display', 'block')
    tip.transition(duration || 0).style('opacity', 1)
  }

  return tip
}

const tooltipService = createTooltipService()
export default tooltipService
