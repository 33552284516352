import { Model } from '@vuex-orm/core'
import Instance from '@/models/Instance'

export default class HardwareInstanceType extends Model {
  static entity = 'hardwareInstanceType'

  static primaryKey = ['instance_id', 'hardware_instance_type_id']

  static fields () {
    return {
      instance_id: this.string(null).nullable(),
      hardware_instance_type_id: this.string(null).nullable(),
      __type: this.attr('hardwareInstance'),
      hardware_instance_name: this.string(null).nullable(),
      provider_name: this.string(null).nullable(),
      cloud_provider: this.string(null).nullable(),
      cloud_region: this.string(null).nullable(),
      ram_bytes: this.number(null).nullable(),
      storage_size_bytes: this.number(null).nullable(),
      vcpu_cores: this.number(null).nullable(),
      cpu_cores: this.number(null).nullable(),
      network_access: this.string(null).nullable(),
      storage_access: this.string(null).nullable(),
      is_system: this.boolean(null).nullable(),
      max_nodes: this.number(null).nullable(),
      reserved_nodes: this.number(null).nullable(),
      used_nodes: this.number(null).nullable()
    }
  }
}
