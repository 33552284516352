<template>
  <div class="top-0 right-0 m-2 stroke-0 fill-current rounded-full hover:bg-gray-200 dark:hover:bg-yb-gray-alt-lightest-inverse dark:hover:text-white hover:text-gray-700 trans-bg-color cursor-pointer z-40" :class="classes" @click.stop="click">
    <svg-close />
  </div>
</template>

<script>
import SvgClose from '@/assets/svg/close.svg'

export default {
  components: { SvgClose },
  props: {
    fixed: Boolean,
    small: Boolean,
    tiny: Boolean
  },
  emits: ['click'],
  computed: {
    classes() {
      const result = []
      if (this.fixed) {
        result.push('fixed')
      } else {
        result.push('absolute')
      }
      if (this.small) {
        result.push('w-5')
        result.push('w-5')
        result.push('p-1')
      } else if (this.tiny) {
        result.push('w-4')
        result.push('w-4')
        result.push('p-1')
      } else {
        result.push('w-12')
        result.push('w-12')
        result.push('p-4')
      }
      return result
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>
