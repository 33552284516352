<template>
  <div class="spinner">
    <div class="rect1" />
    <div class="rect2" />
    <div class="rect3" />
    <div class="rect4" />
    <div class="rect5" />
  </div>
</template>

<style scoped>
.spinner {
  @apply h-20;
  & > div {
    @apply bg-gray-300 w-1 mr-1 h-full inline-block;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  & .rect2 {
    animation-delay: -1.1s;
  }
  & .rect3 {
    animation-delay: -1s;
  }
  & .rect4 {
    animation-delay: -0.9s;
  }
  & .rect5 {
    animation-delay: -0.8s;
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
</style>
