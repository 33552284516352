<template>
  <div v-show="show" class="pointer-events-auto" :class="layoutClasses" :data-test-id="effectiveDataTestId" @click="maybeClose">
    <div :class="dialogClasses + ' ' + modalClasses" role="dialog" @click.stop="">
      <yb-close v-if="!forceConfirmation" class="text-white" :class="layout !== 'drawer' ? 'top-[-4px]' : ''" @click="close" />
      <h4 class="yb-view-header text-center px-4 pt-2 mb-4 overflow-ellipsis relative bg-yb-gray-dark dark:bg-yb-gray-alt-dark" :class="layout === 'drawer' ? 'pb-6': 'pt-4 pb-3'">
        <slot name="header" />
        <yb-icon v-if="icon" :icon="icon" class="absolute top-0 left-0 m-4 text-white" :class="iconClasses" />
        <span v-if="effectiveTitle" class="ml-8 mr-16 flex justify-center whitespace-nowrap overflow-ellipsis text-white" :class="layout === 'drawer' ? 'mt-4': ''">{{ effectiveTitle }}</span>
      </h4>

      <suspense timeout="250">
        <template #default>
          <form :class="effectiveContentClasses" @submit.prevent="$emit('submit', $event)">
            <div
              v-if="['alert', 'confirm'].includes(type) && !useMarkdown"
              v-html="description"
            />
            <div v-if="useMarkdown">
              <yb-markdown data-testid="modal-markdown-content" :source="description" />
            </div>
            <slot :dialog-controller="dialogController" />
            <component
              v-bind="componentProps"
              :is="component"
              v-if="component"
              :dialog-controller="dialogController"
              @close="close"
              @title="updateTitle"
              @nobuttons="effectiveAcceptLabel = null"
            />
            <div v-if="effectiveAcceptLabel" class="yb-view-footer flex flex-row justify-center pt-8 pb-4">
              <yb-button
                v-if="(type == 'confirm' || showCancel) && !forceConfirmation"
                type="button"
                :label="cancelLabel"
                class="yb-button-default-lg mr-2"
                @click="close"
              />
              <yb-button
                type="submit"
                data-test-id="modal-submit-btn"
                :label="effectiveAcceptLabel"
                class="modal-submit-btn"
                :class="type == 'confirm' ? 'yb-button-primary-lg' : 'yb-button-default-lg'"
              />
            </div>
          </form>
        </template>
        <template #fallback>
          <div class="yb-view-content min-h-[200px]">
            <div class="h-full flex flex-row items-center justify-center">
              <yb-loading class="self-center text-center" />
            </div>
          </div>
        </template>
      </suspense>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

let DEFAULT_TITLE = 'Yellowbrick Data'
export const setDefaultTitle = title => (DEFAULT_TITLE = title)
export { DEFAULT_TITLE }

export default {
  provide() {
    const { dialogController } = this
    return {
      dialogController
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    forceConfirmation: {
      type: Boolean,
      default: false
    },
    useMarkdown: {
      type: Boolean,
      default: false
    },
    focusSubmit: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: DEFAULT_TITLE
    },
    description: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'alert',
      validator: value => ['alert', 'confirm', 'form', 'component'].includes(value)
    },
    dialogClasses: {
      type: String,
      default: 'w-full max-h-full max-w-xl'
    },
    icon: {
      type: String
    },
    iconClasses: {
      type: String,
      default: 'yb-symbol-icon'
    },
    outerContentClass: {
      type: String,
      default: 'yb-view-content'
    },
    contentClasses: {
      type: String,
      default: 'py-4 px-8'
    },
    acceptLabel: {
      type: String
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    component: {
      type: [Object, Function]
    },
    componentProps: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: String,
      default: 'popup'
    },
    drawerWidth: {
      type: String,
      default: ''
    },
    dataTestId: {
      type: String
    },
    clickLock: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'submit', 'close'
  ],
  data() {
    return {
      effectiveDataTestId: null,
      effectiveTitle: this.title,
      effectiveAcceptLabel: this.acceptLabel
    }
  },
  computed: {
    effectiveContentClasses() {
      const result = []
      if (this.outerContentClass) {
        result.push(this.outerContentClass)
      }
      if (this.contentClasses) {
        result.push(this.contentClasses)
      }
      return result
    },
    layoutClasses() {
      const layouts = {
        drawer: 'fixed flex justify-end inset-0 h-full w-full bg-yb-steel bg-opacity-50 z-50 trans-opacity',
        popup: 'fixed inset-0 bg-yb-steel bg-opacity-50 flex items-center justify-center trans-opacity shadow-lg z-50 max-h-screen'
      }
      return layouts[this.layout]
    },
    modalClasses() {
      const layouts = {
        drawer: 'yb-view-base overflow-hidden trans-transform yb-body-background z-50 relative h-full shadow-lg ' + this.drawerWidth,
        popup: 'yb-view-base trans-transform yb-body-background z-50 rounded relative'
      }
      return layouts[this.layout]
    },
    transitionName() {
      const transitions = {
        drawer: 'dialog-side',
        popup: 'dialog'
      }
      return transitions[this.layout]
    },
    dialogController() {
      return {
        close: this.close.bind(this),
        updateTitle: this.updateTitle.bind(this)
      }
    }
  },

  watch: {
    show(_) {
      this.updateShow(_)
    }
  },
  mounted() {
    const scrollLock = document.getElementById('scroll-lock')
    disableBodyScroll(scrollLock || this.$el, {
      reserveScrollBarGap: true
    })
    document.activeElement.blur()
    setTimeout(() => {
      document.addEventListener('keydown', this.keyed)
    }, 100)

    if (!this.focusSubmit) {
      document.querySelector('input')?.focus()
    } else {
      this.$nextTick(() => {
        document.querySelector('button.modal-submit-btn')?.focus()
      })
    }

    // Do we have a data test id?  If so, make a dialog event.
    this.effectiveDataTestId = this.$attrs['data-test-id'] || this.dataTestId
    if (this.show) {
      this.updateShow(true)
    }
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.keyed)
    clearAllBodyScrollLocks()
    if (this.show) {
      this.updateShow(false)
    }
  },

  methods: {
    close(...args) {
      this.$emit('close', ...args)
    },
    keyed(event) {
      if (event.keyCode === 27) { this.close() }
    },
    updateShow(value) {
      const { effectiveDataTestId, effectiveTitle } = this
      const id = effectiveDataTestId || effectiveTitle || 'unknown'
      if (value) {
        this.$track && this.$track(`dialog.${id}.open`)
      } else {
        this.$track && this.$track(`dialog.${id}.close`)
      }
    },
    updateTitle(title) {
      this.effectiveTitle = title
    },
    maybeClose() {
      if (this.layout !== 'drawer' && !this.clickLock) {
        this.close()
      }
    }
  }
}
</script>
