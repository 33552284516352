import OwnerModel from './OwnerModel'
import Database from './Database'

export default class DatabaseModel extends OwnerModel {
  static entity = 'databaseModel'

  static fields () {
    return {
      ...super.fields(),
      database_id: this.number(null).nullable()
    }
  }

  get database() {
    return Database.query().whereId([this.instance_id, this.database_id]).first()
  }

  get database_name() {
    return this.database?.name
  }
}
