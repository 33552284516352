import { ResponseError } from './errors'

export let errorHandler = (title, message) => {
  console.error(`${title}: ${message}`)
  return Promise.resolve(null)
}

export function setErrorHandler(_) {
  errorHandler = _
}

export function error(title, message) {
  errorHandler(title, message)
}

let errorResultHandler = (response, reportInstanceNotFound) => {
  console.error('Remote error', response)
  return Promise.resolve(false)
}

export function setErrorResultHandler(_) {
  errorResultHandler = _
}

export function errorResult(response, reportInstanceNotFound, message = 'Unexpected error') {
  return errorResultHandler(response, reportInstanceNotFound, message)
}

const defaultThrownMessage = 'Unexpected exception'
export function thrown(error, message = defaultThrownMessage) {
  if (errorIsCancelled(error)) {
    return Promise.resolve(null)
  }
  if (error?.networkError?.result?.errors?.length) {
    message += '\n\n' + error.networkError.result.errors.map(e => e.message || '').join('\n')
  }
  console.error('[ym:error]', error)
  if (!(error instanceof Error)) {
    console.error('[ym:error]', new Error('Error Location'))
  }
  console.log('[ym:error]', message, error)
  if (error instanceof ResponseError || (error instanceof Error && !!error.networkError)) {
    return errorResult(error, false, message === defaultThrownMessage ? 'The operation failed.' : message)
  } else if (typeof error?.request !== 'undefined' || typeof error?.response !== 'undefined') {
    return errorResult(error, false, message)
  } else if (error instanceof Error) {
    return errorResult(error, false, message)
  } else if (error?.error instanceof Error) {
    return errorResult(error.error, false, message)
  } else {
    return errorHandler('Unexpected Error', message + ':\n\n' + (error?.message || error?.error || String(JSON.stringify(error, null, 2))))
  }
}

export const networkFailRegex = /Failed to fetch|NetworkError when attempting to fetch resource|EHOSTUNREACH|ENOTFOUND|ECONNREFUSED|EPROTO|Connection Error:|Connection refused:|the server has closed the connection|socket hang up|Unknown response error|ERR_SOCKET_CONNECTION_TIMEOUT/i
export const notTrustedRegex = /Federated.*issuer '.*' not configured on Yellowbrick|Federated.*Invalid public key|role "<jwt>" does not exist/

export function errorIsCancelled(result) {
  return !!result && typeof result.navigationCancelled === 'boolean' && !!result.navigationCancelled
}

export function errorIsTimeout(result) {
  if (errorIsCancelled(result)) {
    return false
  }
  if (!result) {
    return false
  }
  if (!!result.data && String(result.data).match(/EHOSTUNREACH|ENOTFOUND|ECONNREFUSED|EPROTO|socket hang up|ERR_SOCKET_CONNECTION_TIMEOUT/)) {
    return true
  }
  return (result instanceof Error) &&
         ((result instanceof DOMException && result.name === 'AbortError') ||
          (result instanceof Error && String(result.message).match(networkFailRegex)))
}

export function errorIsUnavailable(result) {
  if (!result) {
    return false
  }
  if (String(result?.message).match(/502 Bad Gateway|503 Service Temporarily Unavailable|status code 502|status code 503/i)) {
    return true
  }
  return result?.status === 502 || result?.status === 503 || result?.networkError?.status === 502 || result?.networkError?.status === 503
}
