import pathify from 'vuex-pathify'
import { make } from '../pathify'

// options
pathify.options.mapping = 'simple'
pathify.options.deep = 2

const state = {
  bytescale: 1000,
  logLevel: null,
  childLoggers: [],
  privilegesManageBy: 'role',
  editor: {
    fontSize: 'base',
    limit: 1000,
    indentLevel: 4,
    queryBatchPolicy: 'first-with-rows',
    minimap: true,
    useDatabaseTimeZone: false,
    useMonospacedFont: true
  },
  instance: {
    lastPath: null,
    splitH: {
      left: 30,
      right: 70
    }
  },
  toggles: {},
  theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations
}
