import d3 from '@/lib/d3'
import { functions } from '@/util'

const STAT_CONVERSIONS = {
  numReadIO: {
    to: 'io_read_count',
    value: 'sum'
  },
  readIOSize: {
    to: 'io_read_bytes',
    value: 'sum'
  },
  readSize: {
    to: 'io_read_bytes',
    value: 'sum'
  },
  numWriteIO: {
    to: 'io_write_count',
    value: 'sum'
  },
  writeIOSize: {
    to: 'io_write_bytes',
    value: 'sum'
  },
  writeSize: {
    to: 'io_write_bytes',
    value: 'sum'
  },
  spillIOReadSize: {
    to: 'io_spill_read_bytes',
    value: 'sum'
  },
  spillReadSize: {
    to: 'io_spill_read_bytes',
    value: 'sum'
  },
  spillIOWriteSize: {
    to: 'io_spill_write_bytes',
    value: 'sum'
  },
  spillWriteSize: {
    to: 'io_spill_write_bytes',
    value: 'sum'
  },
  spillIOSpace: {
    to: 'io_spill_space_bytes',
    value: 'sum'
  },
  spillSpace: {
    to: 'io_spill_space_bytes',
    value: 'sum'
  },
  numApiCalls: {
    to: 'io_network_count',
    value: 'sum'
  },
  apiDataSent: {
    to: 'io_network_bytes',
    value: 'sum'
  },
  memoryUsed: {
    to: 'memory_actual_bytes',
    value: 'avg'
  },
  numRows: {
    to: 'rows_actual',
    value: 'sum'
  },
  linkRows: {
    to: 'link_rows',
    value: 'sum'
  },
  linkBytes: {
    to: 'link_bytes',
    value: 'sum'
  },
  numRowPacketAlloc: {
    to: 'packet_alloc_count',
    value: 'sum'
  },
  numRowPacketFree: {
    to: 'packet_free_count',
    value: 'sum'
  },
  numRowsReturned: {
    to: 'rows_returned',
    value: 'sum'
  },
  numRowsInserted: {
    to: 'rows_inserted',
    value: 'sum'
  },
  numRowsColumnStore: {
    to: 'rows_columnstore',
    value: 'sum'
  },
  numRowsDeleted: {
    to: 'rows_deleted',
    value: 'sum'
  },
  ticks: {
    to: 'ticks',
    value: 'avg'
  },
  rows: {
    to: 'rows',
    fn: d => functions.sum(functions.values(d))
  },
  packets: {
    to: 'packets',
    fn: d => functions.sum(functions.values(d))
  }
}

export default function statAggregator(stats) {
  // Turn the data into a map of nodeId->aggregated stats
  stats = d3.nest()
    .key(function(d) {
      if (!d || typeof d.nodeId === 'undefined') {
        throw new Error('No node id!')
      }
      return d.nodeId
    })
    .map(stats)
  functions.forEach(stats, function(nodeStats, nodeId) {
    if (typeof nodeId === 'undefined' || nodeId === 'undefined') {
      throw new Error('Undefined!')
    }
    stats[nodeId] = nodeStats.reduce(function(agg, nodeStat, index) {
      nodeStat.ticks = (nodeStat.endTicks && nodeStat.startTicks && nodeStat.endTicks - nodeStat.startTicks) || 0
      if (nodeStat.ticks < 1) {
        nodeStat.ticks = 0
      }
      delete nodeStat.endTicks
      delete nodeStat.startTicks
      functions.forEach(nodeStat, function(v, p) {
        // eslint-disable-next-line no-empty
        if (p.match(/id|uuid|nodeId/)) {
        } else if (p === 'custom') {
          const custom = d3.nest()
            .key(function(d) {
              return d.statText
            })
            .map(v)
          let aggCustom = agg.custom
          if (Array.isArray(aggCustom)) {
            agg.custom = aggCustom = d3.nest()
              .key(function(d) {
                return d.statText
              })
              .map(aggCustom)
          }

          if (index !== 0) {
            functions.forEach(custom, function(vv, pp) {
              if (Array.isArray(agg.custom[pp])) {
                Array.prototype.push.apply(agg.custom[pp], vv)
              } else {
                agg.custom[pp] = vv
              }
            })
          }
        } else if (Array.isArray(v)) {
          if (p === 'rows' || p === 'packets') {
            if (Array.isArray(agg[p])) {
              agg[p] = {}
            }
            agg[p][nodeStat.guid || nodeStat.uuid] = v
          } else if (index !== 0) {
            agg[p] = Array.prototype.concat.apply(agg[p], v)
          }
        } else if (typeof v === 'number') {
          if (typeof agg[p] === 'number') {
            agg[p] = {
              min: Number.MAX_VALUE,
              max: Number.MIN_VALUE,
              sum: 0,
              count: 0
            }
          }
          agg[p].count += 1
          agg[p].sum += v
          agg[p].min = Math.min(agg[p].min || Number.MAX_VALUE, v)
          agg[p].max = Math.max(agg[p].max || Number.MIN_VALUE, v)
          agg[p].avg = parseFloat((agg[p].sum / agg[p].count).toFixed(2))
        } else {
          agg[p] = v
        }
      })
      return agg
    }, nodeStats[0])
    delete stats[nodeId].uuid
    delete stats[nodeId].execId
    delete stats[nodeId].nodeId
    if (stats[nodeId].custom) {
      if (Object.keys(stats[nodeId].custom).length === 0) {
        delete stats[nodeId].custom
      } else {
        const customAgg = {}
        functions.forEach(stats[nodeId].custom, (v, p) => {
          customAgg[p] = Array.isArray(v)
            ? v.reduce((sum, item) => {
              sum.nodeCount = (sum.nodeCount || 1) + 1
              sum.min = Math.min(sum.min, item.minTime)
              sum.max = Math.max(sum.max, item.maxTime)
              sum.sum += item.sumTime
              sum.count += item.count
              delete sum.statText
              delete sum.statName
              return sum
            })
            : v
        })
        stats[nodeId].custom = customAgg
      }
    }
    functions.forEach(stats[nodeId], (v, p) => {
      if (p === 'custom') {
        functions.forEach(v, (vv, pp) => {
          delete vv.nodeCount
        })
      } else {
        delete v.count
      }
    })

    // Convert runtime stats to normalized form.
    functions.forEach(STAT_CONVERSIONS, (conv, p) => {
      if (stats[nodeId][p]) {
        if (typeof conv.fn === 'function') {
          stats[nodeId][conv.to] = conv.fn(stats[nodeId][p])
        } else if (typeof stats[nodeId][p][conv.value] !== 'undefined') {
          stats[nodeId][conv.to] = stats[nodeId][p][conv.value]
        }
        if (p !== conv.to) {
          delete stats[nodeId][p]
        }
      }
    })
  })
  return stats
}
