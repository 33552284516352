import SchemaModel from './SchemaModel'

export default class Sequence extends SchemaModel {
  static entity = 'sequence'

  static primaryKey = ['instance_id', 'sequence_id']

  static fields () {
    return {
      ...super.fields(),
      sequence_id: this.number(null).nullable(),
      __type: this.attr('sequence'),
      fullname: this.string(null).nullable(),
      start_value: this.number(null).nullable(),
      last_value: this.number(null).nullable(),
      min_value: this.number(null).nullable(),
      max_value: this.number(null).nullable(),
      creation_time: this.string(null).nullable()
    }
  }

  get id() {
    return this.sequence_id
  }
}
