import 'prismjs'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-json'
// import 'prismjs/plugins/custom-class/prism-custom-class';
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prism-themes/themes/prism-vs.css'
// import './prismjs/themes/prism.css';
// import './prismjs/light-prismjs-overrides.css';

// NB: the interaction between the markdown-it-prism plugin and our use requires us to use global window import
const Prism = window.Prism

export default Prism

function fixupClasses(Prism) {
  if (Prism.plugins.customClass) {
    Prism.plugins.customClass.prefix('prismjs-')
  }
}

function fixupSQL(Prism) {
  if (Prism.languages.sql) {
    // Does the regex contain our keywords?  Doctor it if missing
    if (Prism.languages.sql.keyword && !Prism.languages.sql.keyword.toString().match(/ACTIVATE/)) {
      const re2string = (re) => {
        return re.toString().replace(new RegExp('^/([^/]*)/.*'), '$1')
      }
      const reo = re2string(Prism.languages.sql.keyword)
      const reyb = re2string(/\b(?:ACTIVATE|CANCEL|WITHOUT|WLM|PROFILE|RESOURCE|POOL|RULE|DISTRIBUTE|SORT|PARTITION|TIMESTAMP|TIMESTAMP WITH|TIME|ZONE|REAL|DOUBLE PRECISION|UUID)\b/)
      Prism.languages.sql.keyword = new RegExp(`(${reo}|${reyb})`, 'i')
    }
  }
}

function fixupLDAP(Prism) {
  if (Prism.languages.clike) {
    if (!Prism.languages.ldap) {
      Prism.languages.ldap = Prism.languages.extend('clike', {
        keyword: /\b(?:cn|objectClass|memberOf)\b/,
        operator: />>=?|<<=?|->|([-+&|:])\1|[?:~]|[-+*/%&|^!=<>]=?/,

        number: /(?:\b0x[\da-f]+|(?:\b\d+\.?\d*|\B\.\d+)(?:e[+-]?\d+)?)[ful]*/i
      })
    }
  }
}

export function fixups(Prism) {
  fixupClasses(Prism)
  fixupSQL(Prism)
  fixupLDAP(Prism)
}
