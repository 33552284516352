import { make } from '../pathify'
import USER_INSTANCES from '@/graphql/userInstances.gql'
import SITES from '@/graphql/sites.gql'
import apollo from '@/apollo'
import { hasRole } from '@/auth'
import { clearCommunicationsError } from '@/services/app'
import { errorHandler } from '@/services'

const DEFAULT_INSTANCE_FILTER = new RegExp(import.meta.env.VITE_INSTANCE_FILTER || '.*')

const state = {
  userInstances: [],
  sites: null,
  portDb: 5432,
  portSmc: 8182,
  nodeVcpuMap: [
    { id: 0, nodes: 8, vcpus: 288, userData: 140 },
    { id: 1, nodes: 9, vcpus: 324, userData: 155 },
    { id: 2, nodes: 10, vcpus: 360, userData: 175 },
    { id: 3, nodes: 11, vcpus: 396, userData: 190 },
    { id: 4, nodes: 12, vcpus: 432, userData: 210 },
    { id: 5, nodes: 13, vcpus: 468, userData: 225 },
    { id: 6, nodes: 14, vcpus: 504, userData: 245 },
    { id: 7, nodes: 15, vcpus: 540, userData: 300 },
    { id: 8, nodes: 16, vcpus: 576, userData: 280 },
    { id: 9, nodes: 18, vcpus: 648, userData: 315 },
    { id: 10, nodes: 20, vcpus: 720, userData: 350 },
    { id: 11, nodes: 22, vcpus: 792, userData: 385 },
    { id: 12, nodes: 24, vcpus: 864, userData: 420 },
    { id: 13, nodes: 26, vcpus: 936, userData: 455 },
    { id: 14, nodes: 27, vcpus: 972, userData: 470 },
    { id: 15, nodes: 28, vcpus: 1008, userData: 490 },
    { id: 16, nodes: 30, vcpus: 1080, userData: 600 },
    { id: 17, nodes: 33, vcpus: 1188, userData: 575 },
    { id: 18, nodes: 36, vcpus: 1296, userData: 630 },
    { id: 19, nodes: 39, vcpus: 1404, userData: 680 },
    { id: 20, nodes: 42, vcpus: 1512, userData: 735 },
    { id: 21, nodes: 45, vcpus: 1620, userData: 900 }
  ],
  densities: [
    { id: 'L', label: 'L', multiplier: 1 },
    { id: 'XL', label: 'XL', multiplier: 2 },
    { id: '_2XL', label: '2XL', multiplier: 4 }
  ],
  reservationStatuses: [
    'Requested',
    'Received',
    'Processed',
    'Connected',
    'Delivered'
  ],
  upgradeInstanceId: [],
  diagsInstanceId: null,
  settings: {
    view: 'list'
  },
  lastObservedActive: 0,
  resumingClusters: []
}

const mutations = make.mutations(state)

let lastPopulated = 0; let populating

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    populate({ commit, state }) {
      async function impl() {
        commit('global/loading', true, { root: true })
        try {
          const response = await apollo
            .query({ query: USER_INSTANCES })
          const { data: { userInstances } = undefined } = response

          const filteredInstances = userInstances.filter(instance => !!DEFAULT_INSTANCE_FILTER.exec(instance.name))
          commit('userInstances', filteredInstances)
          clearCommunicationsError(true)

          if (hasRole('consumeradmin') && (!state.sites || state.sites.length === 0)) {
            const response = await apollo
              .query({ query: SITES })
            let { data: { sites } = [] } = response
            sites = sites.map((site) => {
              site.label = `${site.name} | ${site.description}`
              return site
            })
            commit('sites', sites)
          }
        } catch (e) {
          await errorHandler.thrown(e, 'Could not retrieve instance information.')
        } finally {
          commit('global/loading', false, { root: true })
        }
      }

      // Only actually get this info once a minute
      const now = +new Date()
      const needFastPoll = (+new Date() - state.lastObservedActive) < (1 * 60 * 1000) // Did we observe an active task in the last min?
      const timeout = needFastPoll ? 5000 : 60000
      if ((now - lastPopulated) > timeout) {
        lastPopulated = now
        populating = impl()
      }
      return populating
    },
    invalidate() {
      lastPopulated = 0
    }
  }
}
