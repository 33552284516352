import { DateTime, Duration } from 'luxon'
import numeral from 'numeral'
import humanizeDuration from 'humanize-duration'

// Setup numeral filter presets and add "number" filter.
function zeros(places, vary) {
  let result = places > 0 ? '.' : ''
  const brackets = !!vary && places > 0
  if (brackets) {
    result += '['
  }
  while (places-- > 0) {
    result += '0'
  }
  if (brackets) {
    result += ']'
  }
  return result
}
export function number(value, decimals) {
  return numeral(value).format('0,0' + zeros(decimals, true))
}
export const formatNumber = number
export function percentage(value, precision) {
  value /= 100
  if (value < 0.001) {
    return '0%'
  }
  if (typeof precision === 'number' && precision === 0) {
    return numeral(value).format('0%')
  } else {
    return numeral(value).format('0.[00]%')
  }
}
let _byteScale = 1024
export function bytes(value, precision) {
  if (isNaN(parseFloat(value)) || !isFinite(value) || value === 0) {
    if (precision === 'units') {
      return 'bytes'
    } else if (precision < 0) {
      return '0'
    }
    return '0 bytes'
  }
  const units = precision === 'units'
  if (units) {
    precision = 0
  }
  if (typeof precision === 'undefined') {
    precision = value < 1024 ? 0 : 1
  }
  const nounits = precision < 0
  if (precision === -1) {
    precision = 0
  }
  let result
  const setting = _byteScale || 1000
  if (setting === '1024' || setting === 1024) {
    if (value < 1024) {
      result = numeral(value).format('0' + zeros(Math.abs(precision))) + ' bytes'
    } else {
      result = numeral(value).format('0' + zeros(Math.abs(precision)) + ' ib')
    }
  } else if (value < 1000) {
    result = numeral(value).format('0' + zeros(Math.abs(precision))) + ' bytes'
  } else {
    result = numeral(value).format('0' + zeros(Math.abs(precision)) + ' b')
  }
  if (nounits) {
    result = result.split(' ')[0]
  } else if (units) {
    result = result.split(' ')[1]
  }
  return result
}
export function byteScale(_) {
  if (_) {
    _byteScale = _
  }
  return _byteScale
}

// Momentizing/humanizing some ms
export function humanizeMs(value, longForm, fractional) {
  if (typeof value !== 'number') {
    return
  }
  if (longForm) {
    let format, millis
    if (isNaN(value)) {
      value = 0
    }
    if (value === 0 && !fractional) {
      format = `s 'seconds'`
    } else if (value < 1000 || (!!fractional && value === 0)) {
      format = `SSS 'ms'`
      millis = true
    } else if (value < 60000) {
      format = fractional ? `s.SSS 'seconds'` : `s 'seconds'`
    } else if (value < 3600000) {
      format = `mm:ss`
    } else if (value < 86400000) {
      format = `hh:mm:ss`
    } else if (value % 86400000 !== 0) {
      format = `d 'days', hh:mm:ss`
    } else if (value / 86400000 === 1) {
      format = `d 'day'`
    } else {
      format = `d 'days'`
    }
    let result = Duration.fromMillis(value).toFormat(format)
    if (fractional && millis) {
      const micros = value % 1
      result = result.replace(' ', '.' + String(parseInt(micros * 1000)).padStart(3, '0') + ' ')
    }
    return result
  } else {
    if (value === 0 || isNaN(value)) {
      return Duration.fromMillis(0).toFormat(`S 'ms'`)
    }
    if (value < 1000) {
      return Duration.fromMillis(value).toFormat(`S 'ms'`)
    } else if (value < 60 * 1000) {
      if (fractional) {
        return Duration.fromMillis(value).toFormat(`s.SSS 's'`)
      } else {
        const result = Duration.fromMillis(value).toFormat(`s'.SSS' 's'`)
        const fract = String(parseInt(value % 1000)).padStart(3, '0')
        return result.replace(/SSS/, fract)
      }
    } else if (value < 60 * 60 * 1000) {
      return Duration.fromMillis(value).toFormat(`mm:ss 'm'`)
    } else if (value < 24 * 60 * 60 * 1000) {
      return Duration.fromMillis(value).toFormat(`h'h' m'm' s's'`)
    } else {
      return Duration.fromMillis(value).toFormat(`d'd' h'h' m'm' s's'`)
    }
  }
}

export function humanizeMsSansUnits(value, longForm, fractional) {
  const result = humanizeMs(value, longForm, fractional)
  return (!!result && result.split(/ /g)[0]) || ''
}

export function humanizeMsUnits(value, longForm, fractional) {
  const result = humanizeMs(value, longForm, fractional)
  return (!!result && result.split(/ /g)[1]) || ''
}

export function humanizeNumber(number, precision) {
  if (number < 1000) {
    return Number(number || 0).toFixed(typeof precision === 'number' ? precision : 2)
  }
  return numeral(number)
    .format('0,0' + zeros(precision || 1) + 'a')
    .toUpperCase()
}

export function humanizeDashboardNumber(number) {
  if (!number || number <= 0 || isNaN(number)) {
    return '0'
  } else if (number < 10000) {
    return humanizeNumber(number, 0)
  } else {
    return humanizeNumber(number, 1)
  }
}

export function formatDate(date) {
  if (!date) {
    return 'n/a'
  } else {
    date = createDateTimeObject(date, { zone: 'UTC' })
  }
  return date.toLocaleString(DateTime.DATE_SHORT)
}

const DEFAULT_TIME_OPTIONS = { hour: '2-digit', minute: '2-digit', second: '2-digit' }

export function formatDateTime(dateTime, fractionalSecondDigits = undefined, timeOptions = DEFAULT_TIME_OPTIONS, dateTimeOptions = null) {
  timeOptions = timeOptions || DEFAULT_TIME_OPTIONS
  if (!dateTime) {
    return 'n/a'
  } else {
    dateTime = createDateTimeObject(dateTime)
  }
  if (fractionalSecondDigits) {
    timeOptions.fractionalSecondDigits = fractionalSecondDigits
  }
  if (!!dateTimeOptions && !!dateTimeOptions.timeZone) {
    dateTime = dateTime.setZone(dateTimeOptions.timeZone)
  }
  return dateTime.toLocaleString(DateTime.DATE_SHORT) + ' ' + dateTime.toLocaleString(timeOptions)
}

function createDateTimeObject(dateTime, options = {}) {
  if (typeof dateTime === 'number') {
    return DateTime.fromMillis(dateTime, options)
  } else if (dateTime instanceof Date) {
    return DateTime.fromJSDate(dateTime, options)
  } else {
    return DateTime.fromJSDate(new Date(String(dateTime)), options)
  }
}

export function formatDateTimeFull(dateTime) {
  if (!dateTime) {
    return 'n/a'
  } else {
    dateTime = createDateTimeObject(dateTime)
  }
  return dateTime.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
}

export function formatDateTimeMs(dateTime, dateTimeOptions = null) {
  return formatDateTime(dateTime, 3, null, dateTimeOptions)
}

export function capitalize(w) {
  if (!w || !w.match(/ /)) {
    return w
  }
  return String(w).split(/ /g)
    .map(word => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(' ')
}

export function duration(t1, t2) {
  if (!t1 && !t2) {
    return ''
  }
  if (t1 && !t2) {
    t2 = +new Date()
  }
  return humanizeMs(+new Date(t2) - +new Date(t1))
}

export function formatDuration(ms) {
  return humanizeDuration(ms, { units: ['d', 'h', 'm', 's'], round: true })
}

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms"
    }
  }
})
export function formatDurationShort(ms) {
  return shortEnglishHumanizer(ms, { units: ['d', 'h', 'm', 's'], maxDecimalPoints: 3 })
}

export function formatTime(dateTime, fractionalSecondDigits = undefined) {
  dateTime = createDateTimeObject(dateTime, { zone: 'UTC' })
  const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
  if (fractionalSecondDigits) {
    timeOptions.fractionalSecondDigits = fractionalSecondDigits
  }
  return dateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS, timeOptions)
}

export function makeSentence(s) {
  if (!s || typeof s !== 'string' || !s.trim()) {
    return s
  }
  let r = s.substring(0, 1).toUpperCase() + s.substring(1)
  if (!r.endsWith('.')) {
    r += '.'
  }
  return r
}

// Former i18n filter
export function translate(s) {
  return s
}
