import { queryNodeTypes } from './queryNodeTypes'
import { functions, Logger } from '@/util'
import { jolokiaService, mbeans, $gettext } from '@/services'
import persistenceService from '@/services/persistenceService'
import queryPlanBuilderService from '@/services/queryPlanBuilderService'

const log = Logger.get('yb.query-stats')

function initialize() {
  // Private method to convert an array of plan nodes into a tree.  The array can come from
  // the stored query plan table, or from the running query plan nodes.
  const convertPlan = (planNodes) => {
    // Process the rows for query plan.
    const parens = /^\((.*?)\)$/
    const demangle = (cols) => {
      // First, convert any , NUMBER to ,NUMBER
      cols = cols && cols.replace(/, ([0-9]+)/g, ',$1')

      // Next, extract the (col1, col2) pattern.
      let match
      if (cols && (match = parens.exec(cols))) {
        return match[1].split(/, /g).map(c => c.trim())
      } else {
        return []
      }
    }
    const nodeMap = functions.indexBy(
      planNodes.map((row) => {
        let explain = null
        const explainMatch = new RegExp('.*' + row.type + ' (.*)$').exec(row.explain)
        if (explainMatch) {
          explain = explainMatch[1]
        }
        return {
          id: row.node_id,
          nodeName: row.type,
          nodeIndex: row.index,
          indent: row.indent,
          distType: row.distribution,
          children: [],
          parent: row.parent_id,
          columnNames: demangle(row.output_columns),
          expressions: demangle(row.partition_columns),
          can_spill_read: !!row.can_spill_read,
          can_spill_write: !!row.can_spill_write,
          explain
        }
      }),
      'id'
    )
    functions.forEach(nodeMap, (node, id) => {
      const parent = nodeMap[node.parent]
      if (parent && parent !== node) {
        parent.children.push(node)
      }
    })
    return nodeMap[0]
  }

  // Some private data.
  let typeMap

  return {
    processQueryStats(queryStats) {
      // Look for query stats in local storage.
      if (!queryStats) {
        // Just send back empty data if there's no stored
        return Promise.resolve({ id: null, plan: null, tree: null, frames: {} })
      }

      // Either return cached copy of node tree, or get it from the db.
      return (queryStats.tree
        ? Promise.resolve(queryStats.tree)
        : this.getRunningQueryPlan(queryStats.id).then((runningPlan) => {
          if (!runningPlan) {
            return this.getQueryPlan(queryStats.plan)
          } else {
            return runningPlan
          }
        })
      ).then((tree) => {
        // eslint-disable-next-line no-constant-condition
        if (false && !tree) {
          throw new Error('Could not locate plan for this query')
        }
        queryStats.tree = tree
        return queryStats
      })
    },
    getRunningQueryPlan(id) {
      log.debug('Getting running query plan for ' + id)
      return jolokiaService.execute(mbeans.lime, 'getRunningQueryPlanNodes', [id]).then((planNodes) => {
        if (planNodes && planNodes.length) {
          return convertPlan(planNodes)
        } else {
          log.debug('Running query plan for ' + id + ' not found')
        }
      })
    },
    getQueryPlan(plan_id, historical) {
      log.debug('Getting stored query plan for ' + plan_id)
      return jolokiaService.execute(mbeans.datasources, 'queryEx', ['yellowbrick',
        [
          'select *',
          historical ? 'from sys.log_query_plan_node' : 'from sys.query_plan_node',
          `where plan_id = '${plan_id}'`
        ].join('\n'),
        'ym', 0])
        .then((response) => {
          log.debug('Got stored query plan for ' + plan_id + ': ' + (response.rows || []).length + ' nodes')
          return convertPlan(response.rows)
        })
    },
    getFrame(scope, queryStats, frame, rateSetting) {
      const open = +new Date()
      return persistenceService.open().then((db) => {
        const start = +new Date()
        log.debug('Opened db in ' + (start - open) + 'ms')
        return db.querySamples
          .where('[id+frame]')
          .equals([queryStats.id, frame])
          .first()
          .then((sample) => {
            const retrieve = +new Date()
            log.debug('Returned frame ' + frame + ' in ' + (retrieve - start) + 'ms')
            const result = queryPlanBuilderService.createTree(functions.copy(queryStats.tree), sample.stats.nodeStats, rateSetting)
            log.debug('Calculated tree in ' + (+new Date() - retrieve) + 'ms')
            return result
          })
      })
    },
    getRows(scope, queryStats, rateSetting) {
      return persistenceService.open().then((db) => {
        return db.querySamples.get(queryStats.id).then(async (samples) => {
          // Need to initialize the typemap?
          if (!typeMap) {
            // Construct type mapping mapping, numbering up from 0 for query node types we know about.
            let typeCount = 0
            typeMap = functions.reduce(
              queryNodeTypes,
              (map, nodeLabel, nodeType) => {
                map[nodeType] = typeCount++
                return map
              },
              {}
            )
          }

          // Turn the samples into frames.  Analyze the stats for distinct keys.
          const keys = {}
          let nextKey = 5
          const frames = samples.map((sample) => {
            // Convert to frame.
            const frame = queryPlanBuilderService.createTree(functions.copy(queryStats.tree), sample.stats.nodeStats, rateSetting);

            // Get aggregation of stats, then generate plan with the stats added.
            (function gather(node) {
              for (const k in node.stats) {
                if (!keys[k]) {
                  keys[k] = nextKey++
                }
              }
              for (const k in node.detailStats) {
                if (!keys[k]) {
                  keys[k] = nextKey++
                }
              }
              (node.children || []).forEach(gather)
            })(frame)

            return frame
          })

          // Now make the rows.
          const header = ['Query Node', 'ID', 'Date', 'Type Name', 'Type']
          const empty = [null, null, null, null, null]
          functions.forEach(keys, (v, k) => {
            header[v] = $gettext(k)
            empty[v] = 0
          })
          const rows = [header]
          functions.forEach(frames, (root, epoch) => {
            (function toRow(node) {
              const row = empty.slice()
              row[0] = $gettext(node.className) + ': ' + node.id
              row[1] = node.id
              row[2] = epoch
              row[3] = node.className
              row[4] = typeMap[node.className] || 0
              for (const k in node.stats) {
                row[keys[k]] = node.stats[k]
              }
              for (const k in node.detailStats) {
                row[keys[k]] = node.detailStats[k]
              }
              rows.push(row);

              (node.children || []).forEach(toRow)
            })(root)
          })

          return rows
        })
      })
    },
    query(query_id, plan_id) {
      // Look for query stats in indexed db, then process it.
      return persistenceService.open().then((db) => {
        return db.analyzedQueries.get(query_id).then((queryStats) => {
          return this.processQueryStats(queryStats)
        })
      })
    },
    async getQueryText(query_id) {
      log.debug('Getting query text for ' + query_id)
      const queryText = await jolokiaService.execute(mbeans.datasources, 'queryEx', ['yellowbrick', `select sys.query_text(${query_id})`, 'ym', 0])
      return queryText.rows ? queryText.rows.map(r => r.query_text).join() : ''
    }
  }
}

export default initialize()
