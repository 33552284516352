import baseUrl from './remoteService'
import { Logger } from '@/util'

const log = Logger.get('yb.user')

let _loggedIn,
  _currentUser,
  _poller,
  _initalizationPromises
const _initializationTasks = []
const _teardownTasks = []

class UserService {
  loggedIn() {
    if (typeof _loggedIn !== 'undefined') {
      return Promise.resolve(_loggedIn)
    } else {
      return this._test(baseUrl.get()).then(() => {
        return _loggedIn
      })
    }
  }

  ensureInitialized() {
    return Promise.resolve(_initalizationPromises)
  }

  // See if we are logged in.
  _test() {
    const currentLoggedIn = _loggedIn
    return fetch(baseUrl.url('user'), { credentials: 'include' })
      .then((response) => {
        log.debug('User response:', response)
        if (!response.ok || response.status >= 400) {
          _loggedIn = false
          return { ok: false, status: response.status }
        } else {
          return response.text().then((text) => {
            false && log.debug('Result of login', text)
            try {
              _currentUser = JSON.parse(text)
              _loggedIn = true
            } catch (error) {
              _loggedIn = false
              _currentUser = undefined
            }
            return { ok: _loggedIn, status: 0 }
          })
        }
      })
      .catch((error) => {
        log.debug('User check error:', error)
        if (error && error.status === 403) {
          _loggedIn = false
          _currentUser = undefined
        }
        return { ok: false, status: (error && error.status) || -1 }
      })
      .finally((result) => {
        if (currentLoggedIn !== _loggedIn) {
          if (_loggedIn) {
            this._bringup()
          } else {
            this._teardown()
          }
        }
        return result
      })
  }

  _bringup() {
    _initalizationPromises = Promise.allSettled(_initializationTasks.map(f => f())).then((responses) => {
      responses
        .filter(response => response.status === 'rejected')
        .forEach((response) => {
          log.warn('Error during init:', response)
        })
    })
    if (!_poller) {
      _poller = window.setInterval(() => this.check(), 10000)
    }
  }

  _teardown() {
    const result = Promise.allSettled(_teardownTasks.map(f => f())).then((responses) => {
      responses
        .filter(response => response.status === 'rejected')
        .forEach((response) => {
          log.warn('Error during teardown:', response)
        })
    })
    if (_poller) {
      window.clearInterval(_poller)
      _poller = undefined
    }
    return result
  }

  login(username, password) {
    return fetch(baseUrl.url('auth/login'), {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: new Headers({
        Authorization: 'Basic ' + window.btoa(username + ':' + password)
      })
    }).then((response1) => {
      // Now test the login, if we're good.
      if (response1.ok) {
        return this._test().then((response2) => {
          if (response2.ok && response2.status !== 403) {
            _currentUser = username
            return true
          } else {
            throw new Error('Not authenticated')
          }
        })
      } else {
        throw response1
      }
    })
  }

  check() {
    return this._test()
  }

  logout() {
    return fetch(baseUrl.url('auth/logout'), {
      method: 'POST',
      mode: 'cors',
      credentials: 'include'
    }).then((response) => {
      if (response.ok) {
        _loggedIn = false
        _currentUser = undefined
        return this._teardown()
      }
      return true
    })
  }

  onLogin(fn, index) {
    if (_loggedIn) {
      fn()
    }
    if (typeof index === 'number') {
      _initializationTasks.splice(index, 0, fn)
    } else {
      _initializationTasks.push(fn)
    }
  }

  onLogout(fn, index) {
    if (!_loggedIn) {
      fn()
    }
    if (index) {
      _teardownTasks.splice(index, 0, fn)
    } else {
      _teardownTasks.push(fn)
    }
  }

  get currentUser() {
    return _currentUser
  }
}

export default new UserService()
