import { make, Payload } from '../pathify'
import { functions } from '@/util'

const state = {
  settings: {
    pollInstanceStates: [],
    history: [],
    loads: []
  }
}

const mutations = make.mutations(state)

const TASKS_HISTORY_LIMIT = 25

export default {
  namespaced: true,
  state,
  mutations,
  getters: {
    loadsMap: (state) => {
      return functions.indexBy(state.settings.loads, 'tags')
    }
  },
  actions: {
    setPollInstanceStates({ commit }, { pollInstanceStates }) {
      commit('settings', new Payload('setSettings', '@pollInstanceStates', pollInstanceStates))
    },
    addLoad({ commit, state }, load) {
      const loads = functions.copyDeep(state.settings.loads)
      loads.unshift(load)
      while (loads.length > TASKS_HISTORY_LIMIT) {
        loads.pop()
      }
      commit('settings', new Payload('setSettings', '@loads', loads))
    },
    addLoadResponse({ commit, state }, { tags, loadResponse }) {
      const loads = functions.copyDeep(state.settings.loads)
      const load = loads.find(load => load.tags === tags)
      if (load) {
        load.loadResponse = loadResponse
        commit('settings', new Payload('setSettings', '@loads', loads))
      }
    },
    addHistory({ commit, state }, { queries }) {
      let history = functions.copyDeep(state.settings.history)
      history.unshift(...queries)
      const dedupe = new Set()
      history = history.filter((q) => {
        if (dedupe.has(q.query_id)) {
          return false
        } else {
          dedupe.add(q.query_id)
          return true
        }
      })
      while (history.length > TASKS_HISTORY_LIMIT) {
        history.pop()
      }
      commit('settings', new Payload('setSettings', '@history', history))
    },
    clearHistory({ commit }) {
      commit('settings', new Payload('setSettings', '@history', []))
    },
    updateQueryText({ commit, state }, { query, queryText }) {
      const history = functions.copyDeep(state.settings.history)
      const queryEntry = history.find(q => q.query_id === query.query_id)
      if (queryEntry) {
        queryEntry.query_text = queryText
        commit('settings', new Payload('setSettings', '@history', history))
        return true
      } else {
        return false
      }
    }
  }
}
