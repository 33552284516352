import d3 from '@/lib/d3'

export const ONE_SECOND = 1 * 1000
export const ONE_MINUTE = 60 * ONE_SECOND
export const ONE_HOUR = 60 * ONE_MINUTE

const formatMillisecond = d3.timeFormat('.%L')
const formatSecond = d3.timeFormat(':%S')
const formatMinute = d3.timeFormat('%I:%M')
const formatHour = d3.timeFormat('%I %p')
const formatDay = d3.timeFormat('%a %d')
const formatWeek = d3.timeFormat('%b %d')
const formatMonth = d3.timeFormat('%B')
const formatYear = d3.timeFormat('%Y')

export function formatDateTimeAbbrev(date) {
  return (d3.timeSecond(date) < date
    ? formatMillisecond
    : d3.timeMinute(date) < date
      ? formatSecond
      : d3.timeHour(date) < date
        ? formatMinute
        : d3.timeDay(date) < date
          ? formatHour
          : d3.timeMonth(date) < date
            ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
            : d3.timeYear(date) < date
              ? formatMonth
              : formatYear)(date)
}

export const formatDateTime = d3.timeFormat('%m/%d/%Y %I:%M:%S')
export const formatDateTimeLong = d3.timeFormat('%m/%d/%Y %I:%M:%S.%L')
export const formatTime = d3.timeFormat('%I:%M:%S')
