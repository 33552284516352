import StackTrace from 'stacktrace-js'
import storeService from '@/store/storeService'
import logRocketService from '@/services/logrocket'

import Instance from '@/models/Instance'

const errorReporter = async (error, message, instance) => {
  const result = []; const indent = '    '

  // What error occurred?
  result.push(`Message: ${message}`)
  result.push(`Error: ${error.message || error.error}`)
  result.push('')
  result.push(`Timestamp: ${new Date().toISOString()}`)
  result.push(`URL: ${window?.location?.href}`)
  const sessionURL = logRocketService.sessionURL
  if (sessionURL) {
    result.push(`Session URL: ${sessionURL}`)
  }
  result.push(`User: ${storeService.userName}`)
  result.push(`Roles: ${Array.from(storeService.roles || []).join(', ')}`)
  result.push('')

  // Append cdwm detail.  TODO: this should be placed into vuex store way ahead of an error.

  // Append instance detail
  const instanceId = error.response?.context?.instance || error.context?.instance || instance
  if (instanceId) {
    result.push(`Instance ID: ${instanceId}`)
    const instance = Instance.query().whereId(instanceId).first()
    if (instance) {
      result.push(`Instance Name: ${instance.name}`)
      result.push(`Instance System Version: ${instance.softwareVersion}`)
      result.push(`Instance Revision: ${instance.revision}`)
      result.push(`Instance Build Date: ${instance.buildDate}`)
    }
  }
  result.push('')

  // Append error detail

  // Append request detail.
  if (error.response) {
    const SQLState = error?.response?.sqlState || error?.response?.SQLState || error?.response?.error?.SQLState
    if (SQLState) {
      result.push(`SQL state: ${SQLState}`)
    }
    if (error.response.errorCode) {
      result.push(`SQL error code: ${error.response.errorCode}`)
    }
    if (error.response.warnings) {
      result.push('SQL warnings:')
      result.push(...error.response.warnings)
    }
    if (error.response.request) {
      result.push('Remote request:')
      result.push(indent + JSON.stringify(error.response.request, null, 2).replace(/\n/g, indent))
    }
  }

  // Append stack.
  const stack = []
  const appendStack = (s) => {
    if (typeof s === 'string') {
      stack.push(...s.split(/\n/))
    } else if (Array.isArray(s)) {
      stack.push(...s)
    } else {
      stack.push(String(s))
    }
  }
  let addedStack = false
  try {
    const stackframes = await StackTrace.fromError(error)
    if (stackframes) {
      appendStack(stackframes)
      addedStack = true
    }
  } catch (ignore) {}
  error.stacktrace && appendStack(error.stackTrace)
  error.stack && !addedStack && appendStack(error.stack)
  if (stack.length) {
    if (result[result.length - 1] !== '') {
      result.push('')
    }
    result.push('Stacktrace:')
    result.push(indent + stack.join('\n' + indent))
  }

  return result.join('\n')
}

export default errorReporter
