import OwnerModel from './OwnerModel'
import Schema from './Schema'
import SchemaModel from './SchemaModel'
import Table from './Table'
import View from './View'
import Procedure from './Procedure'
import Function from './Function'
import Sequence from './Sequence'
import * as functions from '@/util/functions'

export default class Database extends OwnerModel {
  static entity = 'database'

  static primaryKey = ['instance_id', 'database_id']

  static fields () {
    return {
      ...super.fields(),
      database_id: this.number(null),
      __type: this.attr('database'),
      populated: this.number(0),
      populating: this.boolean(false),
      compressed_bytes: this.number(null).nullable(),
      max_size_bytes: this.number(null).nullable(),
      rows: this.number(null).nullable(),
      table_count: this.number(null).nullable(),
      uncompressed_bytes: this.number(null).nullable(),
      can_create: this.boolean(null).nullable(),
      is_hot_standby: this.boolean(false),
      is_readonly: this.boolean(false),
      readonly_reason: this.string(null).nullable(),
      isDatabaseTreeOpen: this.boolean(false)
    }
  }

  get id() {
    return this.database_id
  }

  get schemas() {
    return Schema.query().where('instance_id', this.instance_id).where('database_id', this.database_id).get()
      .filter(Schema.filterCommon)
      .sort(functions.sortByName)
  }

  get counts() {
    return {
      schema: this.schemas.length,
      table: Table.query().where('instance_id', this.instance_id).where('database_id', this.database_id).get().length,
      view: View.query().where('instance_id', this.instance_id).where('database_id', this.database_id).get()
        .filter(SchemaModel.filterCommon)
        .length,
      sequence: Sequence.query().where('instance_id', this.instance_id).where('database_id', this.database_id).get().length,
      function: Function.query().where('instance_id', this.instance_id).where('database_id', this.database_id).get().length,
      procedure: Procedure.query().where('instance_id', this.instance_id).where('database_id', this.database_id).get().length
    }
  }
}
