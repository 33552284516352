import SchemaModel from './SchemaModel'

export default class View extends SchemaModel {
  static entity = 'view'

  static primaryKey = ['instance_id', 'view_id']

  static fields () {
    return {
      ...super.fields(),
      view_id: this.number(null).nullable(),
      __type: this.attr('view'),
      definition: this.string(null).nullable(),
      is_temp: this.boolean(false).nullable()
    }
  }

  get id() {
    return this.view_id
  }
}
