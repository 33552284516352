import { make } from '../pathify'

const defaultCols = {
  query: [
    { name: 'Query ID', value: 'query_id' },
    { name: 'Submit Time', value: 'submit_time' },
    { name: 'State', value: 'state' },
    { name: 'Total Time', value: 'total_ms' },
    { name: 'Error Code', value: 'error_code' },
    { name: 'User Name', value: 'username' },
    { name: 'Application Name', value: 'application_name' },
    { name: 'Query', value: 'query_text' }
  ],
  session: [
    { name: 'Session ID', value: 'session_id' },
    { name: 'User', value: 'session_username' },
    { name: 'Database', value: 'session_dbname' },
    { name: 'Application', value: 'application_name' },
    { name: 'Host Name', value: 'client_ip_address' },
    { name: 'Secured', value: 'secure_connection' }
  ],
  load: [
    { name: 'Job', value: 'job_name' },
    { name: 'Time Started', value: 'start_time' },
    { name: 'State', value: 'state' },
    { name: 'Updated/Completed', value: 'end_time' },
    { name: 'Duration', value: 'elapsed_ms' },
    { name: 'Table Name', value: 'table_name' },
    { name: 'Parse', value: 'parsed_bytes' }
  ],
  unload: [
    { name: 'Job', value: 'job_name' },
    { name: 'SQL', value: 'sql_query' },
    { name: 'Time Started', value: 'start_time' },
    { name: 'State', value: 'state' },
    { name: 'Updated/Completed', value: 'last_activity_time' },
    { name: 'Duration', value: 'elapsed_ms' },
    { name: 'Rows', value: 'sent_rows' },
    { name: 'Data Read', value: 'sent_bytes' },
    { name: 'Rows per Second', value: 'sent_rows_per_second' },
    { name: 'Data per Second', value: 'sent_bytes_per_second' }
  ],
  backup: [
    { name: 'Job', value: 'job_name' },
    { name: 'Time Started', value: 'start_time' },
    { name: 'State', value: 'state' },
    { name: 'Updated/Completed', value: 'end_time' },
    { name: 'Duration', value: 'elapsed_ms' },
    { name: 'Data Transferred', value: 'sent_bytes' },
    { name: 'Transfer Rate', value: 'sent_bytes_per_sec' }
  ],
  restore: [
    { name: 'Job', value: 'job_name' },
    { name: 'Time Started', value: 'start_time' },
    { name: 'State', value: 'state' },
    { name: 'Updated/Completed', value: 'end_time' },
    { name: 'Duration', value: 'elapsed_ms' },
    { name: 'Data Received', value: 'received_bytes' },
    { name: 'Transfer Rate', value: 'received_bytes_per_sec' }
  ]
}

const state = {
  loading: false,
  defaultColsState: {
    query: [...defaultCols.query],
    session: [...defaultCols.session],
    load: [...defaultCols.load],
    unload: [...defaultCols.unload],
    backup: [...defaultCols.backup],
    restore: [...defaultCols.restore]
  },
  settings: {
    time: {
      mode: 'active',
      clusterId: null,
      timeInMinutes: 15,
      lastTimeInMinutesActive: 15,
      lastTimeInMinutesHistorical: 360,
      customTime: {
        startDate: null,
        endDate: null,
        label: null
      }
    },
    order: {
      query: {
        column: 'submit_time',
        order: 'DESC'
      },
      session: {
        column: 'session_id',
        order: 'DESC'
      },
      load: {
        column: 'start_time',
        order: 'DESC'
      },
      unload: {
        column: 'start_time',
        order: 'DESC'
      },
      backup: {
        column: 'start_time',
        order: 'DESC'
      },
      restore: {
        column: 'start_time',
        order: 'DESC'
      }
    },
    excludeQueries: {
      systemWork: true,
      automaticFlush: true,
      Session: true
    },
    filters: {
      query: [],
      session: [],
      load: [],
      unload: [],
      backup: [],
      restore: []
    },
    filterWidgets: {
      query: [],
      session: [],
      load: [],
      unload: [],
      backup: [],
      restore: []
    },
    selectedColumns: {
      query: [],
      session: [],
      load: [],
      unload: [],
      backup: [],
      restore: []
    },
    presets: {
      myPresets: {
        query: [],
        session: [],
        load: [],
        unload: [],
        backup: [],
        restore: []
      },
      defaultPresets: {
        query: [
          {
            order: {
              column: 'total_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Query Time',
                value: 'total_ms'
              }
            ],
            name: 'Top Query Time'
          },
          {
            order: {
              column: 'run_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Time in Compute',
                value: 'run_ms'
              }
            ],
            name: 'Top Compute Time'
          },
          {
            order: {
              column: 'wait_lock_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Time Waiting',
                value: 'wait_lock_ms'
              }
            ],
            name: 'Top Lock Time'
          },
          {
            order: {
              column: 'acquire_resources_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Time in Queue',
                value: 'acquire_resources_ms'
              }
            ],
            name: 'Top in Queue'
          },
          {
            order: {
              column: 'wait_run_cpu_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Run CPU Wait Time',
                value: 'wait_run_cpu_ms'
              }
            ],
            name: 'Top CPU Waiters'
          },
          {
            order: {
              column: 'wait_run_io_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Run I/O Wait Time',
                value: 'wait_run_io_ms'
              }
            ],
            name: 'Top I/O Waiters'
          },
          {
            order: {
              column: 'wait_run_spool_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Run Spool Wait Time',
                value: 'wait_run_spool_ms'
              }
            ],
            name: 'Top Spool Waiters'
          },
          {
            order: {
              column: 'spool_ms',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Time Spooling',
                value: 'spool_ms'
              }
            ],
            name: 'Top Spool Time'
          },
          {
            order: {
              column: 'io_read_bytes',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Read I/O',
                value: 'io_read_bytes'
              }
            ],
            name: 'Top Read I/O Activity'
          },
          {
            order: {
              column: 'io_spill_read_bytes',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Temp Read I/O',
                value: 'io_spill_read_bytes'
              }
            ],
            name: 'Top Temp Read I/O Activity'
          },
          {
            order: {
              column: 'io_write_bytes',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Write I/O',
                value: 'io_write_bytes'
              }
            ],
            name: 'Top Write I/O Activity'
          },
          {
            order: {
              column: 'io_spill_write_bytes',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Temp Write I/O',
                value: 'io_spill_write_bytes'
              }
            ],
            name: 'Top Temp Write I/O Activity'
          },
          {
            order: {
              column: 'io_network_bytes',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Network I/O',
                value: 'io_network_bytes'
              }
            ],
            name: 'Top Network I/O Activity'
          },
          {
            order: {
              column: 'memory_bytes',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Peak Memory on Worker',
                value: 'memory_bytes'
              }
            ],
            name: 'Top Memory Consumers'
          },
          {
            order: {
              column: 'cpu_percent',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Average CPU Usage',
                value: 'cpu_percent'
              }
            ],
            name: 'Top CPU Consumers'
          },
          {
            order: {
              column: 'num_restart',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Restart Count',
                value: 'num_restart'
              }
            ],
            name: 'Top User Restart'
          },
          {
            order: {
              column: 'num_error',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Error Count',
                value: 'num_error'
              }
            ],
            name: 'Top Error Restart'
          },
          {
            order: {
              column: 'done_time',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Completed',
                value: 'done_time'
              }
            ],
            name: 'Recently Completed'
          },
          {
            order: {
              column: 'rows_returned',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Rows Returned',
                value: 'rows_returned'
              }
            ],
            name: 'Rows Returned'
          },
          {
            order: {
              column: 'rows_inserted',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Rows Inserted',
                value: 'rows_inserted'
              }
            ],
            name: 'Rows Inserted'
          },
          {
            order: {
              column: 'rows_deleted',
              order: 'DESC'
            },
            columns: [
              {
                name: 'Query ID',
                value: 'query_id'
              },
              {
                name: 'State',
                value: 'state'
              },
              {
                name: 'Rows Deleted',
                value: 'rows_deleted'
              }
            ],
            name: 'Rows Deleted'
          }
        ],
        session: [],
        load: [],
        unload: [],
        backup: [],
        restore: []
      }
    }
  },
  availableFilters: {
    query: [
      {
        name: 'Application',
        value: 'application_name',
        options: [],
        type: 'group-checkbox',
        category: 'Attributes'
      },
      { name: 'Host', value: 'client_ip_address', type: 'group-checkbox', category: 'Attributes' },
      {
        name: 'Priority',
        value: 'priority',
        options: [],
        type: 'group-checkbox',
        category: 'Attributes'
      },
      { name: 'Resource Pool', value: 'pool_id', type: 'group-checkbox', options: [], category: 'Attributes' },
      { name: 'Query Tag', value: 'tags', type: 'group-checkbox', options: [], category: 'Attributes' },
      { name: 'Query Type', value: 'type', type: 'group-checkbox', options: [], category: 'Attributes' },
      { name: 'State', value: 'state', type: 'group-checkbox', options: [], category: 'Attributes' },
      { name: 'User', value: 'username', type: 'group-checkbox', options: [], category: 'Attributes' },
      { name: 'Database', value: 'database_name', type: 'group-checkbox', options: [], category: 'Attributes' },
      { name: 'Cluster', value: 'cluster_name', type: 'group-checkbox', category: 'Attributes' },

      { name: 'Total Time', value: 'total_ms', type: 'regular-time', category: 'Time', subcategory: 'Most Frequently Used' },
      { name: 'Run Time', value: 'run_ms', type: 'regular-time', category: 'Time', subcategory: 'Most Frequently Used' },
      { name: 'Total Wait Time', value: 'total_wait_time_ms', type: 'regular-time', category: 'Time', subcategory: 'Most Frequently Used' },

      { name: 'Parse Time', value: 'parse_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Wait Parse Time', value: 'wait_parse_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Wait Lock Time', value: 'wait_lock_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Plan Time', value: 'plan_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Wait Plan Time', value: 'wait_plan_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Assemble Time', value: 'assemble_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Wait Assemble Time', value: 'wait_assemble_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Compile Time', value: 'compile_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Done Time', value: 'done_time', type: 'timestamp-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Wait Compile Time', value: 'wait_compile_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Acquire Resources Time', value: 'acquire_resources_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },
      { name: 'Client Wait Time', value: 'client_ms', type: 'regular-time', category: 'Time', subcategory: 'Other Time' },

      { name: 'Memory Used', value: 'memory_bytes', type: 'io', category: 'Resources', subcategory: 'Most Frequently Used' },
      { name: 'Network I/O', value: 'io_network_bytes', type: 'io', category: 'Resources', subcategory: 'Most Frequently Used' },
      { name: 'Read I/O', value: 'io_read_bytes', type: 'io', category: 'Resources', subcategory: 'Most Frequently Used' },
      { name: 'Write I/O', value: 'io_write_bytes', type: 'io', category: 'Resources', subcategory: 'Most Frequently Used' },

      { name: 'Client Read I/O', value: 'io_client_read_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Client Write I/O', value: 'io_client_write_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Disk Spool Write I/O', value: 'io_spool_write_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Memory Estimate', value: 'memory_estimated_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      {
        name: 'Memory Estimate Confidence',
        value: 'memory_estimate_confidence',
        options: [],
        type: 'group-checkbox',
        category: 'Resources',
        subcategory: 'Other I/O & Resources'
      },
      { name: 'Memory Granted', value: 'memory_granted_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Memory Used Max', value: 'memory_bytes_max', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Temp Read I/O', value: 'io_spill_read_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Temp Write I/O', value: 'io_spill_write_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Temp Space Used', value: 'io_spill_space_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Temp Space Granted', value: 'io_spill_space_granted_bytes', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },
      { name: 'Temp Space Used Max', value: 'io_spill_space_bytes_max', type: 'io', category: 'Resources', subcategory: 'Other I/O & Resources' },

      { name: 'CPU Usage', value: 'cpu_percent', type: 'regular-integer', category: 'Other', subcategory: 'Most Frequently Used' },
      { name: 'Error Code', value: 'error_code', type: 'regular-text', category: 'Other', subcategory: 'Most Frequently Used' },

      { name: 'CPU Usage Max', value: 'cpu_percent_max', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Compile CPU Usage', value: 'compile_percent', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Cache Efficiency', value: 'cache_efficiency', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Error Count', value: 'num_error', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Error Message', value: 'error_message', type: 'regular-text', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Restart Count', value: 'num_restart', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Rows Inserted', value: 'rows_inserted', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Rows Deleted', value: 'rows_deleted', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Rows Returned', value: 'rows_returned', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' },
      { name: 'Worker Count', value: 'num_workers', type: 'regular-integer', category: 'Other', subcategory: 'Miscellaneous' }
    ],
    session: [],
    load: [],
    unload: [],
    backup: [],
    restore: []
  },

  columns: {
    query: [
      {
        category: 'General',
        items: [
          { name: 'Application Name', value: 'application_name' },
          { name: 'Database Name', value: 'database_name' },
          { name: 'Host Name', value: 'client_ip_address' },
          { name: 'Priority', value: 'priority' },
          { name: 'Query ID', value: 'query_id' },
          { name: 'Query Tags', value: 'tags' },
          { name: 'Query Type', value: 'type' },
          { name: 'Resource Pool', value: 'pool_id' },
          { name: 'Session ID', value: 'session_id' },
          { name: 'State', value: 'state' },
          { name: 'User Name', value: 'username' },
          { name: 'Submit Time', value: 'submit_time' },
          { name: 'Done Time', value: 'done_time' },
          { name: 'State Time', value: 'state_time' },
          { name: 'Restart Time', value: 'restart_time' }
        ]
      },
      {
        category: 'Time',
        items: [
          { name: 'Acquire Resources Time', value: 'acquire_resources_ms' },
          { name: 'Assemble Time', value: 'assemble_ms' },
          { name: 'Cancel Time', value: 'cancel_ms' },
          { name: 'Client Time', value: 'client_ms' },
          { name: 'Compile Time', value: 'compile_ms' },
          { name: 'Lock Time', value: 'wait_lock_ms' },
          { name: 'Parse Time', value: 'parse_ms' },
          { name: 'Plan Time', value: 'plan_ms' },
          { name: 'Restart Time', value: 'restart_ms' },
          { name: 'Run Time', value: 'run_ms' },
          { name: 'Spool Time', value: 'spool_ms' },
          { name: 'Total Time', value: 'total_ms' },
          { name: 'Total Wait Time', value: 'total_wait_time' },
          { name: 'WLM Rule Time', value: 'wlm_runtime_ms' },
          { name: 'Wait Assemble Time', value: 'wait_assemble_ms' },
          { name: 'Wait Client Time', value: 'wait_client_ms' },
          { name: 'Wait Compile Time', value: 'wait_compile_ms' },
          { name: 'Wait Parse Time', value: 'wait_parse_ms' },
          { name: 'Wait Plan Time', value: 'wait_plan_ms' },
          { name: 'Wait Run CPU Time', value: 'wait_run_cpu_ms' },
          { name: 'Wait Run I/O Time', value: 'wait_run_io_ms' },
          { name: 'Wait Run Time', value: 'wait_run_time' },
          { name: 'Wait Spool Time', value: 'wait_run_spool_ms' }
        ]
      },
      {
        category: 'Resources',
        items: [
          { name: 'Client Read I/O', value: 'io_client_read_bytes' },
          { name: 'Client Write I/O', value: 'io_client_write_bytes' },
          { name: 'Disk Spool Write I/O', value: 'io_spool_write_bytes' },
          { name: 'Memory Estimated', value: 'memory_estimated_bytes' },
          { name: 'Memory Estimate Confidence', value: 'memory_estimate_confidence' },
          { name: 'Memory Granted', value: 'memory_granted_bytes' },
          { name: 'Memory Used', value: 'memory_bytes' },
          { name: 'Memory Used Max', value: 'memory_bytes_max' },
          { name: 'Network I/O', value: 'io_network_bytes' },
          { name: 'Read I/O', value: 'io_read_bytes' },
          { name: 'Temp Read I/O', value: 'io_spill_read_bytes' },
          { name: 'Temp Space Used', value: 'io_spill_space_bytes' },
          { name: 'Temp Space Granted', value: 'io_spill_space_granted_bytes' },
          { name: 'Temp Space Used Max', value: 'io_spill_space_bytes_max' },
          { name: 'Temp Write I/O', value: 'io_spill_write_bytes' },
          { name: 'Write I/O', value: 'io_write_bytes' }
        ]
      },
      {
        category: 'Other',
        items: [
          { name: 'CPU Usage Max', value: 'cpu_percent_max' },
          { name: 'CPU Usage', value: 'cpu_percent' },
          { name: 'Compile CPU Usage', value: 'compile_percent' },
          { name: 'Cluster Name', value: 'cluster_name' },
          { name: 'Cache Efficiency', value: 'cache_efficiency' },
          { name: 'Error Code', value: 'error_code' },
          { name: 'Error Count', value: 'num_error' },
          { name: 'Error Message', value: 'error_message' },
          { name: 'Restart Count', value: 'num_restart' },
          { name: 'Rows Inserted', value: 'rows_inserted' },
          { name: 'Rows Deleted', value: 'rows_deleted' },
          { name: 'Rows Returned', value: 'rows_returned' },
          { name: 'Worker Count', value: 'num_workers' }
        ]
      }
    ],
    session: [
      {
        category: 'General',
        items: [
          { name: 'Session ID', value: 'session_id' },
          { name: 'Process ID', value: 'process_id' },
          { name: 'User', value: 'session_username' },
          { name: 'Database', value: 'session_dbname' },
          { name: 'Application', value: 'application_name' },
          { name: 'Host Name', value: 'client_ip_address' },
          { name: 'State', value: 'state' },
          { name: 'Secured', value: 'secure_connection' }
        ]
      }
    ],
    load: [
      {
        category: 'General',
        items: [
          { name: 'Job', value: 'job_name' },
          { name: 'Time Started', value: 'start_time' },
          { name: 'State', value: 'state' },
          { name: 'Updated/Completed', value: 'end_time' },
          { name: 'Duration', value: 'elapsed_ms' },
          { name: 'Table Name', value: 'table_name' },
          { name: 'Parse', value: 'parsed_bytes' }
        ]
      }
    ],
    unload: [
      {
        category: 'General',
        items: [
          { name: 'Job', value: 'job_name' },
          { name: 'SQL', value: 'sql_query' },
          { name: 'Time Started', value: 'start_time' },
          { name: 'State', value: 'state' },
          { name: 'Updated/Completed', value: 'last_activity_time' },
          { name: 'Duration', value: 'elapsed_ms' },
          { name: 'Rows', value: 'sent_rows' },
          { name: 'Data Read', value: 'sent_bytes' },
          { name: 'Rows per Second', value: 'sent_rows_per_second' },
          { name: 'Data per Second', value: 'sent_bytes_per_second' }
        ]
      }
    ],
    backup: [
      {
        category: 'General',
        items: [
          { name: 'Job', value: 'job_name' },
          { name: 'Time Started', value: 'start_time' },
          { name: 'State', value: 'state' },
          { name: 'Updated/Completed', value: 'end_time' },
          { name: 'Duration', value: 'elapsed_ms' },
          { name: 'Data Transferred', value: 'sent_bytes' },
          { name: 'Transfer Rate', value: 'sent_bytes_per_sec' }
        ]
      }
    ],
    restore: [
      {
        category: 'General',
        items: [
          { name: 'Job', value: 'job_name' },
          { name: 'Time Started', value: 'start_time' },
          { name: 'State', value: 'state' },
          { name: 'Updated/Completed', value: 'end_time' },
          { name: 'Duration', value: 'elapsed_ms' },
          { name: 'Data Received', value: 'received_bytes' },
          { name: 'Transfer Rate', value: 'received_bytes_per_sec' }
        ]
      }
    ]
  }
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state)
  }
}
