// Global constants.

export const colorYbGreen = '#86BC4D'
export const colorYbYellow = '#FFCD32'
export const colorSeafoam = '#55ACBA'
export const colorSteel = '#4B5357'
export const colorSandbox = '#CF8737'
export const colorAirforce = '#1F4471'
export const colorMerlot = '#8E2E63'
export const colorPumpkin = '#F29C11'
export const colorSage = '#2E7A50'
export const colorPlum = '#412250'
export const colorLava = '#D34A24'
export const colorBluebird = '#1C76E6'

export const fgDark = '#4A4A4A'
export const fgLight = '#F7F7F7'

export const seriesColors = [
  { bg: colorYbGreen, fg: fgLight },
  { bg: colorYbYellow, fg: fgDark },
  { bg: colorSeafoam, fg: fgDark },
  { bg: colorSteel, fg: fgLight },
  { bg: colorSandbox, fg: fgLight },
  { bg: colorAirforce, fg: fgLight },
  { bg: colorMerlot, fg: fgLight },
  { bg: colorPumpkin, fg: fgLight },
  { bg: colorSage, fg: fgLight },
  { bg: colorPlum, fg: fgLight },
  { bg: colorLava, fg: fgLight },
  { bg: colorBluebird, fg: fgLight }
]

// Internal build?  It's either running on (1) localhost, (2) yellowbrickcloud.com or (3) yellowbrick.io
const internalHost = () => {
  const hostname = window?.location?.hostname || 'unknown'
  return hostname === 'localhost' ||
    hostname.match(/\.yellowbrickcloud\.com$/) ||
    hostname.match(/\.yellowbrick\.io$/)
}
export { internalHost }

// Development deployment?  It's either running on (1) localhost, (2) yellowbrick.io
const developmentHost = () => {
  const hostname = window?.location?.hostname || 'unknown'
  return hostname === 'localhost' ||
    hostname.match(/\.yellowbrick\.io$/)
}
export { developmentHost }

// From chat gpt4 :)
const cidrRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})\/(3[0-2]|[1-2]?[0-9])$/
export { cidrRegex }
