import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error"
import { getInstance } from '@/auth'

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT
})

const authLink = setContext((request) => {
  const token = getInstance()?.accessToken
  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  } else {
    return {
      headers: {}
    }
  }
})

const errorLink = onError(({ response }) => {
  const message = response?.errors?.[0]?.message
  if (message === undefined) {
    // If no message, there's a network error. Should be caught in individual call
  } else if (message === 'Forbidden') {
    console.log('[ym] unauthorized; returning to login')
    useLogout().logout()
  }
})

// Disable dev tools warning.
window.__APOLLO_DEVTOOLS_GLOBAL_HOOK__ = true

const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: false
})

apolloClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'no-cache'
  }
}

export default apolloClient
