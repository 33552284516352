import pako from 'pako'

export function decode(str) {
  if (str && str.match(/^G64:/)) {
    return pako.inflate(atob(str.substring(4)), { to: 'string' })
  } else {
    return str
  }
}

export function encode(str) {
  return 'G64:' + btoa(pako.deflate(str, { to: 'string' }))
}
