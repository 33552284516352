import DatabaseModel from './DatabaseModel'
import Table from './Table'
import View from './View'
import Sequence from './Sequence'
import Procedure from './Procedure'
import Function from './Function'
import * as functions from '@/util/functions'

export default class Schema extends DatabaseModel {
  static entity = 'schema'

  static primaryKey = ['instance_id', 'database_id', 'schema_id']

  static fields () {
    return {
      ...super.fields(),
      schema_id: this.number(null).nullable(),
      __type: this.attr('schema'),
      creation_time: this.string(null).nullable(),
      table_count: this.number(0),
      bytes_uncompressed: this.number(0),
      bytes_compressed: this.number(0),
      can_create: this.boolean(null).nullable(),
      rows: this.number(0),
      populated: this.boolean(true)
    }
  }

  get schema_name() {
    return this.name
  }

  get id() {
    return this.schema_id
  }

  get path() {
    return `${this.database_name}`
  }

  get tables() {
    return Table.query().where('instance_id', this.instance_id).where('database_id', this.database_id).where('schema_id', this.schema_id).get().sort(functions.sortByName)
  }

  get views() {
    return View.query().where('instance_id', this.instance_id).where('database_id', this.database_id).where('schema_id', this.schema_id).get().sort(functions.sortByName)
  }

  get sequences() {
    return Sequence.query().where('instance_id', this.instance_id).where('database_id', this.database_id).where('schema_id', this.schema_id).get().sort(functions.sortByName)
  }

  get procedures() {
    return Procedure.query().where('instance_id', this.instance_id).where('database_id', this.database_id).where('schema_id', this.schema_id).get().sort(functions.sortByName)
  }

  get functions() {
    return Function.query().where('instance_id', this.instance_id).where('database_id', this.database_id).where('schema_id', this.schema_id).get().sort(functions.sortByName)
  }

  get counts() {
    return {
      table: this.tables.length,
      view: this.views.length,
      sequence: this.sequences.length,
      function: this.functions.length,
      procedure: this.procedures.length
    }
  }

  static filterCommonRE = /^(information_schema|sys|pg_catalog)$/
  static filterCommon =
    schema => !schema.name?.match(Schema.filterCommonRE)
}
