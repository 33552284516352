import { Model } from '@vuex-orm/core'

export default class SystemPrivilege extends Model {
  static entity = 'systemPrivilege'

  static primaryKey = ['instance_id', 'privilege_type']

  static fields () {
    return {
      instance_id: this.string(null).nullable(),
      __type: this.attr('privilege'),
      privilege_type: this.string(null).nullable(),
      granted: this.boolean(null).nullable(),
      is_grantable: this.boolean(null).nullable()
    }
  }
}
