import SchemaModel from './SchemaModel'

export default class Procedure extends SchemaModel {
  static entity = 'procedure'

  static primaryKey = ['instance_id', 'procedure_id']

  static fields () {
    return {
      ...super.fields(),
      procedure_id: this.number(null).nullable(),
      __type: this.attr('procedure'),
      definition: this.string(null).nullable(),
      result_type: this.string(null).nullable(),
      argument_types: this.string(null).nullable()
    }
  }

  get id() {
    return this.procedure_id
  }
}
