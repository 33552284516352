class NotFound extends Error {
  constructor(...params) {
    super(...params)
  }
}

export { NotFound }

class ResponseError extends Error {
  constructor(response, message) {
    super(String(response?.error?.message || response?.error?.error || response?.error || message || 'Unknown response error'))
    this.name = 'ResponseError'
    this.response = response
    this.applicationError = false
  }
}

ResponseError.prototype.toString = function () {
  return this.message
}

export { ResponseError }
