import { type App } from 'vue'
import Toast, { useToast, POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { confirmModal, alertModal, componentModal, clearAllModals, promptModal } from './modal'
import { DEFAULT_TITLE } from '@/components/YbModal.vue'

const toastification = useToast()

let _title = DEFAULT_TITLE

// Default options.
const defaultDuration = 5000

type TOpenToast<T = any> = {
  promise: Promise<T> | null;
  resolve: ((value: T | PromiseLike<T>) => void) | null;
  reject: ((reason?: any) => void) | null;
}

type TOpenToasts = {
  [key: string]: TOpenToast;
}

let openToasts: TOpenToasts = {}

function toast(message: string, closeable: boolean|any = true, duration: any|number = defaultDuration, method: string, className: string | null) {
  if (typeof closeable !== 'boolean') { // legacy
    if (typeof closeable?.timeOut === 'number') {
      duration = closeable.timeOut
    }
    closeable = true
  }
  if (typeof duration === 'object') { // legacy
    if (typeof duration?.timeOut === 'number') {
      duration = duration.timeOut
    } else {
      duration = defaultDuration
    }
  }
  if (duration < 0) {
    duration = Number.MAX_VALUE
  }
  if (!closeable) {
    duration = duration || defaultDuration
    closeable = false // must be boolean
  }

  // Make the toast key.
  const key = `${message}:${method}`
  let openToast = openToasts[key]
  if (openToast) {
    return openToast.promise
  }

  // Pop the toast.
  openToast = openToasts[key] = { promise: null, resolve: null, reject: null }
  openToast.promise = new Promise((resolve, reject) => {
    openToast.resolve = resolve
    openToast.reject = reject
    toastification[method](
      message,
      {
        timeout: duration || defaultDuration,
        toastClassName: className || '',
        onClose: resolve
      }
    )
  })
  openToast.promise
    .finally(() => {
      delete openToasts[key]
    })
  return openToast.promise
}

const dialogs = {
  info(message : string, closeable = true, duration = defaultDuration) {
    return toast(message, closeable, duration, 'info', 'yb-toast-info')
  },
  warning(message : string, closeable = true, duration = Number.MAX_VALUE) {
    return toast(message, closeable, duration, 'warning', 'yb-toast-warning')
  },
  timeout(message : string, closeable = true, duration = Number.MAX_VALUE) {
    return toast(message, closeable, duration, 'warning', 'yb-toast-timeout')
  },
  error(message : string, closeable = true, duration = Number.MAX_VALUE) {
    return toast(message, closeable, duration, 'error', 'yb-toast-error')
  },
  notifyClipboard(text : string, label = null) {
    const TRUNCATE_LIMIT = 100

    // Notify the user.
    let truncated = text.substring(0, TRUNCATE_LIMIT)
    if (truncated !== text) {
      truncated += '...'
    }
    this.info('Copied text to clipboard: ' + (label || truncated))
  },
  alert(message : string) {
    return alertModal(_title, message)
  },
  confirm(message : string, acceptLabel : string, cancelLabel : string) {
    return confirmModal(_title, message, acceptLabel, cancelLabel)
  },
  component(title : string, acceptLabel : string, component, componentProps, parent, modalProps) {
    return componentModal(title, acceptLabel, component, componentProps, parent, modalProps)
  },
  prompt(title : string, message : string, buttonLabels: string[]) {
    return promptModal(title, message, buttonLabels)
  },
  title(_) {
    if (_) {
      _title = _
    }
    return _title
  },
  clearAll() {
    toastification.clear && toastification.clear()
    clearAllModals()
  }
}

export default dialogs

export function install(app: App<Element>) {
  // We use some toast here
  app.use(Toast, {
    position: POSITION.TOP_RIGHT,
    hideProgressBar: true
  })

  // Setup dialogs as top-level var.
  app.config.globalProperties.$dialogs = dialogs
}

export function clearDialogsAndToasts() {
  // Clear toasts when we change routes.
  Object.values(openToasts).forEach(t => t.resolve())
  openToasts = {}
  toastification.clear()
}
