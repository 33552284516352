import { Model, Record, Collections } from '@vuex-orm/core'
import Instance from './Instance'

export default class InstanceModel extends Model {
  static typeKey = '__entityType'

  static entity = 'instanceModel'

  static fields () {
    return {
      instance_id: this.string(null),
      name: this.string(null).nullable(),
      instance: this.belongsTo(Instance, 'instance_id', 'id')
    }
  }

  static async insertWithInstance<T extends typeof InstanceModel>(
    this: T,
    instanceId: String,
    payload: any, /* Payloads.InsertOrUpdate */
    database_id: number|undefined,
    replaceInsert: boolean|undefined
  ): Promise<Collections> {
    // Add instance id to each model given.
    payload.data = payload.data.map((m) => {
      return {
        ...m,
        instance_id: instanceId
      }
    })

    // Do we have bulk insert optimization?
    if (replaceInsert) {
      // Delete all of this type for this instance/database.
      await this.delete((m: any) =>
        m.instance_id === instanceId && (!database_id || m.database_id === database_id)
      )

      // Do the insert.
      return await this.insert(payload)
    } else {
      const result = await this.insertOrUpdate(payload)

      // Gather the existing ids, and use it to remove those that disappeared.
      const keys = this.primaryKey as string[]
      const modelIdFor = (m: T) => keys.map(keyId => m[keyId]).join(':')
      const idsKept = new Set(payload.data.map(modelIdFor))
      this.delete((m: any) =>
        !idsKept.has(modelIdFor(m)) && (!database_id || m.database_id === database_id)
      )

      return result
    }
  }

  $getAttributes(): Record {
    const result = super.$getAttributes()
    return Object.freeze(result)
  }

  get label() {
    return (this as any).name
  }

  get location() {
    return (this as any).name
  }

  get quotedLocation() {
    return `"${(this as any).name}"`
  }
}
