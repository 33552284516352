<template>
  <span :class="classes" v-bind="$attrs">
    <component
      :is="iconComponent"
      v-if="iconComponent"
      ref="svg"
      :class="fill ? 'fill-current' : ''"
    />
  </span>
</template>

<script>
import * as icons from '../assets/icons/icons.js'

export default {
  name: 'YbIcon',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      required: true
    },
    fill: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: [Number, String],
      default: 0
    },
    iconClass: {
      type: String,
      default: 'yb-icon'
    }
  },
  computed: {
    useFill() {
      return this.fill ? 'currentColor' : ''
    },
    iconName() {
      let result = String(this.icon).replace(/-/g, '_') // undo kebab-case for snake_case

      // Add underscore for known JS keywords.
      if (result.match(/^(delete|function|export|import|class)$/)) {
        result += '_'
      }

      return result
    },
    iconComponent() {
      const { iconName } = this
      const result = icons[iconName]

      // NB: our lookup here must be an object that has a render fn.
      return !!result && (result instanceof Object) && (result.render instanceof Function) && result
    },
    classes() {
      const result = []
      if (this.iconClass) {
        result.push(this.iconClass)
      }
      if (this.fill) {
        result.push('fill-current')
      }
      result.push(`stroke-${this.stroke}`)
      return result
    }
  }
}
</script>

<style scoped>
.yb-icon svg.yb-svg-icon {
  width: 100%  !important;
  height: auto !important;
}

.yb-icon:not(.stroke-inherit) svg.yb-svg-icon,
.yb-icon:not(.stroke-inherit) svg.yb-svg-icon :deep(*) {
  stroke: currentColor;
}

.yb-icon.fill-current svg.yb-svg-icon,
.yb-icon.fill-current svg.yb-svg-icon :deep(*) {
  fill: currentColor;
}
</style>
