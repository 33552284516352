import SchemaModel from './SchemaModel'

export default class Function extends SchemaModel {
  static entity = 'function'

  static primaryKey = ['instance_id', 'function_id']

  static fields () {
    return {
      ...super.fields(),
      function_id: this.number(null).nullable(),
      __type: this.attr('function'),
      definition: this.string(null).nullable(),
      function_type: this.string(null).nullable(),
      result_type: this.string(null).nullable(),
      argument_types: this.string(null).nullable()
    }
  }

  get id() {
    return this.function_id
  }
}
