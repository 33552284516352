export default {
  props: {
    label: {
      type: String,
      required: false
    },
    buttonClasses: String,
    baseButtonClass: {
      type: String,
      default: 'yb-button'
    },
    actionLinkBaseClasses: {
      type: String,
      default: 'py-1'
    },
    icon: {
      type: String,
      required: false
    },
    iconClasses: String,
    dataTestId: {
      type: String
    },
    disabled: Boolean,
    actionLink: Boolean
  },
  computed: {
    buttonClass() {
      const result = this.actionLink ? ['yb-button', this.actionLinkBaseClasses] : [this.baseButtonClass]
      const { buttonClasses, disabled } = this
      if (!!buttonClasses && this.baseButtonClass !== buttonClasses) {
        result.push(buttonClasses)
      } else if (false) {
        result.push(...['border', 'yb-border-content', 'rounded-sm'])
      }
      if (disabled) {
        result.push('opacity-50')
        result.push('cursor-not-allowed')
      }
      result.push('select-none')
      result.push(...this.actionLinkClasses)
      return result
    },
    iconClass() {
      const result = []
      const { iconClasses, disabled } = this
      if (iconClasses) {
        result.push(iconClasses)
      }
      const hasIcon = !!result.find(c => c.match(/yb-button-icon/))
      if (!hasIcon) {
        result.push('yb-button-icon')
      }
      if (disabled) {
        result.push('opacity-50')
      }
      return result
    },
    actionLinkClasses() {
      if (!this.actionLink) {
        return []
      }
      return ['text-sm', 'border', 'border-yb-gray-lighter', 'rounded-md', this.disabled ? '' : 'hover:bg-yb-gray-fainter dark:hover:bg-yb-gray-alt-lightest-inverse', 'dark:bg-yb-gray-alt-dark', 'dark:border-yb-gray-alt-lightest-inverse', 'dark:hover:border-yb-yellow']
    }
  }
}
