import { databaseObjectService } from '@/services'
import Database from '@/models/Database'

const databasesPopulating = {}

export default {
  namespaced: true,
  actions: {
    async populate({ commit, state, rootState }, instance) {
      if (!instance || !instance.online) {
        return
      }
      if (databasesPopulating[instance.id]) {
        return databasesPopulating[instance.id]
      }
      try {
        databasesPopulating[instance.id] = databaseObjectService.populateDatabases(instance.id)
        const databases = await databasesPopulating[instance.id]
        if (databases) {
          await Database.insertWithInstance(instance.id, { data: databases })
        }
        return databases
      } finally {
        delete databasesPopulating[instance.id]
      }
    }
  }
}
