import jolokiaService from './jolokiaService'
import baseUrl from './remoteService'
import * as errorHandler from './errorHandler'

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

class ConnectionService {
  constructor() {
    this.connections = {}
  }

  connect(instanceId) {
    if (!instanceId.match(uuidRegex)) {
      throw new Error('Developer bug! Not connecting to an instance: ' + instanceId)
    }
    baseUrl.set(`instance://${instanceId}/`)
    baseUrl.proxy('/proxy')
    if (!jolokiaService.started()) {
      jolokiaService.start()
    }

    // No need to check again.
    if (typeof this.connections[instanceId] !== 'undefined') {
      return this.connections[instanceId]
    }

    try {
      baseUrl.loading = true

      // TODO: check version.
      this.connections[instanceId] = true
      return this.connections[instanceId]
    } catch (e) {
      console.error('Unable to open connection')
      console.log(e)
      if (import.meta.env.MODE !== 'production') {
        errorHandler.error('Instance', `Failed to login to: ${baseUrl.url('/')}`)
      }
      return false
    } finally {
      baseUrl.loading = false
    }
  }

  disconnect(instanceId) {
    delete this.connections[instanceId]
  }
}

const connectionService = new ConnectionService()
export default connectionService
