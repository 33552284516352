export const createDollarQuotedDelimiter = () => '$_' + (parseInt(Math.random() * 1000000000000)).toString(36).substring(0, 6) + '_$'

export function parseFilter(where, filter, columns = []) {
  const dollarQuoted = createDollarQuotedDelimiter()
  if (typeof filter === 'string') {
    const expr = `${dollarQuoted}%${filter}%${dollarQuoted}`
    where.push('and (' + columns.map(column => `${column} ilike ${expr}`).join(' or ') + ')')
  } else if (typeof filter === 'object') {
    Object.entries(filter).forEach(([column, expression]) => {
      function re(expression) {
        return expression.replace(/\*/, '.*').replace(/\?/, '.')
      }

      switch (expression.type) {
        case 'equals':
          where.push(`and ${column} = ${dollarQuoted}${expression.filter}${dollarQuoted}`)
          break
        case 'notEqual':
          where.push(`and ${column} != ${dollarQuoted}${expression.filter}${dollarQuoted}`)
          break
        case 'contains':
          where.push(`and ${column} ~* ${dollarQuoted}${re(expression.filter)}${dollarQuoted}`)
          break
        case 'notContains':
          where.push(`and ${column} !~* ${dollarQuoted}${re(expression.filter)}${dollarQuoted}`)
          break
        case 'startsWith':
          where.push(`and ${column} ~* ${dollarQuoted}^${re(expression.filter)}${dollarQuoted}`)
          break
        case 'endsWith':
          where.push(`and ${column} ~* ${dollarQuoted}${re(expression.filter)}$${dollarQuoted}`)
          break
      }
    })
  } else if (filter) {
    throw new Error('Unknown filter type: ' + (typeof filter))
  }
}
