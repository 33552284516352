import Instance from './Instance'
import InstanceModel from './InstanceModel'

export default class WLMRule extends InstanceModel {
  static entity = 'wlmRule'

  static primaryKey = ['instance_id', 'rule_name']

  static fields () {
    return {
      ...super.fields(),
      rule_name: this.string(null).nullable(),
      __type: this.attr('wlm_rule'),
      profile_name: this.string(null).nullable(),
      rule_type: this.string(null).nullable(),
      order: this.number(null).nullable(),
      enabled: this.boolean(null).nullable(),
      superuser: this.boolean(null).nullable(),
      expression: this.string(null).nullable()
    }
  }

  get name() {
    return this.rule_name
  }

  set name(n) {
    // no-op to avoid TypeError
  }
}
