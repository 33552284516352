import Instance from './Instance'
import InstanceModel from './InstanceModel'

export default class WLMResourcePool extends InstanceModel {
  static entity = 'wlmResourcePool'

  static primaryKey = ['instance_id', 'name']

  static fields () {
    return {
      ...super.fields(),
      __type: this.attr('wlm_resource_pool'),
      profile_name: this.string(null).nullable(),
      memory_requested: this.string(null).nullable(),
      memory_per_query_bytes: this.number(null).nullable(),
      temp_space_requested: this.string(null).nullable(),
      temp_space_per_query_bytes: this.number(null).nullable(),
      max_concurrency: this.number(null).nullable(),
      min_concurrency: this.number(null).nullable(),
      queue_size: this.number(null).nullable(),
      max_row_limit: this.number(null).nullable(),
      max_wait_time_limit: this.number(null).nullable(),
      max_exec_time_limit: this.number(null).nullable()
    }
  }
}
