import { createStore as vuexCreateStore } from 'vuex'
import VuexORM from '@vuex-orm/core'

import pathify from './pathify'
import vuexLocal from './vuexLocalStorage'
import Cluster from '@/models/Cluster'
import Database from '@/models/Database'
import DatabaseModel from '@/models/DatabaseModel'
import Format from '@/models/Format'
import Function from '@/models/Function'
import HardwareInstanceType from '@/models/HardwareInstanceType'
import Instance from '@/models/Instance'
import InstanceModel from '@/models/InstanceModel'
import Location from '@/models/Location'
import OwnerModel from '@/models/OwnerModel'
import Procedure from '@/models/Procedure'
import Role from '@/models/Role'
import Schema from '@/models/Schema'
import SchemaModel from '@/models/SchemaModel'
import Sequence from '@/models/Sequence'
import Storage from '@/models/Storage'
import SystemPrivilege from '@/models/SystemPrivilege'
import Table from '@/models/Table'
import View from '@/models/View'
import WLMProfile from '@/models/WLMProfile'
import WLMResourcePool from '@/models/WLMResourcePool'
import WLMRule from '@/models/WLMRule'
// import createLogger from 'vuex/dist/logger';
import logRocketService from '@/services/logrocket'
import { functions } from '@/util'
import remoteService from "@/services/remoteService"

const debug = import.meta.env.MODE !== 'production'
const strict = import.meta.env.VITE_STORE_STRICT === 'true'
const statelogger = String(window.location.search).match(/statelogger/)

export function createStore(modules = {}, appName) {
  if (appName) {
    vuexLocal.appName = appName
  }
  const database = new VuexORM.Database()

  // Base objects (must be in order).
  database.register(InstanceModel)
  database.register(OwnerModel)
  database.register(DatabaseModel)
  database.register(SchemaModel)

  // Class objects.
  database.register(Cluster)
  database.register(Database)
  database.register(Format)
  database.register(Function)
  database.register(HardwareInstanceType)
  database.register(Instance)
  database.register(Location)
  database.register(Procedure)
  database.register(Role)
  database.register(Schema)
  database.register(Sequence)
  database.register(Storage)
  database.register(SystemPrivilege)
  database.register(Table)
  database.register(View)
  database.register(WLMProfile)
  database.register(WLMResourcePool)
  database.register(WLMRule)

  const plugins = [VuexORM.install(database), pathify.plugin, vuexLocal.plugin.bind(vuexLocal)]
  // if (statelogger) {
  //   plugins.unshift(createLogger());
  // }
  logRocketService.addVuexPlugin(plugins)

  const store = vuexCreateStore({
    modules,
    strict,
    plugins
  })

  statelogger && pathify.debug()

  // Setup loading interceptor.
  remoteService.setLoadingInterceptor((_) => {
    store.set('global/loading', _)
  })

  return store
}

export function gatherModules(importContext) {
  const modules = {}
  Object.entries(importContext).forEach(([fileName, module]) => {
    if (fileName === './index.js') {
      return
    }
    // filter fullstops and extension
    fileName = functions.parsePath(fileName).name
    const moduleName = fileName.replace(/(\.\/|\.js)/g, '')
    modules[moduleName] = module.default || module
  })
  false && console.log('Gathered modules for store', modules)
  return modules
}
