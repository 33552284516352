import tasksService from './tasksService'
import connectionService from './connectionService'
import { mbeans } from './constants'
import jolokiaService from './jolokiaService'
import apollo from '@/apollo'
import INSTANCE_TASK_CREATE from '@/graphql/instanceTaskCreate.gql'
import INSTANCE_TASK_STATUS from '@/graphql/instanceTaskStatus.gql'
import INSTANCE_TASK_CANCEL from '@/graphql/instanceTaskCancel.gql'
import INSTANCE_TASK_DELETE from '@/graphql/instanceTaskDelete.gql'
import INSTANCE_CREATE from '@/graphql/instanceCreate.gql'
import INSTANCE_DELETE from '@/graphql/instanceDelete.gql'
import INSTANCE_UPDATE from '@/graphql/instanceUpdate.gql'
import IS_INSTANCE_NAME_AVAILABLE from '@/graphql/isInstanceNameAvailable.gql'
import VERSIONS from '@/graphql/versions.gql'
import VERSION_CREATE from '@/graphql/versionCreate.gql'
import SYSTEM_RESIZE from '@/graphql/systemResize.gql'
import SHARED_SERVICES_TYPES from '@/graphql/sharedServicesTypes.gql'
import HARDWARE_INSTANCE_TYPES from '@/graphql/hardwareInstanceTypes.gql'
import HARDWARE_INSTANCE_UPDATE from '@/graphql/updateHardwareInstanceType.gql'
import RESERVED_NODE_STATISTICS from '@/graphql/reservedNodeStatistics.gql'
import INSTANCE_CERTIFICATE from '@/graphql/instanceCertificate.gql'
import * as functions from '@/util/functions'
import Instance from '@/models/Instance'
import { versionService } from '@/services'

class InstanceService {
  async getStorageUtilizationDetail(instance_id, database_name) {
    if (!instance_id) {
      throw new Error(`Parameter 'instance_id' is required for populate`)
    }
    const result = { compressed_bytes: 0, uncompressed_bytes: 0 }
    const connected = await connectionService.connect(instance_id)
    if (connected) {
      const where = database_name ? { name: database_name } : {}
      const response = await jolokiaService.execute(
        mbeans.sysDatabase,
        'retrieve',
        [
          {
            columns: [
              'sum(compressed_bytes) as compressed_bytes',
              'sum(uncompressed_bytes) as uncompressed_bytes'
            ],
            where
          }
        ]
      )
      return (response.rows || [])[0]
    }
    return result
  }

  async getHardwareInstanceTypes(instanceId) {
    const response = await apollo.query({
      query: HARDWARE_INSTANCE_TYPES,
      variables: {
        instanceId
      }
    })

    const {
      data: {
        hardwareInstanceTypes
      }
    } = response

    return hardwareInstanceTypes
  }

  async getReservedNodeStatistics(instanceId) {
    const response = await apollo.query({
      query: RESERVED_NODE_STATISTICS,
      variables: {
        instanceId
      }
    })

    const {
      data: {
        reservedNodeStatistics
      }
    } = response

    return reservedNodeStatistics
  }

  updateHardwareInstanceType({ name, instanceId, props }) {
    return apollo.mutate({
      mutation: HARDWARE_INSTANCE_UPDATE,
      variables: {
        name,
        instanceId,
        props
      }
    })
  }

  async systemResize(sharedServicesType) {
    const response = await apollo.mutate({
      mutation: SYSTEM_RESIZE,
      variables: {
        input: {
          sharedServicesType
        }
      }
    })
    const {
      data
    } = response
    return data
  }

  async isInstanceNameAvailable(instanceName) {
    const response = await apollo.query({ query: IS_INSTANCE_NAME_AVAILABLE, variables: { instanceName } })
    const {
      data
    } = response
    return !!data?.available
  }

  async getSharedServicesTypes() {
    const response = await apollo.query({ query: SHARED_SERVICES_TYPES })
    const {
      data
    } = response
    return data
  }

  async getVersions(instanceId, namespace) {
    const response = await apollo.query({ query: VERSIONS, variables: { instanceId, namespace } })
    return (response?.data?.versions.sort(versionService.sortVersion).reverse() || [])
      .filter(v => !String(v.name).match(/^[5-6]/))
  }

  async createVersion(namespace, version, registry) {
    const response = await apollo.mutate({
      mutation: VERSION_CREATE,
      variables: {
        namespace,
        version,
        registry
      }
    })
    return response?.data?.createVersion
  }

  async createInstance(name, namespace, version, sharedServicesType, storageManaged, reservedNodes, initialAdmin, initialAdminPassword, store) {
    sharedServicesType ??= 'standard'
    reservedNodes ??= []
    const params = {
      name,
      namespace,
      version,
      storageManaged,
      sharedServicesType,
      reservedNodes,
      initialAdmin,
      initialAdminPassword
    }
    const result = await manageJobStatus(null, null, jobInstanceOperationHandler({
      operation: 'Create',
      mutation: INSTANCE_CREATE,
      variables: { params }
    }), store)

    // Look for instance to stop resuming before returning
    if (result?.results?.id) {
      console.log(`[ym] created instance ${name}, id ${result?.results?.id}`)
      const expire = +new Date() + (60 * 60 * 1000)
      while (+new Date() < expire) {
        console.log(`[ym] waiting for instance ${name} to be ready after create`)
        await functions.timeout(15 * 1000)

        // Populate instances.
        await store.dispatch('instance/invalidate')
        await store.dispatch('instance/populate')

        // Check instance state.
        const instance = Instance.query().where('id', result?.results?.id).first()
        if (!instance) {
          console.log(`[ym] while creating instance ${name}, it did not show up after create`)
          break
        }
        if (instance.status?.type === 'ERROR') {
          console.log(`[ym] instance ${name} reached error state: ${instance.status?.error}`)
          throw new Error(`The instance create operation failed: ${instance.status?.error}`)
        }
        if (instance.status?.type !== 'RESUMING') {
          console.log(`[ym] instance ${name} reached non-resuming state: ${instance.status?.type}`)
          break
        }
      }
    }

    return result
  }

  async destroyInstance(instanceId, store) {
    return await manageJobStatus(instanceId, 'Deleting', jobInstanceOperationHandler({
      operation: 'Delete',
      mutation: INSTANCE_DELETE,
      variables: { instanceId }
    }), store)
  }

  async suspendInstance(instanceId, store) {
    return await manageJobStatus(instanceId, 'Suspending', jobInstanceOperationHandler({
      operation: 'Suspend',
      mutation: INSTANCE_TASK_CREATE,
      variables: { instanceId, operation: 'Suspend' }
    }), store)
  }

  async resumeInstance(instanceId, store) {
    return await manageJobStatus(instanceId, 'Resuming', jobInstanceOperationHandler({
      operation: 'Resume',
      mutation: INSTANCE_TASK_CREATE,
      variables: { instanceId, operation: 'Resume' }
    }), store)
  }

  async setInstanceVersion(instanceId, version, store) {
    return await manageJobStatus(instanceId, 'Upgrading', jobInstanceOperationHandler({
      operation: 'SetVersion',
      mutation: INSTANCE_TASK_CREATE,
      variables: { instanceId, operation: 'SetVersion', options: { version } }
    }), store)
  }

  async resizeInstance(instanceId, sharedServicesType, store) {
    return await manageJobStatus(instanceId, 'Changing', jobInstanceOperationHandler({
      operation: 'ChangeSharedServices',
      mutation: INSTANCE_TASK_CREATE,
      variables: { instanceId, operation: 'ChangeSharedServices', options: { sharedServicesType } }
    }), store)
  }

  async diagnostics(instanceId, startTime, endTime, store) {
    return await manageJobStatus(instanceId, null, jobInstanceOperationHandler({
      operation: 'Diagnostics',
      mutation: INSTANCE_TASK_CREATE,
      variables: { instanceId, operation: 'Diagnostics', options: {diagsInput: {startTime, endTime }}  }
    }), store)
  }

  async updateInstance(instanceId, allowlistIpCidrs) {
    const response = await apollo.mutate({
      mutation: INSTANCE_UPDATE,
      variables: {
        instanceId,
        allowlistIpCidrs
      }
    })
    const {
      data
    } = response
    return data?.updateInstance
  }

  async retrieveInstanceCertificate(instanceId) {
    const response = await apollo.query({
      query: INSTANCE_CERTIFICATE,
      variables: {
        instanceId
      }
    })
    const {
      data: {
        instanceCertificate: {
          certificate
        }
      }
    } = response
    return certificate
  }

  async cancelInstanceTask(instanceTaskNamespace, instanceTaskName) {
    const response = await apollo.mutate({
      mutation: INSTANCE_TASK_CANCEL,
      variables: {
        instanceTaskNamespace,
        instanceTaskName
      }
    })
    const {
      data
    } = response
    return data?.instanceTaskCancel
  }

  async deleteInstanceTask(instanceTaskNamespace, instanceTaskName) {
    const response = await apollo.mutate({
      mutation: INSTANCE_TASK_DELETE,
      variables: {
        instanceTaskNamespace,
        instanceTaskName
      }
    })
    const {
      data
    } = response
    return data?.instanceTaskDelete
  }
}

async function manageJobStatus(instanceId, jobStatus, op, store) {
  try {
    // Overlay job status, ensure instance is not seen as online and state change is immediate.
    if (jobStatus && instanceId) {
      await Instance.update({
        where: instanceId,
        data: {
          jobStatus: {
            type: jobStatus.toUpperCase(),
            description: jobStatus
          }
        }
      })

      const instance = Instance.query().whereId(instanceId).first()
      if (!instance.jobStatus) {
        throw new Error('Could not update job status to ' + jobStatus)
      }
    }

    // Run the operation.
    return await op(instanceId)
  } finally {
    try {
      // Refresh state, remove status overlay.
      await store.dispatch('instance/invalidate')
      await store.dispatch('instance/populate')

      // Remove status overlay.
      if (jobStatus && instanceId) {
        await Instance.update({
          where: instanceId,
          data: {
            jobStatus: null
          }
        })
      }
    } catch (e) {
      // ignore; may have gone away.
      console.error('Could not query instance', instanceId)
      console.error(e)
    }
  }
}

class JobFatalError extends Error {
  constructor(...params) {
    super(...params)
  }
}

// Start, stop, delete are all considered vanilla Instance operations. They
// operate on an existing instance, take only the ID of the instance, and
// provide no new output variables.
function jobInstanceOperationHandler({ operation, mutation, variables = {} }) {
  return async (instanceId) => {
    const start = +new Date()
    console.log(`[ym] ${operation} instance ${instanceId} started`)

    // Do the operation.
    const response = await apollo.mutate({
      mutation,
      variables
    })
    tasksService.pollTasks()
    const { instanceTaskNamespace, instanceTaskName } = response?.data?.task
    console.log(`[ym] ${operation} instanceTaskNamespace ${instanceTaskNamespace}, instanceTaskName ${instanceTaskName}`)

    // TODO: check for errors.

    // Poll the job completion
    // We'll wait up to 1hr for the instance operation to complete.
    const expire = +new Date() + 60 * 60 * 1000
    let foundOnce = false
    while (+new Date() < expire) {
      // Delay until next check.
      await functions.timeout(15 * 1000)

      try {
        // Ask for job status.
        const response = await apollo.query({
          query: INSTANCE_TASK_STATUS,
          variables: {
            instanceTaskName,
            instanceTaskNamespace
          }
        })
        const {
          data: {
            instanceTaskStatus: { state, summary }
          }
        } = response
        foundOnce = true

        // Possible end stages:
        // - Completed
        // - Error
        if (state === 'Completed') {
          console.log(
            `[ym] ${operation} instance ${instanceId} succeeded in ${
              +new Date() - start
            }ms.`
          )
          return response.data.instanceTaskStatus
        } else if (state === 'Error') {
          const error = `Instance ${operation} failed: ${summary}`
          console.log('[ym]', error, 'in', +new Date() - start, 'ms', response)
          throw new JobFatalError(error)
        }

      } catch (e) {
        if (e instanceof JobFatalError) {
          throw e
        }
        if (String(e?.message).match(/not.found/i)) {
          if (!foundOnce) {
            continue
          }
        }
      }
    }

    throw new Error(`Could not ${operation} instance: timed out`)
  }
}

const instanceService = new InstanceService()
export default instanceService
