import { make } from '../pathify'
import storeService from '..'
import { databaseObjectService } from '@/services'
import Instance from '@/models/Instance'

const defaultCsvTextFormatOptions = {
  delimiter: null,
  linesep: null,
  quote_char: null,
  escape_char: null,
  emptymarker: null,
  nullmarker: null,
  num_header_lines: 1,
  trim_white: false,
  date_style: null,
  on_extra_field: null,
  on_missing_field: null,
  on_unescaped_embedded_quote: null,
  on_invalid_char: null,
  on_string_too_long: null
}

const defaultParquetFormatOptions = {
  ignore_unsupported_schema: true,
  serialize_nested_as_json: true
}

const defaultFormatOptions = {
  type: null,
  ...defaultCsvTextFormatOptions,
  ...defaultParquetFormatOptions,
  advanced: []
}

const state = {
  cache: {
    instance: null,
    cluster: null,
    database: null,
    populated: 0
  },
  settings: {
    instance: '',
    database: '',
    location: null,
    format: null,
    recentPatterns: [],
    table: null,
    tableSchema: null
  },
  importPatterns: [],
  defaultFormatOptions,
  defaultCsvTextFormatOptions,
  defaultParquetFormatOptions,
  inlineFormatType: null,
  inlineFormatOptions: null
}

const mutations = make.mutations(state)

const MODEL_TIMEOUT = 5 * 60 * 1000 // 5m

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    invalidate({ commit, state }) {
      commit('cache', { instance: null, database: null, populated: +new Date() })
    },

    async populate(context, { force }) {
      const { commit, dispatch, state } = context
      commit('global/loading', true, { root: true })
      try {
        const { settings: { instance, database }, cache } = state
        if (!instance) {
          throw new Error('Instance is required to populate model')
        }

        // Do we need to be repopulated?
        const cacheAge = +new Date() - cache.populated
        if (force || (cacheAge > MODEL_TIMEOUT || (cache.instance !== instance) || (cache.database !== database))) {
          // Get instance object.  TODO: should change instance to id here.
          const instanceObject = Instance.query().where('id', instance).first()
          if (!instanceObject) {
            throw new Error(`Could not locate instance by address: ${instance}`)
          }

          // Populate database model.
          if (force) {
            await databaseObjectService.evict(instanceObject.id)
          }
          await databaseObjectService.populate(instanceObject.id, database || 'yellowbrick', { full: true })

          // Commit the cache stamp
          commit('cache', { instance, database, populated: +new Date() })
        }
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('global/loading', false, { root: true })
      }
    },

    async createStorage({ commit, state }, { storage }) {
      // TODO:
    },

    async createLocation({ commit, state }, { location }) {
      // TODO:
    },

    async createFormat({ commit, state }, { format }) {
      // TODO:
    },

    async removeStorage(context, { storage }) {
      // TODO:
    },

    async removeLocation(context, { location }) {
      // TODO:
    },

    async removeFormat(context, { format }) {
      // TODO:
    }
  }
}
