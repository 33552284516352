import { make } from '../pathify'

const state = {
  settings: {
    zoom: undefined,
    range: 300,
    summarize: 60
  }
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state)
  }
}
