import InstanceModel from './InstanceModel'
import Instance from '@/models/Instance'

export default class Cluster extends InstanceModel {
  static entity = 'cluster'

  static primaryKey = ['instance_id', 'cluster_id']

  static fields () {
    return {
      ...super.fields(),
      cluster_id: this.string(null).nullable(),
      __type: this.attr('cluster'),
      cluster_name: this.string(null).nullable(),
      nodes: this.number(null).nullable(),
      auto_suspend: this.number(null).nullable(),
      auto_resume: this.boolean(null).nullable(),
      default_wlm_profile_name: this.string(null).nullable(),
      error_message: this.string(null).nullable(),
      active_wlm_profile_name: this.string(null).nullable(),
      hardware_instance_type_id: this.string(null).nullable(),
      state: this.string(null).nullable(),
      is_default_cluster: this.boolean(null).nullable(),
      can_alter: this.boolean(null).nullable(),
      can_drop: this.boolean(null).nullable(),
      can_usage: this.boolean(null).nullable(),
      isClusterTreeOpen: this.boolean(false),
      hardwareInstanceType: this.attr(null).nullable()
    }
  }

  get id() {
    return this.cluster_id
  }

  get name() {
    return this.cluster_name
  }

  set name(n) {
    // no-op to avoid TypeError
  }
}
