<template>
  <div class="mx-8 -mt-4 mb-4 relative">
    <div class="pt-4 flex flex-row flex-nowrap items-center space-x-2">
      <yb-icon class="w-12 mr-2 flex-shrink-0" :icon="iconName" :class="iconClasses" />
      <div>
        <div class="font-semibold text-lg">
          {{ messageDisplay }}
        </div>
        <div class="py-2 font-light text-base">
          {{ errorDisplay }}
        </div>
      </div>
    </div>
    <yb-button
      v-tooltip="!isResponseError ? '' : 'Copy full error details to clipboard'"
      v-yb-clipboard:function="errorDetail"
      class="font-semibold pt-8"
      :class="isResponseError ? 'absolute -right-10 -top-8' : 'yb-link'"
      :label="isResponseError ? '' : 'Copy full error details to clipboard'"
      icon="clipboard"
      clipboard-label="(Error Details)"
    />
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import errorReporter from './errorReporter'
import logRocketService from '@/services/logrocket'

const props = defineProps({
  error: {
    type: Error,
    required: true
  },
  message: {
    type: String,
    required: false,
    default: 'An unexpected error occurred.'
  },
  type: {
    type: String,
    required: false,
    default: 'error'
  }
})

const emit = defineEmits(['title', 'nobuttons'])

const messageDisplay = computed(() => {
  let result = props.message || 'An unexpected error occurred.'
  const errorIndex = result.indexOf(String(props.error))
  if (errorIndex > 0) {
    result = result.substring(0, errorIndex) + result.substring(errorIndex + String(props.error).length)
  }
  result = result.trim()
  if (!result.endsWith('.')) {
    result += '.'
  }
  return result
})

const errorDisplay = computed(() => {
  let result = props.error || ''
  const errorRE = /^(TypeError|ApolloError|ResponseError): /
  if (String(result).match(errorRE)) {
    result = String(result).replace(errorRE, '')
  }
  return result
})

const isResponseError = computed(() => {
  const { error } = props

  // Is this an application error?
  if (error?.applicationError) {
    return true
  }

  // Is this a SQLState we know about, and can soften the display a bit for?
  const SQLState = error?.response?.sqlState || error?.response?.SQLState || error?.response?.error?.SQLState
  return String(SQLState).match(/^(42|5)/)
})

const iconClasses = computed(() => {
  const result = []
  if (props.type === 'warning' || isResponseError.value) {
    result.push('text-yb-pumpkin')
  } else {
    result.push('text-yb-lava')
  }
  return result
})

const iconName = computed(() => {
  return isResponseError.value ? 'warning' : 'error'
})

const errorDetail = () => errorReporter(props.error, messageDisplay.value)

onMounted(function() {
  const { error, type, message } = props
  console.error(`${type}: ${message}`, error)
  logRocketService.track('error.response', { message: messageDisplay.value, error: error.message || error.error })

  if (isResponseError.value) {
    emit('title', 'Notice')
    emit('nobuttons')
  }
})

</script>
