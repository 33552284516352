import { Model } from '@vuex-orm/core'
import Database from '@/models/Database'
import Cluster from '@/models/Cluster'
import WLMProfile from '@/models/WLMProfile'

const ONLINE_STATES = new Set(['HEALTHY', 'RUNNING'])
const CHANGING_STATES = new Set(['CHANGING', 'CREATING', 'DELETING'])

export default class Instance extends Model {
  static entity = 'instance'

  static fields () {
    return {
      id: this.attr(null),
      __type: this.attr('instance'),
      __typename: this.attr(''),
      instanceType: this.attr(''),
      buildDate: this.attr(''),
      startedAt: this.attr(''),
      consumerAddress: this.string(null).nullable(),
      databaseCount: this.number(null).nullable(),
      density: this.string(''),
      description: this.string('').nullable(),
      generation: this.string('').nullable(),
      name: this.string(null).nullable(),
      namespaceName: this.string(null).nullable(),
      nodes: this.number(null).nullable(),
      sharedServicesType: this.string('').nullable(),
      providerAddress: this.string('').nullable(),
      reservationStatus: this.attr(''),
      revision: this.string('').nullable(),
      site: this.attr(''),
      softwareVersion: this.string(null).nullable(),
      spec: this.attr(''),
      status: this.attr(null).nullable(),
      storageScratch: this.number(null).nullable(),
      storageTotal: this.number(null).nullable(),
      storageUsed: this.number(null).nullable(),
      databases: this.hasMany(Database, 'instance_id'),
      clusters: this.hasMany(Cluster, 'instance_id'),
      wlmProfiles: this.hasMany(WLMProfile, 'instance_id'),
      isInstanceTreeOpen: this.boolean(false),
      isInstanceManagementTreeOpen: this.boolean(false),
      isInstanceDatabasesTreeOpen: this.boolean(false),
      isInstanceClustersTreeOpen: this.boolean(false),
      isInstanceWLMTreeOpen: this.boolean(false),
      isInstanceActivityTreeOpen: this.boolean(false),
      isInstanceWLMProfilesTreeOpen: this.boolean(false),
      isInstancePopulatingWLM: this.boolean(false),
      jobStatus: this.attr(null).nullable(),
      allowlistIpCidrs: this.attr(null).nullable(),
    }
  }

  get online() {
    return !this.jobStatus && ONLINE_STATES.has(this.status?.type)
  }

  get changing() {
    return !!this.jobStatus || CHANGING_STATES.has(this.status?.type)
  }

  get activeStatus() {
    return this.jobStatus || this.status
  }
}
