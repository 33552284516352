import { mbeans } from './constants'
import jolokiaService from './jolokiaService'
import databaseObjectService from './databaseObjectService'

// TODO: add comments once we finally iron out this interface
class ExternalService {
  // get section - use query service to retrieve rows from sys.external_* tables in PG

  async getExternalStorage(database, options = {}, where = {}, limit = undefined, offset = undefined) {
    const result = await jolokiaService.execute(mbeans.externalStorage, 'retrieve', [{ options, database, where, limit, offset }])
    return result.rows || []
  }

  async getExternalLocation(database, options = {}, where = {}, limit = undefined, offset = undefined) {
    const result = await jolokiaService.execute(mbeans.externalLocation, 'retrieve', [{ options, database, where, limit, offset }])
    return result.rows || []
  }

  async getExternalFormat(database, options = {}, where = {}, limit = undefined, offset = undefined) {
    const result = await jolokiaService.execute(mbeans.externalFormat, 'retrieve', [{ options, database, where, limit, offset }])
    return result.rows || []
  }

  async getExternalTable(database, options = {}, where = {}, limit = undefined, offset = undefined) {
    const result = await jolokiaService.execute(mbeans.externalTable, 'retrieve', [{ options, database, where, limit, offset }])
    return result.rows || []
  }

  // create section - use SMC DataMovementService to drop external storage, format, location, and table

  /**
   * Creates an external storage.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param orReplace - OPTIONAL orReplace is a boolean, if true it adds OR REPLACE to SQL command, if false it omits it from SQL command
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external storage.
   * @param storageType - storageType is the type of storage for this external storage, ex. 'S3'
   * @param endpoint - endpoint is the URL to where the external storage exists, ex. 's3://bucket_name/'
   * @param region - region is where the external storage resides, ex. 'us-west-1'
   * @param identity - identity is the identity to use to access this external storage, ex. 'AKIAJA5MV57W7NXMQOEA'
   * @param credential - credential is the credentials to use to access this external storage, ex. 'FZuRivow2frcwzAEg8/RqDpZb1pNLcBR2Zfnd4cp'
   * @param commandOptions - OPTIONAL commandOptions are a list of name / value pairs and are used if additional configuration is
   *        required to access this external storage, ex. exclude certain files from user purview ["--exclude": "PROD"]
   * @returns {Promise<*>}
   */
  async createExternalStorage(database, options, orReplace, ifNotExists, name, storageType, endpoint, region, identity, credential, commandOptions) {
    const result = await jolokiaService.execute(mbeans.externalService, 'createExternalStorage', [database, options, orReplace, ifNotExists, name, storageType, endpoint, region, identity, credential, commandOptions])
    return result
  }

  /**
   * Creates an external format.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param orReplace - OPTIONAL orReplace is a boolean, if true it adds OR REPLACE to SQL command, if false it omits it from SQL command
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external format.
   * @param fileType - fileType is the type of format, ex. 'csv'
   * @param commandOptions - OPTIONAL commandOptions are a list of name / value pairs and are used for additional configuration,
   *        ex. [{"nullmarker": "<NULL>"}, {"emptymarker": "<EMPTY>"}]
   * @returns {Promise<*>}
   */
  async createExternalFormat(database, options, orReplace, ifNotExists, name, fileType, commandOptions) {
    const result = await jolokiaService.execute(mbeans.externalService, 'createExternalFormat', [database, options, orReplace, ifNotExists, name, fileType, commandOptions])
    return result
  }

  /**
   * Creates and external location.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param orReplace - OPTIONAL orReplace is a boolean, if true it adds OR REPLACE to SQL command, if false it omits it from SQL command
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external location.
   * @param locationPath - locationPath is the object store name/path, ex. S3 bucket name 'ybcs-unit-test-data'
   * @param storageName - storageName is the external storage name associated to this location
   * @param formatName - formatName is the external format name associated to this location
   * @param allowedPrefixes - OPTIONAL list of allowed file prefixes, ex. ['DEV', 'TEST']
   * @param disallowedPrefixes - OPTIONAL list of disallowed file prefixes, ex. ['PROD', 'GA']
   * @param readOnly - OPTIONAL readOnly is a boolean, if true it sets location to READ_ONLY, if false it omits
   * @param writeOnly - OPTIONAL writeOnly is a boolean, if true it sets location to WRITE_ONLY, if false it omits
   * @returns {Promise<*>}
   */
  async createExternalLocation(database, options, orReplace, ifNotExists, name, locationPath, storageName, formatName, allowedPrefixes, disallowedPrefixes, readOnly, writeOnly) {
    const result = await jolokiaService.execute(mbeans.externalService, 'createExternalLocation', [database, options, orReplace, ifNotExists, name, locationPath, storageName, formatName, allowedPrefixes, disallowedPrefixes, readOnly, writeOnly])
    return result
  }

  /**
   * Creates an external table that maps to one or more files from the associated external table.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param orReplace - OPTIONAL orReplace is a boolean, if true it adds OR REPLACE to SQL command, if false it omits it from SQL command
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external table.
   * @param columnSpecs - OPTIONAL columnSpecs is a list of key / value pairs listing each column name and column type,
   *        ex. [{"column1": "varchar(255)"}, {"column2": "varchar(255)"}, {"column3": "varchar(255)"}]
   * @param fileGlobs - fileGlobs is a list of file names or file name prefixes to assocate to the external table,
   *        ex. ['my-file.csv', 'test']
   *        would associate file 'my-file.csv' and any file starting with 'test'
   * @param locationName - locationName is the name of the location associated to this external table
   * @param formatName - formatName is the external format name associated to this table
   * @param formatType - OPTIONAL formatType is not used if  formatName is populated, otherwise it is required, ex. 'csv'
   * @param formatTypeOptions - OPTIONAL formatTypeOptions is not used if formatName is populated, otherwise it is a list of one or more format options,
   *        ex. [{"nullmarker": "<NULL>"}, {"emptymarker": "<EMPTY>"}]
   * @param commandOptions - OPTIONAL commandOptions are a list of name / value pairs and are used if additional configuration is
   *        required to access this external storage, ex. exclude certain files from user purview ["--exclude": "PROD"]
   * @returns {Promise<*>}
   */
  async createExternalTable(database, options, orReplace, ifNotExists, name, columnSpecs, fileGlobs, locationName, formatName, formatType, formatTypeOptions, commandOptions) {
    const result = await jolokiaService.execute(mbeans.externalService, 'createExternalTable', [database, options, orReplace, ifNotExists, name, columnSpecs, fileGlobs, locationName, formatName, formatType, formatTypeOptions, commandOptions])
    return result
  }

  // drop section - use SMC DataMovementService to create external storage, format, location, and table

  /**
   * Drops an external storage.
   *
   * @param database - database associated to this external storage.
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifExists - ifExists is a boolean, if true it will only execute DROP if the storage exists, otherwise it executes
   *        command without verifying it exists
   * @param name - name of external storage
   * @param cascade - cascade is a boolean, if true it drops all associated external formats, locations, and tables, if false
   *        it will only attempt to drop only the external storage but if there are any associated formats, locations or
   *        tables it will throw an exception
   * @returns {Promise<*>}
   */
  async dropExternalStorage(database, options, ifExists, name, cascade) {
    const result = await jolokiaService.execute(mbeans.externalService, 'dropExternalStorage', [database, options, ifExists, name, cascade])
    return result
  }

  /**
   * Drops an external format.
   *
   * @param database - database associated to this external format.
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifExists - ifExists is a boolean, if true it will only execute DROP if the format exists, otherwise it executes
   *        command without verifying it exists
   * @param name - name of external format
   * @param cascade - cascade is a boolean, if true it drops all associated external locations, and tables, if false
   *        it will only attempt to drop only the external format but if there are any associated locations or
   *        tables it will throw an exception
   * @returns {Promise<*>}
   */
  async dropExternalFormat(database, options, ifExists, name, cascade) {
    const result = await jolokiaService.execute(mbeans.externalService, 'dropExternalFormat', [database, options, ifExists, name, cascade])
    return result
  }

  /**
   * Drops an external location.
   *
   * @param database - database associated to this external location.
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifExists - ifExists is a boolean, if true it will only execute DROP if the location exists, otherwise it executes
   *        command without verifying it exists
   * @param name - name of external location
   * @param cascade - cascade is a boolean, if true it drops all associated external tables, if false it will only attempt
   *        to drop only the external location but if there are any associated tables it will throw an exception
   * @returns {Promise<*>}
   */
  async dropExternalLocation(database, options, ifExists, name, cascade) {
    const result = await jolokiaService.execute(mbeans.externalService, 'dropExternalLocation', [database, options, ifExists, name, cascade])
    return result
  }

  /**
   * Drops an external table.
   *
   * @param database - database associated to this external table.
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifExists - ifExists is a boolean, if true it will only execute DROP if the table exists, otherwise it executes
   *        command without verifying it exists
   * @param name - name of external table
   * @returns {Promise<*>}
   */
  async dropExternalTable(database, options, ifExists, name) {
    const result = await jolokiaService.execute(mbeans.externalService, 'dropExternalTable', [database, options, ifExists, name])
    return result
  }

  // alter section

  /**
   * Alters an external storage.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external storage.
   * @param storageType - storageType is the type of storage for this external storage, ex. 'S3'
   * @param endpoint - endpoint is the URL to where the external storage exists, ex. 's3://bucket_name/'
   * @param region - region is where the external storage resides, ex. 'us-west-1'
   * @param identity - identity is the identity to use to access this external storage, ex. 'AKIAJA5MV57W7NXMQOEA'
   * @param credential - credential is the credentials to use to access this external storage, ex. 'FZuRivow2frcwzAEg8/RqDpZb1pNLcBR2Zfnd4cp'
   * @param commandOptions - OPTIONAL commandOptions are a list of name / value pairs and are used if additional configuration is
   *        required to access this external storage, ex. exclude certain files from user purview ["--exclude": "PROD"]
   * @returns {Promise<*>}
   */
  async alterExternalStorage(database, options, ifNotExists, name, storageType, endpoint, region, identity, credential, commandOptions) {
    const result = await jolokiaService.execute(mbeans.externalService, 'alterExternalStorage', [database, options, ifNotExists, name, storageType, endpoint, region, identity, credential, commandOptions])
    return result
  }

  /**
   * Alters an external format.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external format.
   * @param fileType - fileType is the type of format, ex. 'csv'
   * @param commandOptions - OPTIONAL commandOptions are a list of name / value pairs and are used for additional configuration,
   *        ex. [{"nullmarker": "<NULL>"}, {"emptymarker": "<EMPTY>"}]
   * @returns {Promise<*>}
   */
  async alterExternalFormat(database, options, ifNotExists, name, fileType, commandOptions) {
    const result = await jolokiaService.execute(mbeans.externalService, 'alterExternalFormat', [database, options, ifNotExists, name, fileType, commandOptions])
    return result
  }

  /**
   * Alters and external location.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external location.
   * @param locationPath - locationPath is the object store name/path, ex. S3 bucket name 'ybcs-unit-test-data'
   * @param storageName - storageName is the external storage name associated to this location
   * @param formatName - formatName is the external format name associated to this location
   * @param allowedPrefixes - OPTIONAL list of allowed file prefixes, ex. ['DEV', 'TEST']
   * @param disallowedPrefixes - OPTIONAL list of disallowed file prefixes, ex. ['PROD', 'GA']
   * @param readOnly - OPTIONAL readOnly is a boolean, if true it sets location to READ_ONLY, if false it omits
   * @param writeOnly - OPTIONAL writeOnly is a boolean, if true it sets location to WRITE_ONLY, if false it omits
   * @returns {Promise<*>}
   */
  async alterExternalLocation(database, options, ifNotExists, name, locationPath, storageName, formatName, allowedPrefixes, disallowedPrefixes, readOnly, writeOnly) {
    const result = await jolokiaService.execute(mbeans.externalService, 'alterExternalLocation', [database, options, ifNotExists, name, locationPath, storageName, formatName, allowedPrefixes, disallowedPrefixes, readOnly, writeOnly])
    return result
  }

  /**
   * Alters an external table that maps to one or more files from the associated external table.
   *
   * @param database - database is name of database
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param ifNotExists - OPTIONAL ifNotExists is a boolean, if true it adds IF NOT EXISTS to SQL command, if false it omits it from SQL command
   * @param name - name is the name for this external table.
   * @param columnSpecs - OPTIONAL columnSpecs is a list of key / value pairs listing each column name and column type,
   *        ex. [{"column1": "varchar(255)"}, {"column2": "varchar(255)"}, {"column3": "varchar(255)"}]
   * @param fileGlobs - fileGlobs is a list of file names or file name prefixes to assocate to the external table,
   *        ex. ['my-file.csv', 'test']
   *        would associate file 'my-file.csv' and any file starting with 'test'
   * @param locationName - locationName is the name of the location associated to this external table
   * @param formatName - formatName is the external format name associated to this table
   * @param formatType - OPTIONAL formatType is not used if  formatName is populated, otherwise it is required, ex. 'csv'
   * @param formatTypeOptions - OPTIONAL formatTypeOptions is not used if formatName is populated, otherwise it is a list of one or more format options,
   *        ex. [{"nullmarker": "<NULL>"}, {"emptymarker": "<EMPTY>"}]
   * @param commandOptions - OPTIONAL commandOptions are a list of name / value pairs and are used if additional configuration is
   *        required to access this external storage, ex. exclude certain files from user purview ["--exclude": "PROD"]
   * @returns {Promise<*>}
   */
  async alterExternalTable(database, options, ifNotExists, name, columnSpecs, fileGlobs, locationName, formatName, formatType, formatTypeOptions, commandOptions) {
    const result = await jolokiaService.execute(mbeans.externalService, 'alterExternalTable', [database, options, ifNotExists, name, columnSpecs, fileGlobs, locationName, formatName, formatType, formatTypeOptions, commandOptions])
    return result
  }

  // load & unload section

  /**
   * Loads a table.
   *
   * @param database - database associated to this external table.
   * @param options - OPTIONAL options are a map of key / value entries, example for controlling GUC settings on the back-end:
   *        ex. set tags and hints {"GUC:ybd_query_tags": "testtag", "GUC:ybd_hint": "injectLatencySubmit=5000"}
   * @param tableName - tableName is the name of the table.
   * @param colSpecs - OPTIONAL columnSpecs is a list of key / value pairs listing each column name and column type,
   *        ex. [{"column1": "varchar(255)"}, {"column2": "varchar(255)"}, {"column3": "varchar(255)"}]
   * @param fileGlob - fileGlob is a list of file names or file name prefixes to assocate to the external table,
   *        ex. ['my-file.csv', 'test']
   *        would associate file 'my-file.csv' and any file starting with 'test'
   * @param locationName - locationName is the name of the location associated to this external table
   * @param formatName - formatName is the external format name associated to this table
   * @param formatType - OPTIONAL formatType is not used if  formatName is populated, otherwise it is required, ex. 'csv'
   * @param formatTypeOptions - OPTIONAL formatTypeOptions is not used if formatName is populated, otherwise it is a list of one or more format options,
   *        ex. [{"nullmarker": "<NULL>"}, {"emptymarker": "<EMPTY>"}]
   * @param commandOptions - OPTIONAL commandOptions are a list of name / value pairs and are used if additional configuration is
   *        required to access this external storage, ex. exclude certain files from user purview ["--exclude": "PROD"]
   * @returns {Promise<*>}
   */
  async load({ instance, database, options, tableName, colSpecs, fileGlob, locationName, formatName, formatType, formatTypeOptions, commandOptions }) {
    // First we need to ask about the sql.  We may be done if that's all caller wants.
    jolokiaService.flush()
    const loadOperation = jolokiaService.execute(mbeans.externalService, 'load', [database, { ...options, preflight: true }, tableName, colSpecs, fileGlob, locationName, formatName, formatType, formatTypeOptions, commandOptions])
    jolokiaService.flush()
    let result = await loadOperation

    // If we were not preflighting, then we need to do the load via async sql handling.
    if (!options.preflight) {
      // API has GUC: options handling.
      let sql = ''
      Object.entries(options).forEach(([key, value]) => {
        if (key.match(/^GUC:/)) {
          key = key.substring(4)
          sql += `SET ${key} TO '${value}';\n`
        }
      })
      sql += result.results.sql

      // Fire the sql.
      const rowset = await databaseObjectService.sql(instance.id, database, sql, 30 * 1000)

      // Convert the rowset to the dm api result.
      const warnings = (rowset.warnings || []).join('\n')
      const col = rowset.columns && rowset.columns[0].name
      const output = rowset.rows && rowset.rows.map(row => row[col]).join('\n')
      result = {
        results: {
          sql_warning_message: warnings || null,
          sql_output: output,
          sql_warning_error_code: rowset.errorCode,
          sql_warning_sql_state: rowset.sqlState,
          yb_last_execid: rowset.execId
        }
      }
    }

    return result
  }

  // list / preview section (TBD)
  async list({ instance, database, options, locationName, prefix, suffix, limit, startWithKey }) {
    // eslint-disable-next-line no-constant-condition
    if (false) {
      const result = await jolokiaService.execute(mbeans.externalService, 'list', [database, options, locationName, prefix, suffix, limit, startWithKey])
      return result
    } else {
      // NB: the $ESC$ is using custom "dollar quoting", per: https://www.postgresql.org/docs/9.5/sql-syntax-lexical.html "4.1.2.4. Dollar-quoted String Constants"
      let sql = `LIST OBJECTS EXTERNAL LOCATION ${locationName}`
      if (prefix) {
        sql += ` PREFIX ($ESC$${prefix}$ESC$)`
      }
      if (suffix) {
        sql += ` SUFFIX ($ESC$${suffix}$ESC$)`
      }
      if (limit) {
        sql += ` LIMIT ${limit}`
      }
      if (startWithKey) {
        sql += ` START AFTER $ESC$${startWithKey}$ESC$`
      }

      const result = await databaseObjectService.sql(instance.id, database, sql, 30 * 1000)
      return result.rows
    }
  }
}

// Export the singleton.
export default new ExternalService()
