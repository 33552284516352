import { useNuxtApp } from '#app'
import { getInstance } from '@/auth'

const useLogout = () => {
  const logout = () => {
    const authService = getInstance()
    if (authService) {
      authService.logout()
    }
    useNuxtApp().$router.push('/login')
  }

  return { logout }
}

export default useLogout
