import {
  axisBottom,
  axisLeft,
  axisRight,
  axisTop
} from 'd3-axis'

import {
  nest
} from 'd3-collection'

import {
  easeLinear
} from 'd3-ease'

import {
  interpolate
} from 'd3-interpolate'

import {
  scaleTime
} from 'd3-scale'

import {
  pointer,
  select,
  selectAll
} from 'd3-selection'

import {
  curveBundle,
  line
} from 'd3-shape'

import {
  timeDay,
  timeHour,
  timeMillisecond,
  timeMinute,
  timeMonth,
  timeSecond,
  timeWeek,
  timeYear
} from 'd3-time'

import {
  timeFormat
} from 'd3-time-format'

import {
  transition
} from 'd3-transition'

import {
  zoom,
  zoomIdentity,
  zoomTransform
} from 'd3-zoom'

const d3 = {
  // d3-axis
  axisBottom,
  axisLeft,
  axisRight,
  axisTop,

  // d3-collection
  nest,

  // d3-ease
  easeLinear,

  // d3-interpolate
  interpolate,

  // d3-scale
  scaleTime,

  // d3-selection
  pointer,
  select,
  selectAll,

  // d3-shape
  curveBundle,
  line,

  // d3-time-format
  timeFormat,

  // d3-time
  timeDay,
  timeHour,
  timeMillisecond,
  timeMinute,
  timeMonth,
  timeSecond,
  timeWeek,
  timeYear,

  // d3-transition
  transition,

  // d3-zoom
  zoom,
  zoomIdentity,
  zoomTransform
}

export default d3
