import sprintf from 'sprintf'

import aggregateStats from './statAggregator'
import $toggles from './toggles'
import { functions, Logger } from '@/util'
import { app, $gettext } from '@/services'

const log = Logger.get('yb.query-plan')

// Icon lookup by type
const typeIcons = {
  aggHash: 'aggHash',
  agg: 'aggHash',
  aggSimple: 'aggSimple',
  append: 'append',
  appendScan: 'tableScan',
  backup: 'backup',
  dataInput: 'dataInput',
  dataOutput: 'dataOutput',
  distribute: 'distribution',
  distributeMap: 'distributeMap',
  distributeSortMerge: 'distributeSortMerge',
  distributeSort: 'distributeSortMerge',
  distribution: 'distribute',
  expression: 'expression',
  filterExpression: 'filterExpression',
  pGTableScan: 'fromPostgres',
  except: 'except',
  exceptAll: 'except',
  intersect: 'intersect',
  intersectAll: 'intersect',
  hashBuild: 'hashBuild',
  build: 'hashBuild',
  hashJoin: 'hashJoin',
  hashEquiJoin: 'hashJoin',
  join: 'hashJoin',
  limit: 'limit',
  loopJoin: 'loopJoin',
  maintenance: 'maintenance',
  partition: 'partition',
  modifyTable: 'modifyTable',
  repeat: 'repeat',
  restore: 'restore',
  singleThread: 'singleThread',
  sequence: 'singleThread',
  sort: 'sort',
  sortMerge: 'sortMerge',
  sortLimit: 'limit',
  subqueryScan: 'subqueryScan',
  tableScan: 'fromColumnStore',
  tableScanComposite: 'tableScan',
  tempTableScan: 'tableScan',
  tempTableWrite: 'tempTableWrite',
  toPostgres: 'toPostgres',
  transposeColumnToRow: 'transposeColumnToRow',
  transposeRowToColumn: 'transposeRowToColumn',
  virtualTableScan: 'virtualTableScan',
  windowAgg: 'windowAgg',
  writeShard: 'writeShard'
}

const typeNameSynonyms = {
  'AGGREGATE SIMPLE': 'AggSimpleNode',
  APPEND: 'AppendNode',
  'APPEND SCAN': 'AppendScanNode',
  BUILD: 'BuildNode',
  'BUILD HASH': 'BuildNode',
  'BUILD SORT': 'BuildNode',
  'BUILD SPILL HASH': 'BuildNode',
  CTAS: 'ModifyTableNode',
  'DATA INPUT': 'DataInputNode',
  'DATA OUTPUT': 'DataOutputNode',
  'DEBUG DUMP': 'DebugDumpNode',
  'DEBUG REFLOW': 'DebugPauseMonkeyNode',
  'DELETE FROM': 'ModifyTableNode',
  'DISTRIBUTE HASH': 'DistributeMapNode',
  'DISTRIBUTE MERGE': 'DistributeSortMergeNode',
  'DISTRIBUTE ON': 'DistributeMapNode',
  'DISTRIBUTE RANDOM': 'DistributeNode',
  'DISTRIBUTE REPLICATE': 'DistributeNode',
  'DISTRIBUTE SINGLE': 'DistributeNode',
  'DISTRIBUTE SORT': 'DistributeSortMergeNode',
  'DISTRIBUTE WORKER': 'DistributeMapNode',
  EXCEPT: 'ExceptNode',
  'EXCEPT ALL': 'ExceptAllNode',
  EXPRESSION: 'ExpressionNode',
  FILTER: 'FilterExpressionNode',
  'GROUP BY': 'AggHashNode',
  'GROUP BY PARTIAL': 'AggHashNode',
  'GROUP BY SPILL': 'AggHashNode',
  'HASH AGGREGATE SIMPLE': 'AggSimpleNode',
  'INSERT INTO': 'ModifyTableNode',
  INTERSECT: 'IntersectNode',
  'INTERSECT ALL': 'IntersectAllNode',
  LIMIT: 'LimitNode',
  MAINTENANCE: 'MaintenanceNode',
  PARTITION: 'PartitionNode',
  'PARTITION EXPRESSION': 'ExpressionNode',
  REPEAT: 'RepeatNode', // TODO: no icon
  SCAN: 'TableScanNode',
  'SCAN EMPTY': 'TableScanNode',
  'SCAN ROW STORE': 'PGTableScanNode',
  'SCAN TEMP': 'TableScanNode',
  'SCAN VIRTUAL': 'VirtualTableScanNode',
  SELECT: 'ToPostgresNode',
  SEQUENCE: 'SequenceNode',
  'SINGLE THREAD': 'SingleThreadNode',
  SORT: 'SortNode',
  'SORT LIMIT': 'LimitNode',
  TRANSPOSE: 'TransposeColumnToRowNode',
  UPDATE: 'ModifyTableNode',
  'WINDOW AGGREGATE': 'WindowAggNode',
  'WRITE HASH': 'BuildNode',
  'WRITE ROW STORE': 'ToPostgresNode',
  'WRITE TEMP': 'TempTableWriteNode'
}

const joinTypes = ['INNER', 'LEFT OUTER', 'LEFT SEMI', 'RIGHT SEMI', 'FULL OUTER', 'SEMI', 'ANTI', 'RIGHT OUTER', 'CROSS']

const joinNodes = ['JOIN', 'LOOP JOIN', 'SPILL HASH JOIN', 'HASH JOIN']

joinTypes.forEach(jt => joinNodes.forEach((jn) => { typeNameSynonyms[`${jt} ${jn}`] = 'JoinNode' }))

function sumArray(sum, d) {
  return sum + d
}

const KEPT_STATS = {
  io_read_bytes: 1,
  io_write_bytes: 1,
  memory_planned_bytes: 1,
  memory_actual_bytes: 1,
  io_spill_read_bytes: 1,
  io_spill_write_bytes: 1,
  io_network_bytes: 1,
  rows_planned: 1,
  rows_actual: 1,
  skew: 1,
  rows_from_column_store: 1,
  rows_from_row_store: 1,
  shards_planned: 1,
  shards_scanned: 1,
  column_parts_planned: 1,
  column_parts_scanned: 1,
  row_groups_planned: 1,
  row_groups_considered: 1,
  row_groups_used: 1,
  read_efficiency: 1,
  iterations: 1,
  runtime_ms: 1
}
const DISCARDED_STATS = {
  query_id: 1,
  node_id: 1,
  submit_time: 1,
  worker_elapsed_ms: 1,
  detail: 1
}

export default (function() {
  function finalizeNode(node) {
    // If there is stat "detail", hoist that info up.
    if (node.stats.detail) {
      const nvRE = /(\w*?)=([^, ]*)/g
      let result
      // eslint-disable-next-line no-cond-assign
      while (result = nvRE.exec(node.stats.detail)) {
        node.stats[result[1]] = node.stats[result[1]] || result[2]
      }
    }

    // Make partition node iterations "iterations".
    if (node.detailStats['Partition node iterations']) {
      node.stats.iterations = node.detailStats['Partition node iterations'].max || node.detailStats['Partition node iterations'].avg || node.detailStats['Partition node iterations'].count
    }

    // For the build nodes, hoist the rows from first child.
    if (node.nodeTypeShort === 'build') {
      if (node.children && node.children[0] && node.children[0].stats.rows) {
        node.stats.rows = node.children[0].stats.rows
      }
    }

    // Create an ordered list by the translation value of each stat label
    node.orderedStatNames = Object.keys(node.stats)
      .sort(app.i18NCompare)
      .filter(stat => !stat.match(/ticks|_name/))
    node.orderedDetailStatNames = Object.keys(node.detailStats).sort(app.i18NCompare)

    // Determine availability.
    node.haveStats = functions.reduce(
      node.stats,
      function(result, value) {
        return result || typeof value === 'number' || (value && value.sum)
      },
      0
    )
    node.haveDetailStats = Object.keys(node.detailStats).length > 0

    // If not in debug, eject most stats.  We may have a flag to discard the stat always as well.
    node.orderedStatNames = node.orderedStatNames.filter(p => ($toggles.queryDevStats || KEPT_STATS[p]) && !DISCARDED_STATS[p])
  }

  function buildTree(node, stats, level, detailed, rateSetting) {
    // Only do the following once on a node.
    if (!node.__processed__) {
      node.__processed__ = true

      // Synonyms.
      if (!node.className && node.nodeName) {
        node.className = typeNameSynonyms[node.nodeName] || functions.capitalize(node.nodeName.toLowerCase())
      }

      // Synthetic substitutions.
      if (node.className === 'SetOpNode' && node.type) {
        node.className = node.type.substring(0, 1).toUpperCase() + node.type.substring(1) + 'Node'
      }

      // Massage data here.
      node.nodeTypeShort = node.className.substring(0, 1).toLowerCase() + node.className.substring(1)
      node.nodeTypeShort = node.nodeTypeShort.replace(/Node[2]?$/, '')
      if (node.className === 'JoinNode') {
        node.className = $gettext(functions.capitalize(node.nodeName.toLowerCase()))
      }
      let translatedNodeType = node.nodeName && $gettext(node.nodeName)
      if (typeof translatedNodeType === 'undefined' || (translatedNodeType && translatedNodeType === node.nodeName)) {
        translatedNodeType = $gettext(node.className)
      }
      if (node.nodeType !== translatedNodeType) {
        node.nodeType = translatedNodeType
      } else {
        node.nodeType = node.nodeTypeShort
      }
      node.icon = typeIcons[node.nodeTypeShort] || 'placeholder'
      node.cid = node.id
      node.label = node.nodeType
      node.children = node.children || []
      node.selected = false
      // eslint-disable-next-line no-constant-condition
      if (false && node.columnNames) {
        node.columnNames = functions.uniq(node.columnNames)
        if (!node.tableNames) {
          node.tableNames = node.columnNames.map((col) => {
            const cols = /(.*?)\.(.*)/.exec(col)
            return (cols && cols[1].trim()) || String(col).trim()
          })
        }
      }
      if (node.tableNames) {
        node.tableNames = functions.uniq(node.tableNames)
      }

      // Our node styler.
      node.setHighlightStyle = function() {
        const rate = {}
        if (!node.element) {
          return
        }
        if (rateSetting) {
          node.element.style('transition', sprintf('all linear %fs', parseFloat(rateSetting) / 1000))
        }

        let style
        if (node.hideArrow) {
          node.element.style('box-shadow', sprintf('inset 0px 0px %fpx %fpx rgba(64, 204, 204, 0.25), 0px 0px %fpx %fpx rgba(64, 204, 204, 0.25)', parseInt(node.statRatio * 20), parseInt(node.statRatio * 50), parseInt(node.statRatio * 50), parseInt(node.statRatio * 20)))
        } else {
          node.element.style('box-shadow', sprintf('0px 0px %fpx %fpx rgba(128, 128, 204, 0.5)', 10 + parseInt(node.statRatio * 40), parseInt(node.statRatio * 20)))
        }
      }

      // DBG only
      // TODO: do we want to remove later?
      node.dbg = () => {
        const result = functions.copy(node)
        delete result.children
        return JSON.stringify(result, null, 4)
      }
      node.getClass = () => {
        if (node.selected) {
          return 'qp-node-selected'
        } else {
          return 'todo'
        }
      }
    }

    // Manage stats for node.
    node.stats = node.stats || functions.copy(stats[node.id]) || {}
    if (!node.nodeTypeShort.match(/appendScan|tableScanComposite/)) {
      if (node.can_spill_read && node.stats.io_read_bytes && typeof node.stats.io_spill_read_bytes === 'undefined') {
        node.stats.io_spill_read_bytes = node.stats.io_read_bytes
        node.stats.io_read_bytes = 0
      }
      if (node.can_spill_write && node.stats.io_write_bytes && typeof node.stats.io_spill_write_bytes === 'undefined') {
        node.stats.io_spill_write_bytes = node.stats.io_write_bytes
        node.stats.io_write_bytes = 0
      }
    }

    // Sumarize rows and packets by worker.
    function summarize(byWorker) {
      const result = { max: 0, count: 0, sum: 0, min: 0, cpuCount: 0, workers: 0, collection: byWorker }
      let sumdone = false
      functions.forEach(byWorker, function(series, workerId) {
        result.cpuCount = Math.max(series.length, result.cpuCount)
        result.count += series.length
        result.workers++
        result.max = Math.max(result.max, functions.max(series))
        result.min = Math.max(result.min, functions.min(series))
        if (sumdone) { return }
        result.sum += Array.isArray(series) ? series.reduce(sumArray, 0) : series.sum
        sumdone = result.sum > 0 && !String(node.distType).match(/hash|rand|worker/i)
      })
      return result
    }
    if (node.stats.custom && node.stats.custom.rows) {
      node.rowSummary = summarize(node.stats.custom.rows)
      delete node.stats.custom.rows
    }
    if (node.stats.custom && node.stats.custom.packets) {
      node.packetSummary = summarize(node.stats.custom.packets)
      delete node.stats.custom.packets
    }

    // Promote node "custom" stats as a value on the stat.
    node.detailStats = node.detailStats || node.stats.custom || {}
    delete node.stats.custom

    // If an object exposed to angular directive contains a "nodeName" and "children" property, it stupidly thinks
    // it is a DOM object!!  So rename that property here.
    if (typeof node.nodeName !== 'undefined') {
      node._nodeName = node.nodeName
      delete node.nodeName
    }

    // Do the children.
    node.level = level
    node.children.forEach((c, index) => {
      c.parent = node.id
      c.index = index
      buildTree(c, stats, level + 1, detailed, rateSetting)
    })

    // For the TableScanNode, make read efficiency.
    if (node.nodeTypeShort === 'tableScan') {
      node.stats.read_efficiency = ''

      /**
       double shardsConsidered = getShardsConsidered();
       double shardsScanned = getShardsScanned();
       double colPartsConsidered = getColPartsConsidered();
       double colPartsScanned = getColPartsPlanned();
       if (shardsConsidered == 0 || colPartsConsidered == 0) {
            return 100.0;
        }
       return 100.0 * (1 - (shardsScanned / shardsConsidered) * (colPartsScanned / colPartsConsidered));
       */
      if (typeof node.detailStats['Shards considered'] !== 'undefined' && typeof node.detailStats['Shards scanned'] !== 'undefined' && typeof node.detailStats['Colparts considered'] !== 'undefined' && typeof node.detailStats['Colparts planned'] !== 'undefined' && node.detailStats['Shards considered'].count && node.detailStats['Shards scanned'].count && node.detailStats['Colparts considered'].count && node.detailStats['Colparts planned'].count) {
        const readEfficiency = 100.0 * (1 - (node.detailStats['Shards scanned'].count / node.detailStats['Shards considered'].count) * (node.detailStats['Colparts planned'].count / node.detailStats['Colparts considered'].count))
        if (isNaN(readEfficiency)) {
          node.stats.read_efficiency = 'n/a'
        } else {
          node.stats.read_efficiency = readEfficiency.toFixed(2) + ' %'
        }
        log.debug('Calculated read efficiency for node: ', node.id, readEfficiency)
      } else if (node.stats && node.stats.detail && node.stats.detail.match(/read_efficiency=/)) {
        node.stats.read_efficiency = node.stats.detail.replace(/.*read_efficiency=(.*?)%.*/, '$1') + '%'
        if (!node.stats.read_efficiency.match(/[^0-9.]+/)) {
          node.stats.read_efficiency += '%'
        }
      }
    }

    // Supply dummy table name for empty scans.
    if (node._nodeName === 'SCAN EMPTY') {
      node.explain = $gettext('(empty scan)')
    }

    // For the DistributeSortMerge, tweak the expressions
    if (node.nodeTypeShort === 'distributeSortMerge') {
      const expressions = [].concat(...node.expressions)
      node.expressions = expressions.filter(e => e.match(/type/))
      node.hash = expressions.filter(e => !e.match(/type/))
    }

    // For the AppendNode constellation, we need to terminate tree here, rollup children in view, pretend to be just "Table Scan"
    if (!detailed && node.nodeTypeShort === 'appendScan') {
      node.original = functions.copy(node)
      finalizeNode(node.original)
      node.nodeTypeShort = 'tableScanComposite'
      node.nodeType = $gettext('TableScanCompositeNode')
      node.label = node.nodeType
      node.className = 'TableScanCompositeNode'
      node.children = []

      // Hoist the table scan nodes up.
      let tableScanNode, pgTableScanNode, transposeNode, filterExpressionNode;
      (function hoist(children) {
        children.forEach((child) => {
          if (child.className === 'TableScanNode') {
            tableScanNode = child
          }
          if (child.className === 'PGTableScanNode') {
            pgTableScanNode = child
          }
          if (child.className === 'TransposeColumnToRowNode') {
            transposeNode = child
          }
          if (child.className === 'FilterExpressionNode') {
            filterExpressionNode = child
          }
          hoist(child.children)
        })
      })(node.original.children)

      // Save the append scan sums for rows/packets
      const rows = node.stats.rows
      const packets = node.stats.packets
      node.stats.rows = 0
      node.stats.packets = 0

      // Now gather up all the interesting metadata and stats from descendents.
      if (tableScanNode) {
        node.tableScanNode = tableScanNode
        node.explain = tableScanNode.explain
        node.columnNames = node.tableScanNode.columnNames
        node.constraints = tableScanNode.constraints
        node.distType = tableScanNode.distType
        node.tableOID = tableScanNode.tableOID
        for (const p in node.tableScanNode.stats) {
          if (!p.match(/^io_/)) {
            continue
          }
          const v = node.tableScanNode.stats[p]
          const pKey = p
          if (typeof v === 'number') {
            if (!node.stats[pKey]) {
              node.stats[pKey] = 0
            }
            node.stats[pKey] += v
          } else {
            node.stats[pKey] = node.tableScanNode.stats[pKey]
          }
        }
        node.stats.rows_from_column_store = node.tableScanNode.stats.rows_actual
        node.stats.packets_from_column_store = node.stats.packets
        const detailStat = (n, s) => (n.detailStats[s] && n.detailStats[s].count) || 0
        node.stats.shards_planned = detailStat(node.tableScanNode, 'Shards considered')
        node.stats.shards_scanned = detailStat(node.tableScanNode, 'Shards scanned')
        node.stats.column_parts_planned = detailStat(node.tableScanNode, 'Colparts considered')
        node.stats.column_parts_scanned = detailStat(node.tableScanNode, 'Colparts planned')
        node.stats.row_groups_planned = detailStat(node.tableScanNode, 'Row groups planned')
        node.stats.row_groups_considered = detailStat(node.tableScanNode, 'Row groups considered')
        node.stats.row_groups_used = detailStat(node.tableScanNode, 'Row groups used')
        node.stats.read_efficiency = node.tableScanNode.stats.read_efficiency
        for (const p in node.tableScanNode.detailStats) {
          if (!(p in node.detailStats)) {
            node.detailStats[p] = functions.copy(node.tableScanNode.detailStats[p])
          } else {
            for (const pp in node.tableScanNode.detailStats[p]) {
              if (!(pp in node.detailStats[p])) {
                node.detailStats[p][pp] = 0
              }
              node.detailStats[p][pp] += node.tableScanNode.detailStats[p][pp]
            }
          }
        }
      }
      if (pgTableScanNode) {
        node.pgTableScanNode = pgTableScanNode
        node.stats.rows_from_row_store = node.pgTableScanNode.stats.rows_actual
        for (const p in node.pgTableScanNode.stats) {
          if (!p.match(/^io_/)) {
            continue
          }
          const v = node.pgTableScanNode.stats[p]
          if (typeof v === 'number') {
            const pKey = p
            if (!node.stats[pKey]) {
              node.stats[pKey] = 0
            }
            node.stats[pKey] += v
          }
        }
        for (const p in node.pgTableScanNode.detailStats) {
          if (!(p in node.detailStats)) {
            node.detailStats[p] = functions.copy(node.pgTableScanNode.detailStats[p])
          } else {
            for (const pp in node.pgTableScanNode.detailStats[p]) {
              if (!(pp in node.detailStats[p])) {
                node.detailStats[p][pp] = 0
              }
              node.detailStats[p][pp] += node.pgTableScanNode.detailStats[p][pp]
            }
          }
        }
      }
      if (transposeNode) {
        node.bloom = transposeNode.bloom
        node.filterScalar = transposeNode.filterScalar
        node.filterVector = transposeNode.filterVector
      }
      if (filterExpressionNode) {
        node.expressions = [filterExpressionNode.explain]
      }

      // Restore/preserve the append scan node rows/packets.
      node.stats.rows = rows
      node.stats.packets = packets

      // See if our explain has scan constraints and constraints to remove.
      if (node.explain) {
        const SC_RE = /(?: AND )?scan_constraints: (.*)/
        const scanConstraints = node.explain.match(SC_RE)
        if (scanConstraints) {
          node.explain = node.explain.replace(SC_RE, '')

          if (!node.expressions) {
            node.expressions = []
          }
          node.expressions.push('PUSHDOWN ' + scanConstraints[1])
        }
        node.explain = node.explain.replace(/ \(.*/, '').replace(/ as .*/i, '')
        node.tableNames = [node.explain]
      }
    }
    finalizeNode(node)
  }

  function analyzeTree(tree, highlightStatExpression, rateSetting) {
    // Make expression to match.
    highlightStatExpression = new RegExp(highlightStatExpression || 'rows', 'g')

    // Determine the max rows and width produced.
    let maxStatValue = 0;
    (function findMax(node) {
      node.stats = node.stats || {}
      node.statValue = 0
      functions.forEach(node.stats, function(stat, id) {
        highlightStatExpression.lastIndex = 0
        if (highlightStatExpression.exec(id) && stat) {
          node.statValue += typeof stat === 'number' ? stat : stat.sum || 0
        }
      })
      maxStatValue = Math.max(maxStatValue, node.statValue)
      node.children.forEach(function(child) {
        findMax(child)
      })
    })(tree)

    // Compute each child ratio stat.
    log.debug(`Max stat value is ${maxStatValue}`);
    (function setPctRows(node) {
      node.statRatio = Math.max(0, node.statValue / maxStatValue)
      if (node.element) {
        const qpRatio = node.element.select('div.qp-stat-ratio')
        if (node.statRatio > 0.02) {
          node.setHighlightStyle()
          qpRatio.style('display', 'block')
          qpRatio.text((node.statRatio * 100).toFixed(0) + '%')
        } else {
          node.element.style('box-shadow', 'none')
          qpRatio.style('display', 'none')
          qpRatio.text('')
        }
      }
      node.children.forEach(function(child) {
        setPctRows(child)
      })
    })(tree)
  }

  return {
    createTree(plan, stats, detailed, rateSetting) {
      // Parse the json to tree, stats to array.
      const tree = typeof plan === 'string' ? JSON.parse(plan).root : plan
      if (!tree) {
        // TODO: would be nice to report an error here?

        return
      }
      if (stats) {
        if (typeof stats === 'string') {
          try {
            stats = JSON.parse(stats)
          } catch (e) {
            log.warn('Unexpected exception parsing query plan: ' + e)
            stats = {}
          }
        }
        false && log.debug('Have stats: ' + JSON.stringify(stats, null, 1))
      } else {
        log.debug('Reusing existing stats')
      }

      // If the stats is an array, we need to aggregate it.
      if (Array.isArray(stats)) {
        stats = aggregateStats(stats)
      }

      // Create new scope and apply tree.
      buildTree(tree, stats || {}, 0, detailed, rateSetting)

      return tree
    },

    analyzeTree
  }
})()
