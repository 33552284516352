import Dexie from 'dexie'

const db = new Dexie('YB')

db.version(1).stores({
  analyzedQueries: 'id,plan,analysis,timestamp,tree,query'
})
db.version(2).stores({
  analyzedQueries: 'id,plan,analysis,timestamp,tree,query',
  reports: 'name,definition'
})
db.version(3).stores({
  analyzedQueries: 'id,plan,timestamp,tree,query,frames',
  querySamples: '[id+frame],id,stat',
  reports: 'name,definition'
})

export default {
  open() {
    return new Promise((resolve, reject) => {
      if (db.isOpen()) {
        resolve(db)
      } else {
        db.open()
          .then((db) => {
            resolve(db)
          })
          .catch((e) => {
            reject(e)
          })
      }
    })
  }
}
