import SchemaModel from './SchemaModel'

export default class Table extends SchemaModel {
  static entity = 'table'

  static primaryKey = ['instance_id', 'table_id']

  static fields () {
    return {
      ...super.fields(),
      table_id: this.number(null).nullable(),
      __type: this.attr('table'),
      bytes_compressed: this.number(null).nullable(),
      bytes_uncompressed: this.number(null).nullable(),
      bytes_compressed_by_worker: this.attr(null),
      deviation: this.number(null).nullable(),
      distribution: this.string(null).nullable(),
      max_ratio: this.number(null).nullable(),
      name: this.string(null).nullable(),
      rows: this.number(null).nullable(),
      rows_by_worker: this.attr(null),
      worker_ratios: this.attr(null),
      workers: this.attr(null)
    }
  }

  get id() {
    return this.table_id
  }
}
