import { createStore, gatherModules } from './store'
import storeService from './storeService'

// Create the store.
export default function() {
  const store = createStore(
    gatherModules(import.meta.glob('./modules/*.js', { eager: true })),
    'YM.settings')
  storeService.set(store)
  return store
}
