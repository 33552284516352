import baseUrl from './remoteService'
import userService from './userService'
import { $gettext } from './gettext'

let _versionInfo

const versionRE = /([0-9]*)\.([0-9]*)\.([0-9]*)-([0-9]*)((?:\.)([a-f0-9]*))?/
const semverFields = ['major', 'minor', 'patch']
const semverAndBuildFields = [...semverFields, 'build']

const versionService = {
  checkVersion() {
    // Perform a version double-check.
    return fetch(baseUrl.url('version'))
      .then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            const serverVersion = response || {}
            if (_versionInfo && _versionInfo.buildNumber && serverVersion.buildNumber && serverVersion.buildNumber !== _versionInfo.buildNumber && serverVersion.projectVersion !== _versionInfo.projectVersion) {
              window.setTimeout(() => {
                window.alert($gettext('The version deployed to the Yellowbrick Database has changed.\n\nPress OK to reload.'))
                window.location.reload()
              }, 500)
            } else if (!_versionInfo) {
              _versionInfo = serverVersion
            }
          })
        }
      })
      .catch(() => null)
  },

  retrieveVersion() {
    return typeof _versionInfo === 'undefined'
      ? fetch(baseUrl.url('version'))
        .then(response => response.json())
        .then((response) => {
          _versionInfo = response
        })
        .catch(() => {})
      : Promise.resolve(_versionInfo)
  },

  // the selection of the clusters we can connect to.  This may return empty, in which case we return a []
  listClusters() {
    return fetch('/clusters')
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          return []
        }
      })
      .catch(() => [])
  },

  isHostTrusted(url) {
    // if the url doesn't start with https, then we can trust it, otherwise we have to test.
    if (String(url.match(/^https:/))) {
      return new Promise((resolve /* , reject */) => {
        // Create a download for a well-known image.   If we can download it via http, then the inbound url is not trusted, because we can reach it via http.
        const testImage = new Image()
        testImage.src = url.replace(/^https/, 'http').replace(':9443/', ':8182/') + 'app/img/empty.gif'
        // testImage.crossOrigin = 'Anonymous';
        testImage.onload = () => {
          resolve('untrusted')
          document.body.removeChild(testImage)
        }
        testImage.onerror = () => {
          resolve('unreachable')
          document.body.removeChild(testImage)
        }
        document.body.appendChild(testImage)
      })
    } else {
      return Promise.resolve('trusted')
    }
  },

  parseVersion(version) {
    const buildParts = versionRE.exec(version)
    if (!!buildParts && buildParts.length >= 5) {
      const [, major, minor, patch, build = '00000', , sha] = buildParts
      const semver = `${major}.${minor}.${patch}`
      return { version, major, minor, patch, sha, build, semver }
    }
  },

  sortSemverAndBuild(v1, v2) {
    for (const field of semverAndBuildFields) {
      const result = parseInt(v1[field]) - parseInt(v2[field])
      if (result !== 0) {
        return result
      }
    }

    // Otherwise, just use version
    return v1.version.localeCompare(v2.version)
  },

  sortSemver(v1, v2) {
    for (const field of semverFields) {
      const result = parseInt(v1[field]) - parseInt(v2[field])
      if (result !== 0) {
        return result
      }
    }

    // Otherwise, just use version
    return v1.version.localeCompare(v2.version)
  },

  findSemverUpgradeTargets(versionObject, versions) {
    return versions.filter(v => v.major === versionObject.major && v.minor === versionObject.minor)
  },

  sortVersion(opt1, opt2) {
    const v1 = opt1.name === 'latest' ? 'latest' : versionService.parseVersion(opt1.name)
    const v2 = opt2.name === 'latest' ? 'latest' : versionService.parseVersion(opt2.name)
    if (v1 === v2) {
      return 0
    }
    if (v1 === 'latest') {
      return 1
    }
    if (v2 === 'latest') {
      return -1
    }
    if (!!v1 && !!v2) {
      return versionService.sortSemverAndBuild(v1, v2)
    }
    if (v1) {
      return 1
    }
    if (v2) {
      return -1
    }
    return opt1.name < opt2.name ? -1 : 1
  }
}

userService.onLogin(() => {
  versionService.retrieveVersion()
})

export default versionService
