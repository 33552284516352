import { make } from '../pathify'
import { ONE_HOUR } from '@/lib/time'

const state = {
  settings: {
    zoomRange: ONE_HOUR,
    excludedClusters: []
  }
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations: {
    ...mutations
  },
  getters: {
  },
  actions: {
  }
}
