import { make } from '../pathify'

const state = {
  settings: {
    isOpen: false,
    subnavOpenIndex: -1
  },
  linklist: [
    {
      title: 'Home',
      path: '/home',
      icon: 'home3',
      tooltip: 'Home'
    },
    {
      title: 'Instance Management',
      path: '/instances',
      icon: 'instance2',
      tooltip: 'Instance Management',
      stroke: 'none'
    },
    {
      title: 'Query',
      path: '/query',
      icon: 'left_nav_query_light',
      tooltip: 'Query'
    },
    {
      title: 'Load',
      path: '/data-wizard/select-data',
      icon: 'loaddata2',
      tooltip: 'Load Assistant'
    },
    {
      title: 'Configuration',
      path: '/configuration',
      icon: 'configuration_nav',
      tooltip: 'Configuration',
      role: ['consumeradmin'],
      filter: function() {
        // $deploymentMode was not accessible here
        if(this.$deploymentMode === "ce") {
          return false
        }
        return true
      }
    }
  ]
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations
}
