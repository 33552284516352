import { createGettext } from "vue3-gettext"

const gettext = createGettext({
  availableLanguages: {
    en: 'English'
  },
  defaultLanguage: 'en',
  translations: {
    en: {} // none for now
  },
  silent: true
})
export default gettext

const { $gettext, interpolate: $gettextInterpolate } = gettext
export { $gettext, $gettextInterpolate }
