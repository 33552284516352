import { DateTime } from 'luxon'
import sprintf from 'sprintf'
import {
  jolokiaService,
  mbeans,
  patterns,
  app
} from '@/services'
import { functions } from '@/util'

// Skinny the columns down for dashboard and other use-cases.
const SKINNY = [
  'name',
  'chassis_id',
  'last_updated',
  'status_dm',
  'blade_state',
  'blade_bay',
  'mac_address',
  'ip_address',
  'uuid',
  'gpio_power_good_overall',
  'cpu_status'
]
const FULL = null

const workerQuery = function(scope, options = {}) {
  return Promise.allSettled([
    jolokiaService.getAttribute(mbeans.lime, options.connected ? 'ConnectedWorkersSummary' : 'KnownWorkersSummary')
      .then(response => response)
      .catch((error) => {
        if (error && !app.isErrorReported(error) && !String(error.error).match(/notfound/i)) {
          throw new Error(error.error || error)
        }
        return []
      }),
    jolokiaService.execute(mbeans.jmxQuery, 'queryAttributes', [patterns.blade, options.skinny ? SKINNY : FULL])
      .catch((error) => {
        if (error && !app.isErrorReported(error) && !String(error.error).match(/not exist/i)) {
          throw new Error(error.error || error)
        }
        return []
      }),
    jolokiaService.getAttribute(mbeans.lime, 'WorkersRebuilding')
      .then(response => response)
      .catch((error) => {
        if (error && !app.isErrorReported(error) && !String(error.error).match(/notfound/i)) {
          throw new Error(error.error || error)
        }
        return []
      }),
    jolokiaService.execute(mbeans.jmxQuery, 'queryAttributes', [patterns.chassis, null])
      .catch((error) => {
        if (error && !app.isErrorReported(error) && !String(error.error).match(/not exist/i)) {
          throw new Error(error.error || error)
        }
        return []
      }),
    jolokiaService.execute(mbeans.drives, 'retrieve', [{
      columns: ['status', 'worker_id', 'drive_num', 'driver_name', 'model_name', 'serial_number'],
      where: {
        'status:!=': 0
      }
    }])
      .then(response => response.rows)
  ])
    .then((responses) => {
      const blades = (responses[1].value || []).map((blade) => {
        blade.key = (blade.mac_address || '').replace(/:/g, '').toLowerCase()
        if (blade.key.match(/^fru/)) {
          blade.key = null
        }
        if (!blade.key) {
          blade.key = blade.ip_address
          blade.keyType = 'IP'
        } else {
          blade.keyType = 'MAC'
        }
        blade.fru_mfg_date = blade.fru_mfg_date && DateTime.fromFormat(blade.fru_mfg_date || '', 'yyMMdd').toJSDate()
        blade.chassis_id = parseInt(blade.chassis_id) || 0
        return blade
      })
      const workerMap = functions.indexBy((responses[0].value || []), 'id')
      const bladeMap = functions.indexBy(blades, 'key')

      // Do some calculations; correlate connected workers with blade.
      const now = new Date().getTime()
      functions.forEach(workerMap, (worker) => {
      // Last seen is in nanoseconds, so use the millis.
        worker.lastSeen = worker.lastSeenWallClock

        // Elapsed seconds since we saw this worker.
        worker.lastSeenElapsed = now - worker.lastSeen

        // Determine the provisioned state of each worker.
        worker.provisioned = worker.role === 'MEMBER'

        // A nice ordering key.
        worker.key = sprintf('%03s %s', worker.chassisId, worker.id)

        // Try to first correlate by mac, then by ip/hostname
        let workerKey = worker.id.replace(/.*?-.*?-.*-(.*)/, '$1').toLowerCase()
        let blade = bladeMap[workerKey]
        if (!blade) {
          workerKey = worker.hostname
          blade = bladeMap[workerKey]
        }

        // Set the blade up.
        if (blade) {
          worker.blade = blade
          worker.bay = parseInt(blade.blade_bay)
          delete bladeMap[workerKey]
          blade.worker = worker
        }
      })

      // Whatever blades we have, are now uncorrelated; show them here.
      if (options.uncorrelated) {
      // For each blade left, construct a faux worker.
        functions.forEach(bladeMap, (blade, workerKey) => {
          if (workerKey && blade.status_dm !== 'not_installed') {
            const worker = {
              blade,
              bay: blade.blade_bay,
              id: `${blade.keyType}: ${blade.key}`,
              hostname: blade.ip_address,
              state: 'NOT_AVAILABLE',
              role: 'NOT_INITIALIZED'
            }
            workerMap[worker.id] = worker
          }
        })

        // For each "spare" worker with no blade and that is offline, reassign to "Uninstalled"
        functions.forEach(workerMap, (worker) => {
          if (!worker.blade && worker.role === 'SPARE' && worker.state === 'OFFLINE') {
            worker.role = 'UNINSTALLED'
          }
        })
      }

      // Layer the state for workers rebuilding.
      (responses[2].value || []).forEach((id) => {
        workerMap[id] && (workerMap[id].rebuilding = true)
      })

      // Provide the state for chassis reporting state.
      const chassisStatus = (responses[3].value || []).map((c) => {
        c.last_reported_date = c.last_reported && new Date(c.last_reported)
        return c
      });

      // Provide failed drives.
      (responses[4].value || []).forEach((drive) => {
        const worker = workerMap[drive.worker_id]
        if (worker) {
          drive.worker_bay = worker.bay
          drive.chassis_id = worker.chassisId
          worker.failedDrives = worker.failedDrives || []
          worker.failedDrives.push(drive)
        }
      })

      // Return worker map and sorted lists by bay.
      return {
        chassisStatus,
        workerMap,
        workers: Object.values(workerMap).sort((w1, w2) => {
          return w1.bay - w2.bay
        }),
        blades: blades.sort((b1, b2) => {
          return parseInt(b1.blade_bay) - parseInt(b2.blade_bay)
        })
      }
    })
}

export default workerQuery
