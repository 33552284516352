import apollo from '@/apollo'
import ADD_USER_ROLE from '@/graphql/addUserRole.gql'
import CONSUMER_USERS from '@/graphql/consumerUsers.gql'
import JOB from '@/graphql/job.gql'
import MANAGER_UPGRADE from '@/graphql/managerUpgrade.gql'
import OFFERINGS from '@/graphql/offerings.gql'
import REMOVE_USER_ROLE from '@/graphql/removeUserRole.gql'
import * as functions from '@/util/functions'
import { versionService } from '@/services'

class AdministrationService {
  async getWarehouseManagerUpgradeOptions() {
    const response = await apollo.query({ query: OFFERINGS, variables: { operation: 'MANAGER_UPGRADE' } })
    return response?.data?.me?.consumer?.services[0]?.offerings?.sort(versionService.sortVersion).reverse() || []
  }

  async getConsumerUsers(input) {
    const response = await apollo.query({ query: CONSUMER_USERS, variables: { input } })
    const { users = [], roles = [] } = response?.data?.me?.consumer
    return { users, roles }
  }

  async addUserRole(input) {
    const response = await apollo.mutate({
      mutation: ADD_USER_ROLE,
      variables: {
        input
      }
    })
    return response
  }

  async removeUserRole(input) {
    const response = await apollo.mutate({
      mutation: REMOVE_USER_ROLE,
      variables: {
        input
      }
    })
    return response
  }

  async upgradeWarehouseManager({ offeringId }, cb) {
    const start = +new Date()

    const response = await apollo.mutate({
      mutation: MANAGER_UPGRADE,
      variables: {
        input: { offeringId }
      }
    })

    const {
      data: {
        managerUpgrade: {
          job: { id: jobId },
          errors
        }
      }
    } = response

    const expire = +new Date() + 15 * 60 * 1000

    while (+new Date() < expire) {
      try {
        const jobResponse = await apollo.query({
          query: JOB,
          variables: {
            id: jobId
          }
        })
        const {
          data: {
            job: {
              status: { message, stage }
            }
          }
        } = jobResponse

        if (stage === 'SUCCEEDED') {
          cb()

          // We have an upgraded instance!  Done.
          console.log(
            'Upgraded manager',
            'in',
            +new Date() - start,
            'ms'
          )
        } else if (stage === 'FAILED') {
          const error = `Manager upgrade failed: ${message}`
          console.log(error, 'in', +new Date() - start, 'ms')
          throw new Error(error)
        }
      } catch (error) {
        console.log(error)
      }
      // Delay until next check.
      await functions.timeout(15 * 1000)
    }
  }
}

const administrationService = new AdministrationService()
export default administrationService
