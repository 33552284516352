import accountService from '@/services/accountService'
import { app as appService } from '@/services'
import humanizeDuration from 'humanize-duration'

const oneDay = 24 * 60 * 60 * 1000
let licenseInfoTime = 0
const licenseInfoCache: any[] = [null]

const useLicense = () => {
  const trialExpired = (licenseInfo: any) => {
    licenseInfo ??= licenseInfoCache[0]
    if (!!licenseInfo) {
      const now = +new Date()

      if (licenseInfo.exp && now > +(new Date(parseInt(licenseInfo.exp) * 1000))) {
        return true
      } else if (licenseInfo.hardexp && now > +(new Date(licenseInfo.hardexp)) + oneDay) {
        return true
      } else {
        return false
      }
    } else {
      return true // we will just say we are
    }
  }

  const refreshLicense = async (instance: string|null = null) => {
    if (!!licenseInfoTime) {
      return
    }

    // How to handle installing license in ui data model?
    const setLicenseInfo = (licenseInfo: any) => {
      licenseInfoTime = +new Date()
      licenseInfoCache[0] = licenseInfo
      if (!licenseInfo) {

        // Offline?  Just no-op.
        if (appService.notices?.communicationErrors?.global) {
          appService.notices.license.warning = ''
        } else {
          appService.notices.license.warning = 'This software is unlicensed'
        }
      } else {
        appService.notices.license.warning = ''

        // Check expirations.
        appService.notices.license.exp = licenseInfo.exp
        appService.notices.license.softexp = licenseInfo.softexp
        appService.notices.license.hardexp = licenseInfo.hardexp
        appService.notices.license.trial = licenseInfo.trial

        // If the license is expired....
        const now = +new Date()
        let exp
        if (licenseInfo.exp && now > +(exp = new Date(parseInt(licenseInfo.exp) * 1000))) {
          appService.notices.license.warning = `The license installed for Yellowbrick has expired`
        } else if (licenseInfo.hardexp && now > +(exp = new Date(licenseInfo.hardexp)) + oneDay) {
          appService.notices.license.warning = `The license installed for Yellowbrick has expired`
        } else if (licenseInfo.softexp && now > +(exp = new Date(licenseInfo.softexp)) + oneDay) {
          appService.notices.license.warning = 'The license installed for Yellowbrick will expire soon'
          if (licenseInfo.hardexp) {
            exp = +(new Date(licenseInfo.hardexp)) + oneDay
            appService.notices.license.warning += ` (in ${humanizeDuration(exp - now, { units: ['d', 'h'], maxDecimalPoints: 0 })})`
          }
        }
      }
      false && console.log('Updated license info', appService.notices.license)
    }

    // Are we on an instance ?
    if (instance) {
      setLicenseInfo(await accountService.getInstanceLicenseInfo(instance))
    } else {
      setLicenseInfo(await accountService.getAnyLicenseInfo())
    }
  }

  const clearCache = () => {
    licenseInfoCache[0] = null
    licenseInfoTime = 0
  }

  return { trialExpired, refreshLicense, clearCache }
}
export default useLicense
