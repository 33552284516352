import storeService from '@/store/storeService'
import logRocketService from '@/services/logrocket'

export const LOGIN_PATH = '/login'
export const CHANGEPASSWORD_PATH = '/change-password'
export const NOT_AUTHORIZED_PATH = '/not-authorized'

interface AuthenticationService {
  authenticated: () => boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: () => void;
  resetPasswordAllowed: boolean,
  userName: string | null | undefined;
  roles: Set<string>;
}

/**
 * Our session checker.
 */
let sessionCheckInterval : number|null = null

/**
 * Configure logger.
 */
const debug = String(window.location.search).match(/debug/)
const logger = debug
  ? console
  : {
      log() {},
      error() {}
    }

let instance: AuthenticationService | null
export const getInstance = () => instance

const RFC2822MATCH = /^[a-z0-9!?'{}*/&#%`^+=|~$_-]+(?:\.[a-z0-9!?'{}*/&#%`^+=|~$_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
export const realmFromEmail = (email: string) => {
  if (!email.match(RFC2822MATCH)) {
    return null
  }

  const at = email.trim().lastIndexOf('@')
  if (at > 0) {
    return email.substring(at + 1)
  }

  return null
}

const useAuthenticationService = async (): Promise<AuthenticationService> => {
  const { status, data, getSession, signOut } = useAuth()

  const onStatus = () => {
    if (status.value === 'authenticated') {
      const token = data.value?.user as any
      const { email, given_name, family_name } = token
      const name = given_name && family_name ? `${given_name} ${family_name}` : (token.name || email)
      storeService.userName = email || token.name
      logRocketService.identify(storeService.userName, {
        name,
        email
      })
    }
  }

  watch(status, onStatus)
  onStatus()

  function redirectToLoginPage(what: string) {
    logger.log(`Error thrown during ${what}`)
    logoutFn()
    if (window.location.pathname !== LOGIN_PATH) {
      window.location.href = LOGIN_PATH
    }
  }

  async function checkToken() {
    if (!status.value) {
      redirectToLoginPage('check authenticated')
    }
    await getSession({ required: true })
  }

  function authenticated() {
    return status.value === 'authenticated'
  }

  function resetPasswordFn(): void {
    window.location.href = CHANGEPASSWORD_PATH
  }

  async function loginFn(): Promise<void> {
    await getSession({ required: true })
    if (!sessionCheckInterval) {
      sessionCheckInterval = window.setInterval(checkToken, 60 * 1000)
    }
  }

  async function logoutFn(): Promise<void> {
    storeService.userName = null
    if (sessionCheckInterval) {
      window.clearInterval(sessionCheckInterval)
      sessionCheckInterval = null
    }
    await signOut()
  }

  instance = {
    authenticated,
    login: loginFn,
    logout: logoutFn,
    resetPassword: resetPasswordFn,
    get resetPasswordAllowed(): boolean {
      return data.value?.type === 'credentials'
    },
    get userName(): string | null | undefined {
      if (authenticated()) {
        return data.value?.user?.name || data.value?.user?.email
      }
      return undefined
    },
    get roles(): Set<string> {
      return new Set(data.value?.user?.roles || [])
    }
  }
  return instance
}

export default useAuthenticationService

export const hasRole = (roleOrRoles: string|Array<string>, availableRoles: Set<string>|undefined = undefined) => {
  if (!availableRoles) {
    availableRoles = instance?.roles ?? new Set<string>()
  }
  if (!Array.isArray(roleOrRoles)) {
    roleOrRoles = [roleOrRoles]
  }
  for (const role of roleOrRoles) {
    if (availableRoles && availableRoles.has(role)) {
      return true
    }
  }
  return false
}
