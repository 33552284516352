import * as errorHandler from './errorHandler'
import { jolokiaService, mbeans, app, $gettextInterpolate } from '@/services'

/**
 * TODO:
 import { YbTableDialog, YbMultipleTablesDialog } from '@yb/app-components';
 */

class TableService {
/**
 * TODO:
  open(parent, tableNameOrOID, databaseName) {
    if (typeof tableNameOrOID === 'string') {
      // Get a list of tables with this name.   IF there are zero, tell the user, if there are > 1, let them choose, if one, go ahead.
      return jolokiaService
        .execute(mbeans.sysTableSummary, 'retrieve', [
          {
            where: { name: tableNameOrOID },
          },
        ])
        .then(response => {
          switch (response.rows.length) {
            case 0:
              app.warning($gettextInterpolate('Could not locate the table named "%{name}".', { name: tableNameOrOID }));
              break;
            case 1:
              return this.open(parent, response.rows[0].table_id, null);
            default:
              ModalProgrammatic.open({
                component: YbMultipleTablesDialog,
                parent,
                props: {
                  name: tableNameOrOID,
                  tables: response.rows,
                  ok: (table) => {
                    this.open(parent, table);
                  },
                },
              });
              break;
          }
        });
    } else {
      return this.getTableGeneral(tableNameOrOID).then(item => {
        if (!item) {
          app.warning($gettextInterpolate('Could not locate the table information you selected (%{tableNameOrOID}).', { tableNameOrOID }));
          return;
        }
        ModalProgrammatic.open({
          width: '75%',
          component: YbTableDialog,
          parent,
          props: {
            item,
            databaseName,
          },
        });
      });
    }
  }
*/

  getTableGeneral(table_id, database) {
    return jolokiaService.execute(mbeans.sysTableGeneral, 'retrieve', [{ params: { table_id }, options: { database } }])
      .then((response) => {
        if (response.rows.length === 1) {
          const t = response.rows[0]
          /**
           * Legacy remapping from StorageManager
           */
          return {
            oid: t.table_id,
            databaseId: t.database_id,
            name: t.name,
            schemaId: t.schema_id,
            ownerId: t.owner_id,
            distributionType: t.distribution,
            sortKey: t.sort_key,
            distributionKey: t.distribution_key,
            clusterKeys: t.cluster_keys,
            partitionKeys: t.partition_keys,
            isTemp: t.is_temp,
            isAutoAnalyze: t.is_auto_analyze,
            autoAnalyzePolicy: t.auto_analyze_policy,
            lastAnalyzed: t.last_analyzed,
            creationTime: t.creation_time,
            database: t.database_name,
            schemaName: t.schema_name,
            rows: t.rows_columnstore,
            pendingRows: t.rowstore_row_count,
            pendingSize: t.rowstore_bytes,
            bytesUsed: t.compressed_bytes,
            bytesUsedUncompressed: t.uncompressed_bytes
          }
        } else {
          return null
        }
      })
      .catch(response => app.errorResult(response))
  }

  async getTableDetails(databaseName, schemaName, tableName) {
    try {
      return await jolokiaService.execute(mbeans.datasources, 'getTableDefinition', [databaseName, schemaName, tableName])
    } catch (e) {
      errorHandler.thrown(e, 'Could not retrieve table details')
    }
  }
}

export default new TableService()
