/*
 * Copyright (C) 2015-19 Yellowbrick Data, Inc.
 * All rights reserved.
 *
 * COMPANY CONFIDENTIAL.  NOT FOR DISTRIBUTION.
 *
 * This work contains proprietary, confidential information of Yellowbrick Data, Inc.
 * Use, disclosure or reproduction without the express written authorization of
 * Yellowbrick Data, Inc. is prohibited.
 */

import Visibility from 'visibilityjs'
import debounce from 'debounce'

import baseUrl from './remoteService'
import { clearCommunicationsError } from './app'
import { Logger } from '@/util'
import { getInstance } from '@/auth'
import { ResponseError } from '@/services/errors'
import { isEqual } from '@/util/functions'

const log = Logger.get('yb.jolokia')

// holds the status returned from the last jolokia call (?)
export const jolokiaStatus = {
  xhr: null
}

// The factory for the parameters running the jolokia service
const DEFAULT_MAX_DEPTH = 10
const DEFAULT_MAX_COLLECTION_SIZE = 1000
const DEFAULT_TIMEOUT = 30000
function loadJolokiaParams() {
  let answer = {
    canonicalNaming: false,
    ignoreErrors: true,
    mimeType: 'application/json',
    maxDepth: DEFAULT_MAX_DEPTH,
    maxCollectionSize: DEFAULT_MAX_COLLECTION_SIZE,
    timeout: DEFAULT_TIMEOUT
  }
  const ls = window.localStorage.getItem('YM.jolokiaParams')
  if (ls) {
    answer = JSON.parse(ls)
  } else {
    window.localStorage.setItem('YM.jolokiaParams', JSON.stringify(answer))
  }
  return answer
}

// The simplified jolokia service/singleton.
function jolokiaConnector() {
  const params = loadJolokiaParams()
  params.ajaxError = function(xhr, textStatus, error) {
    if (xhr.status === 401 || xhr.status === 403) {
      // Silent fail for now
      // TODO: potentially redirect to login after initially validated
      jolokiaStatus.xhr = null
    } else {
      jolokiaStatus.xhr = xhr
      if (!xhr.responseText && error) {
        xhr.responseText = error.stack
      }
    }
  }
  if (!params.fetchInterval) {
    params.fetchInterval = parseInt(localStorage['SMC.updateRate'] || '5000')
  }
  params.url = 'jolokia'
  return params
}

const activeRequests = new Set()
let pinned

const jolokiaService = (function() {
  let pending = []
  const jolokiaParams = loadJolokiaParams()

  // Ensure we get at least 1000 rows back.  This is because we graph 3600s, at 5s intervals, which is 720 samples
  let maxDepth = Math.max(DEFAULT_MAX_DEPTH, jolokiaParams.maxDepth)
  let maxCollectionSize = Math.max(DEFAULT_MAX_COLLECTION_SIZE, jolokiaParams.maxCollectionSize)
  let timeout = Math.max(DEFAULT_TIMEOUT, jolokiaParams.timeout)
  let reauthenticate = () => {
    return Promise.reject(new Error('No re-authentication hook provided'))
  }
  let authorization

  function defaultErrorCallback(event) {
    const response = event && event.reason && (event.reason.message || event.reason.request)
    if (response) {
      console.error('[ym:error] uncaught error response', response)
      console.log('[ym:error] uncaught error response', response)
    }
  }

  // The jolokia instance.
  let jolokia

  // We do bulk invocation debounced on a short timer.
  function doInvoke() {
    if (pending.length === 0) {
      log.debug('No pending requests to post')
      return
    }
    try {
      (function invokePending(_pending) {
        // Do the requests.
        const requests = _pending.map(e => e.request)
        if (!jolokia) {
          log.warn('Disconnected; rejecting bulk requests', requests)
          _pending.forEach(p => p.reject({ error: 'Not Connected', status: -1 }))
        } else {
          log.debug('Doing bulk request: ' + JSON.stringify(requests, null, 1))

          // Gather the request params.
          const params = {
            maxDepth,
            maxCollectionSize,
            canonicalNaming: false,
            canonicalProperties: false,
            ignoreErrors: true
          }

          // Construct URL.
          const urlSearchParams = new URLSearchParams(params)
          const url = jolokia.url + '?' + urlSearchParams.toString()

          // Setup token/authorization.
          const bearer = getInstance()?.accessToken
          let headers
          if (bearer) {
            headers = { Authorization: `Bearer ${bearer}` }
          } else {
            headers = authorization ? { Authorization: authorization } : {}
          }

          // Our current context for hooking events later/cancellation.
          const context = { ...baseUrl.context }

          // Setup timeout.
          const controller = new AbortController()
          const timeoutId = setTimeout(() => controller.abort(), timeout)

          // Record the request.
          const request = { context, controller, pinned }
          activeRequests.add(request)

          // Issue request.
          fetch(baseUrl.url(url), {
            method: 'POST',
            mode: 'cors',
            credentials: authorization ? 'omit' : 'include',
            body: JSON.stringify(requests),
            signal: controller.signal,
            headers
          })
            .then((response) => {
              activeRequests.delete(request)

              // Clear abort timeout.
              clearTimeout(timeoutId)

              if (response.ok && response.status === 200) {
                return response.json().then((data) => {
                  if (!!data && !Array.isArray(data)) {
                    data = _pending.map(() => data) // This is an error condition but we should return the error to each request
                  }
                  jolokiaStatus.xhr = null
                  _pending.forEach((p, i) => {
                    data[i].context = context
                    data[i].navigationCancelled = request.navigationCancelled
                    if (data[i].status === 200) {
                      clearCommunicationsError()
                      if (Array.isArray(data[i].value)) {
                        const r = data[i].value
                        r.context = context
                        p.resolve(r)
                      } else if (typeof data[i].value === 'object') {
                        p.resolve({ ...data[i].value, ...{ context } })
                      } else {
                        p.resolve(data[i].value)
                      }
                    } else {
                      // It's a 403; must re-authenticate.  Handle re-authentication and resubmission here.
                      // Backend services will return 403 status if the cached password expired or is missing.
                      // This code negotiates the reauthentication process with the user.
                      if (data[i].status === 403 && String(data[i].error).match(/re-auth/i)) {
                        reauthenticate()
                          .then(() => {
                            invokePending([p])
                          })
                          .catch(() => {
                            p.reject(data[i])
                          })
                      } else {
                        p.reject(data[i])
                      }
                    }
                  })
                })
              } else {
                response.text().then((data) => {
                  _pending.forEach((p, i) => {
                    const error = new ResponseError(response, data)
                    error.context = context
                    error.data = data
                    error.navigationCancelled = request?.navigationCancelled
                    error.request = requests[i]
                    p.reject(error)
                  })
                })
              }
            })
            .catch((response) => {
              activeRequests.delete(request)
              response.context = context
              response.navigationCancelled = request?.navigationCancelled
              jolokiaStatus.xhr = response

              // Clear abort timeout.
              clearTimeout(timeoutId)

              // Reject all.
              _pending.forEach(p => p.reject(response))
            })
        }

        // Reset max collection size and timeout, it can be overriden per-request.
        maxCollectionSize = Math.max(DEFAULT_MAX_COLLECTION_SIZE, jolokiaParams.maxCollectionSize)
        timeout = Math.max(DEFAULT_TIMEOUT, jolokiaParams.timeout)
      })(pending)
    } finally {
      pending = []
      pinned = false
    }
  }
  const invoke = debounce(doInvoke, 10)

  function send(e) {
    if (!jolokia) {
      throw new Error('Jolokia is not started')
    }

    // Deduplicate identical requests.
    for (const p of pending) {
      if (isEqual(p.request, e.request)) {
        return p.promise
      }
    }

    // Make the queue entry; assign promise and promise components.
    const entry = Object.assign({}, e)
    const result = new Promise((resolve, reject) =>
      Object.assign(entry, { resolve, reject })
    )
    entry.promise = result // For de-duplication
    pending.push(entry)

    // Do the queue (invoke) or send here (doInvoke)
    const unbatched = String(window.location.search).match(/unbatched/i)
    unbatched ? doInvoke() : invoke()
    return result
  }
  function assertNonNull(...args) {
    args.forEach((arg) => {
      if (!arg) {
        throw new Error('Argument cannot be null')
      }
    })
  }
  function assertUndefined(...args) {
    args.forEach((arg) => {
      if (arg) {
        throw new Error('Argument should not be defined')
      }
    })
  }
  function intervalPinger(self, interval, intervalFn) {
    let calling
    const empty = () => {}
    const callbackPromise = {
      then: empty,
      catch: defaultErrorCallback,
      finally: empty
    }

    // Setup refresher to callback with the promise given on each invocation
    function refresh() {
      return new Promise((resolve /* , reject */) => {
        if (jolokia) {
          if (!calling) {
            calling = true

            function reset() {
              calling = false
              resolve()
            }
            intervalFn.call(self, callbackPromise).then(reset, reset)
          } else {
            resolve()
          }
        } else {
          resolve()
        }
      })
    }

    // Create repeating timer.
    let timer = Visibility.every(interval, refresh)
    let startTimer

    // This is promise-like but not a JS Promise, b/c we repeatedly callback
    const resultPromise = {
      then: (tt) => {
        callbackPromise.then = tt
        return resultPromise
      },
      catch: (cc) => {
        callbackPromise.catch = cc
        return resultPromise
      },
      finally: (ff) => {
        callbackPromise.finally = ff
        return resultPromise
      },
      cancel: () => {
        Visibility.stop(timer)
        startTimer && window.clearTimeout(startTimer)
        calling = false
        return resultPromise
      },
      refresh,
      timeout: (interval) => {
        Visibility.stop(timer)
        timer = Visibility.every(interval, refresh)
        return resultPromise
      }
    }

    startTimer = window.setTimeout(() => {
      refresh()
      startTimer = null
    }, 10)

    return resultPromise
  }
  return {
    init() {
      // no-op
    },
    get jolokia() {
      return this.start()
    },
    start() {
      if (!jolokia) {
        jolokia = jolokiaConnector()
      }
      return jolokia
    },
    stop() {
      if (jolokia && jolokia.stop) {
        jolokia.stop()
      }
      jolokia = null
    },
    started() {
      return !!jolokia
    },
    collectionSize(_) {
      if (typeof _ !== 'undefined') {
        maxCollectionSize = _
      }
      return Promise.resolve(maxCollectionSize)
    },
    depth(_) {
      if (typeof _ !== 'undefined') {
        maxDepth = _
      }
      return Promise.resolve(maxDepth)
    },
    timeout(_) {
      if (typeof _ !== 'undefined') {
        timeout = _
      }
      return timeout
    },
    reauthenticate(_) {
      if (typeof _ !== 'undefined') {
        reauthenticate = _
      }
      return reauthenticate
    },
    authorization(_) {
      if (typeof _ !== 'undefined') {
        authorization = _
      }
      return authorization
    },
    flush() {
      doInvoke()
      return Promise.resolve({})
    },
    request(request, nope) {
      assertNonNull(request)
      assertUndefined(nope)
      return send({ request })
    },
    execute(mbean, operation, args, nope) {
      assertNonNull(mbean, operation, args)
      assertUndefined(nope)
      return send({
        request: {
          type: 'exec',
          mbean,
          operation,
          arguments: args
        }
      })
    },
    getAttribute(mbean, attribute, nope) {
      assertNonNull(mbean, attribute)
      assertUndefined(nope)
      return send({
        request: { type: 'read', mbean, attribute }
      })
    },
    setAttribute(mbean, attribute, value, nope) {
      assertNonNull(mbean, attribute)
      assertUndefined(nope)
      return send({
        request: {
          type: 'write',
          mbean,
          attribute,
          value
        }
      })
    },
    list(path, nope) {
      assertNonNull(path)
      assertUndefined(nope)
      return send({ request: { type: 'list', path } })
    },
    register(args, nope) {
      assertNonNull(args)
      assertUndefined(nope)
      if (!Array.isArray(args)) {
        args = [args]
      }

      // Setup dispatch to call on ourself for each arg and dispatch with legacy response
      function dispatch(promise) {
        return Promise.all(
          args.map((arg) => {
            switch (String(arg.type).toLowerCase()) {
              case 'read':
                return this.getAttribute(arg.mbean, arg.attribute)
              case 'exec':
                return this.execute(arg.mbean, arg.operation, arg.arguments || [])
              default:
                throw new Error('Unknown registration type: ' + arg.type)
            }
          })
        )
          .then(responses =>
            responses.forEach((response, index) => {
              promise.then({ request: args[index], value: response })
            })
          )
          .catch(promise.catch)
          .finally(promise.finally)
      }
      return intervalPinger(this, 5000, dispatch)
    },
    periodic(registrar, interval = 5000, setupFn = undefined) {
      // Setup dispatcher to call the registrar
      function dispatch(promise) {
        // Might be a setup fn; invoke that.
        setupFn && setupFn(this)

        // Get the caller's registered promise/promises. /*eslint-disable no-invalid-this */
        const promises = registrar(this)

        // Did the caller do one promise or many?
        let doWork
        if (!Array.isArray(promises)) {
          doWork = promises
        } else {
          doWork = Promise.allSettled(promises)
        }

        // Settle the promise(s).
        return doWork
          .then(promise.then)
          .catch(promise.catch)
          .finally(promise.finally)
      }

      return intervalPinger(this, interval, dispatch)
    },

    pin() {
      pinned = true
    },

    abortActiveRequests(to) {
      // NB: only abort requests in the instance views.
      const instance = to?.instance || to?.params?.instance
      Array.from(activeRequests)
        .filter(r => !!r.context?.instance && r.context?.instance !== instance)
        .filter(r => !r.pinned)
        .forEach((request) => {
          const { controller, timeoutId } = request
          request.navigationCancelled = true
          window.clearTimeout(timeoutId)
          controller.abort()
          activeRequests.delete(request)
        })
    }
  }
})()

export default jolokiaService
