import type { App } from 'vue'
import createPlugin from 'logrocket-vuex'

const ENABLE_LOGROCKET =
  (import.meta.env.PROD && internalHost() && import.meta.env.VITE_LOGROCKET !== 'false') ||
  (import.meta.env.VITE_LOGROCKET_FORCE === 'true')
const ENABLE_LOGROCKET_VUEX =
    (import.meta.env.VITE_LOGROCKET_VUEX === 'true')

let LogRocket: any
let identifyEmail: string|null = null
let identifyOptions: any|null = null
const productionDataPrivate: any = {}

export default {
  async init(app: App<Element>) {
    if (!LogRocket) {
      if (ENABLE_LOGROCKET && !window.navigator.userAgent.match(/headless/i)) { // do not record headless testing sessions
        // Wait loading logrocket api module.
        const module = await import('logrocket')
        LogRocket = module.default
        const isInternalHost = internalHost()

        // NB: leaving this here, because WE NEVER WANT TO DO SOMETHING LIKE THIS for a production build.
        // Don't advertise that logrocket is on/off/whatever
        false && console.log('[ym] enabling logrocket')
        LogRocket.init('rabkrg/yellowbrick-manager', {
          dom: {
            inputSanitizer: false,
            textSanitizer: false
          },
          network: {
            requestSanitizer: (request: any) => {
              if (!isInternalHost) {
                request.headers.Authorization = null
                if (request.url.match(/auth|jolokia|graphql/)) {
                  request.body = null
                }
              }
              return request
            },
            responseSanitizer: (response: any) => {
              if (!isInternalHost) {
                response.body = null
              }
              return response
            }
          }
        })
        if (import.meta.env.VITE_LOGROCKET_PRIVATE !== 'false') {
          if (!isInternalHost || import.meta.env.VITE_LOGROCKET_PRIVATE === 'true') {
            productionDataPrivate['data-private'] = ''
          }
        }

        // This can happen; we can authenticate before we load the logrocket runtime.
        if (identifyEmail) {
          LogRocket.identify(identifyEmail, identifyOptions)
        }
      }
    }
    app.config.globalProperties.$track = this.track
    app.config.globalProperties.$productionDataPrivate = productionDataPrivate
  },

  addVuexPlugin(plugins: Array<any>) {
    if (!!LogRocket && ENABLE_LOGROCKET_VUEX) {
      plugins.push(createPlugin(LogRocket))
    }
  },

  track(eventName: String, arg: any) {
    return LogRocket && LogRocket.track.call(LogRocket, eventName, arg || {})
  },

  identify(email: string, options: any) {
    LogRocket && LogRocket.identify(email, options)
    identifyEmail = email
    identifyOptions = options
  },

  get sessionURL() {
    return LogRocket && LogRocket.sessionURL
  }
}
