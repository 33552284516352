import InstanceModel from './InstanceModel'
import Role from './Role'

export default class OwnerModel extends InstanceModel {
  static entity = 'ownerModel'

  static fields () {
    return {
      ...super.fields(),
      owner_id: this.number(null),
      _owner_name: this.string(null).nullable()
    }
  }

  get owner() {
    return Role.query().whereId([this.instance_id, this.owner_id]).first()
  }

  get owner_name() {
    return this._owner_name || this.owner?.name || '(unknown)'
  }
}
