import { defineComponent } from 'vue'
import YbModal, { DEFAULT_TITLE } from '@/components/YbModal.vue'
import YbPrompt from '@/components/YbPrompt.vue'

const DialogConstructor = defineComponent(YbModal)
let app

const modals = new Set()
const destroyModal = (modalObject) => {
  modalObject.destroyRef && modalObject.destroyRef()
  modalObject.containerElement && modalObject.containerElement.remove()
  modals.delete(modalObject)
}

export function modal(title, description, type, acceptLabel = 'OK', component = null, componentProps = null, parent = null, modalProps = {}, dataTestId = "", forceConfirmation = false, focusSubmit = false, useMarkdown = false) {
  const containerElement = document.createElement('div')
  const modalObject = { containerElement }
  modals.add(modalObject)
  let promiseResolve, promiseReject

  const { destroy } = app.$renderComponent(
    containerElement,
    DialogConstructor,
    Object.assign({
      type,
      title: title || DEFAULT_TITLE,
      description,
      acceptLabel,
      component,
      componentProps,
      dataTestId,
      forceConfirmation,
      focusSubmit,
      useMarkdown,
      show: true,
      clickLock: true,
      onSubmit() {
        destroyModal(modalObject)
        promiseResolve()
      },
      onClose() {
        destroyModal(modalObject)
        promiseReject()
      }
    }, (modalProps || {})),
    parent
  )
  modalObject.destroyRef = destroy
  document.querySelector('body').appendChild(containerElement)

  return new Promise((resolve, reject) => {
    promiseResolve = resolve
    promiseReject = reject
  })
}

export function acceptModal(title, description, acceptLabel, cancelLabel) {
  return modal(title, description, 'confirm', acceptLabel, null, null, null, { cancelLabel }, "message-banner-modal", true, true, true)
}

export function confirmModal(title, description, acceptLabel, cancelLabel) {
  return modal(title, description, 'confirm', acceptLabel, null, null, null, { cancelLabel })
}

export function alertModal(title, description, acceptLabel) {
  return modal(title, description, 'alert', acceptLabel)
}

export function componentModal(title, acceptLabel, component, componentProps, parent, modalProps) {
  return modal(title, '', 'component', acceptLabel, component, componentProps, parent, modalProps)
}

export async function promptModal(title, message, buttonLabels) {
  const buttonChoice = [-1]
  const componentProps = {
    message,
    buttonLabels,
    buttonChoice
  }
  try {
    await componentModal(title, null, YbPrompt, componentProps)
  } catch (cancel) {
  }
  return buttonChoice[0]
}

export function clearAllModals() {
  [...modals].forEach(destroyModal)
}

export function install(_app) {
  app = _app
}
