import { reactive } from 'vue'

import { networkFailRegex, notTrustedRegex, errorIsTimeout, errorIsCancelled, errorIsUnavailable } from './errorHandler'
import ResponseErrorComponent from './ResponseErrorComponent.vue'
import { Logger } from '@/util'
import { baseUrl, $gettext, $gettextInterpolate } from '@/services'
import { ResponseError } from '@/services/errors'
import Instance from '@/models/Instance'

// import { errorCodes } from 'app/errgen/ErrorCodes';
const errorCodes = {} // TODO:

const dialogs = useDialogs()

const log = Logger.get('yb.util')

export function isErrorReported(err) {
  // We do not report errors on DISCONNECTED (0, -1), METHOD NOT ALLOWED (405 on startup), Unauthorized (401 when session closed/invalid), FORBIDDEN (403, handled by login poller), SERVICE UNAVAILABLE (503 pg not ready).
  // These are handled at the login and status pollers to show/hide the login page
  // and/or the disconnected indicator if required.
  return !err || !err.status || err.status === 0 || err.status === -1 || err.status === 401 || err.status === 403 || err.status === 405 || err.status === 503
}

export function makeError(err) {
  if (isErrorReported(err)) {
    if (typeof err.status !== 'undefined') {
      return
    } else {
      return { error: err }
    }
  } else if (String(err && err.error).match(/InstanceNotFoundException|WM002/g)) {
    err.error = $gettext('The cluster is not online.  Please try again later.')
    delete err.stacktrace
    log.debug('An error occurred: ' + err.error, err)
  } else {
    err.error = String(err.error).trim() || String(err.messsage).trim() || '';
    ['stacktrace', 'stack'].forEach((s) => {
      const stack = err[s]
      if (typeof stack === 'string') {
        err.error += '\n' + stack
      } else if (Array.isArray(stack)) {
        err.error += '\n' + stack.join('\n')
      }
    })
    log.error('[ym] An error occurred: ' + err.error, err)
    log.trace(err)
  }
  return err
}

export function makeException(err) {
  const result = new Error(err.message || err.error)
  delete result.stack
  result.stacktrace = err.stacktrace || err.stack
  log.error('[ym] An error occurred: ' + result.message, result)
  log.trace(result)
  return result
}

function dump(what, message, scope) {
  if (what !== 'info') {
    let log = console && console[what]
    if (!log) {
      log = console && console.warn
    }
    if (log) {
      log('[ym]: ' + what + ': ' + $gettextInterpolate(message, scope))
    }
  }
}

export const notices = reactive({
  banner: null, // `I am jack's scheduled outage`,
  license: {
    warning: '', // `I am jack's warning about license issues`
    trial: true,
    exp: 0,
    softexp: 0,
    hardexp: 0
  },
  communicationErrors: {
    global: null,
    instance: null
  }
})

export function exception(exception, message) {
  if (window.onerror) {
    window.onerror(message, null, -1, -1, exception)
  }
}

export function communicationsError(global) {
  if (!global) {
    const instanceId = baseUrl.context?.instance
    if (instanceId) {
      const instance = Instance.query().where('id', instanceId).first()
      if (!!instance && instance.online) {
        console.error('[ym] experiencing timeouts or network error to instance', baseUrl.context)
        notices.communicationErrors.instance = baseUrl.context
      }
    }
  } else {
    console.error('[ym] experiencing network error to YM')
    notices.communicationErrors.instance = null
    notices.communicationErrors.global = true
  }
}

export function clearCommunicationsError(global) {
  if (global) {
    notices.communicationErrors.global = null
  } else {
    notices.communicationErrors.instance = null
  }
}

export function error(message) {
  return dialogs.error(message)
}

export function warning(message) {
  return dialogs.warning(message)
}

export function info(message) {
  return dialogs.info(message)
}

export function errorResult(result, reportInstanceNotFound = false, what = '') {
  if (!isErrorReported(result) && (!String(result && result.error).match(/InstanceNotFoundException/g) || reportInstanceNotFound)) {
    // Is this an exception we should just display as a warning?
    const msg = result.error || result
    if (String(msg).match(/InstanceNotFoundException|WM002|InvalidClusterId/)) {
      return warning(what + $gettext('The instance is not online.  Please try again later.'))
    } else if (String(msg).match(/wait time exceeded/)) {
      return warning(what + $gettext('Your query exceeded the maximum allowed wait time.<br><br>The instance cluster may be busy or suspended.  Please try again later.'))
    } else if (String(msg).match(/(YBDException|PGSQLSimpleException)\s*:/)) {
      return warning(what + $gettext(String(msg).replace(/.*(YBDException|PGSQLSimpleException)\s*:\s*/, '')))
    } else {
      return error(what + String(result.error || result).replace(/^java\.lang\.Exception : /, ''), null, null, result)
    }
    return true
  } else if (result && result.error === 'timeout') {
    return warning(what + $gettext('The operation timed out'))
  } else if (errorIsCancelled(result)) {
    return Promise.resolve(false) // do nothing
  } else if (errorIsTimeout(result) || errorIsUnavailable(result)) {
    let global

    // See if the error has an URL, and that it matches the current URL.  If the URL doesn't match, we'll drop the error on the floor.
    if (result.context) {
      const currentContext = baseUrl.context?.instance
      if (currentContext !== result.context?.instance) {
        return Promise.resolve(false)
      }
    } else {
      global = true
    }

    communicationsError(global)
    return Promise.resolve(false)
  } else if (result instanceof ResponseError || (result instanceof Error && !!result.networkError) || (result instanceof Error && !!result.graphQLErrors)) {

    // Graphql buried errors when we get unauthenticated to suppress.
    if (Array.isArray(result?.graphQLErrors) && result.graphQLErrors.length > 0 && result.graphQLErrors[0].statusCode === 401) {
      console.log('[ym] graphql response is unauthorized')
      const { status } = useAuthState()
      if (status.value === 'unauthenticated') {
        console.log('[ym] performing logout')
        useLogout().logout()
      }
      return Promise.resolve(false)
    }

    // If this is an unauthorized, we'll lean into the redirect for /login coming, rather than show an ugly error.
    if (result.response?.status === 401) {
      console.log('[ym] response is unauthorized; login redirect pending')
      return Promise.resolve(false)
    }
    return dialogs.component('Error', 'Close', ResponseErrorComponent, { error: result, message: what }, null, { dataTestId: 'response-error' })
      .catch(() => {}) // we do not care about cancelling this dialog.
  } else if (result instanceof Error) {

    if (!!what && !what.endsWith(' ')) {
      what += '  '
    }
    const route = useRoute()
    if (route?.params?.instance_id) {
      result.context = { instance: route?.params?.instance_id }
    }
    const message = String(result) === what + result ? null : what + result
    return dialogs.component('Error', 'Close', ResponseErrorComponent, { error: result, message }, null, { dataTestId: 'generic-error' })
      .catch(() => {}) // we do not care about cancelling this dialog.
  } else {
    return Promise.resolve(false)
  }
}

window.addEventListener("unhandledrejection", function(promiseRejectionEvent) {
  // Don't want console barking about unhandled rejection without the context we need.
  promiseRejectionEvent.preventDefault()

  // Swallow some vue-router NavigationFailureType promise rejections.
  if (promiseRejectionEvent.reason instanceof Error && !!promiseRejectionEvent.reason._isRouter &&
     (promiseRejectionEvent.reason.type === 8 || // NavigationFailureType.cancelled
      promiseRejectionEvent.reason.type === 16)) { // NavigationFailureType.duplicated
    return
  }

  // Send event to console.
  if (!errorIsTimeout(promiseRejectionEvent.reason) && !errorIsCancelled(promiseRejectionEvent.reason)) {
    console.error('[ym] unhandled rejection in promise', promiseRejectionEvent)
    if (!!promiseRejectionEvent.reason && (promiseRejectionEvent.reason instanceof Error)) {
      console.error(promiseRejectionEvent.reason)
    }
  }

  // Handle the result.
  errorResult(promiseRejectionEvent.reason || promiseRejectionEvent)
})

export function mapQueryStatus(queryStatus, shortForm) {
  if (typeof queryStatus === 'object') {
    if (queryStatus.errorCode) {
      const errorCode = errorCodes[queryStatus.errorCode]
      if (errorCode && !!errorCode.dfltMessage) {
        if (shortForm) {
          return errorCode.errorCode
        } else if (queryStatus.detail) {
          return errorCode.errorCode + ': ' + queryStatus.detail
        } else {
          return errorCode.errorCode + ': ' + errorCode.dfltMessage // !!! TODO: i18n
        }
      } else {
        return queryStatus.errorCode
      }
    }
  } else if (typeof queryStatus === 'string') {
    // Is this a string starting with a 5-char code?
    const STATUS_RE = /(\w{5}) ?(.*)/
    const match = STATUS_RE.exec(queryStatus)
    if (match) {
      const errorCode = errorCodes[match[1]]
      if (errorCode && !!errorCode.dfltMessage) {
        if (shortForm) {
          return errorCode.errorCode
        } else if (match[2]) {
          return errorCode.errorCode + ': ' + match[2] // !!! TODO: i18n
        } else {
          return errorCode.errorCode + ': ' + errorCode.dfltMessage // !!! TODO: i18n
        }
      }
    }
  }

  return queryStatus
}

const RE_EXCEPTION = /.*Exception\s*:/
const MESSAGE_NETWORK_FAIL = 'Could not connect to the instance'
const MESSAGE_NETWORK_TIMEOUT = 'A timeout occurred communicating with the instance'
const MESSAGE_NOT_TRUSTED = 'This instance has not been configured for single sign-on'
export function formatError(e) {
  if (errorIsCancelled(e)) {
    return '' // don't format a cancelled request
  }
  if (e) {
    if (errorIsUnavailable(e)) {
      return MESSAGE_NETWORK_FAIL
    }
    if (e instanceof Error) {
      if (String(e.message).match(notTrustedRegex)) {
        return MESSAGE_NOT_TRUSTED
      } else if (String(e.message).match(networkFailRegex)) {
        return MESSAGE_NETWORK_FAIL
      } else if (e instanceof DOMException && e.name === 'AbortError') {
        return MESSAGE_NETWORK_TIMEOUT
      } else if (!!e.response && (e.response?.status === 401 || e.response?.status === 403)) {
        return 'Not authorized'
      }
      return e.message
    } else if (typeof e.data !== 'undefined' && !!e.data) {
      if (String(e.data).match(notTrustedRegex)) {
        return MESSAGE_NOT_TRUSTED
      } if (String(e.data).match(networkFailRegex)) {
        return MESSAGE_NETWORK_FAIL
      } else {
        return e.data
      }
    } else if (typeof e.response !== 'undefined') {
      e = e.response
      if (e instanceof Response) {
        if (e.status === 403 || e.status === 401) {
          return 'Not authorized'
        } else if (e.status === 503) {
          return MESSAGE_NETWORK_FAIL
        } if (e.status >= 400) {
          return e.statusText
        }
      }
    } else if (typeof e.message !== 'undefined' && !!e.message) {
      e = e.message
    } else if (typeof e.error !== 'undefined' && !!e.error) {
      return e.error.replace(RE_EXCEPTION, '')
    }

    // Look for "FATAL" on multi-line error, and strip java exception prefix.

    let result = String(e)
    let fatals

    if (!!(fatals = result.match(/FATAL.*/gm)) && result.includes('\n')) {
      result = fatals.pop().replace(/\s*FATAL\s*\|\s*/g, '') // Multi-line FATAL, show last only.
    }

    return result.replace(RE_EXCEPTION, '')
  }
}

export function smcQueryStatus(d, shortForm) {
  if (d) {
    return mapQueryStatus(d, shortForm)
  }
}

/**
 * Get current utc epoch
 */
export function getCurrentTimeUTC() {
  const now = new Date()
  return now.getTime() + now.getTimezoneOffset() * 60000
}

// For using template strings, adapted from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals

export function templify(strings, ...keys) {
  return function(...values) {
    const result = [strings[0]]
    values.forEach(function(value, i) {
      result.push(value, strings[i + 1])
    })
    return result.join('')
  }
}

export function matchFilterIgnoreCase(text, filters) {
  return filters.split(/,/i).some((filter) => {
    return !!text.match(new RegExp(filter.trim(), 'gi'))
  })
}

// From: http://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
export const toCamelCase = string =>
  string
    .replace(/[\s|_|-](.)/g, $1 => $1.toUpperCase())
    .replace(/[\s|_|-]/g, '')
    .replace(/^(.)/, $1 => $1.toLowerCase())

export function touchForm(form) {
  throw new Error('Not implemented')
}

// From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const getSeverityLabel = (severity) => {
  if (severity >= 100) {
    return $gettext('Critical')
  } else if (severity >= 75) {
    return $gettext('Major')
  } else if (severity >= 50) {
    return $gettext('Minor')
  } else if (severity >= 25) {
    return $gettext('Informational')
  } else if (severity >= 0) {
    return $gettext('OK')
  }
}

export const getSeverityClass = (severity) => {
  if (severity >= 100) {
    return 'critical'
  } else if (severity >= 75) {
    return 'major'
  } else if (severity >= 50) {
    return 'minor'
  } else if (severity >= 25) {
    return 'info'
  } else if (severity >= 0) {
    return 'ok'
  }
}

export function handleError(response, message) {
  if (response?.status === 403 || response?.status === 401) {
    warning($gettext('You do not have permission to perform this operation.'))
  } else if (String(message).match(/PGSQLSimpleException:/)) {
    warning(String(message).replace(/.*PGSQLSimpleException:([^\n]*)[^]*/g, '$1'))
  } else {
    error(message)
  }
}

export function i18NCompare(s1, s2) {
  s1 = $gettext(s1)
  s2 = $gettext(s2)
  if (s1 < s2) {
    return -1
  }
  if (s1 > s2) {
    return 1
  }
  return 0
}
