import DatabaseModel from './DatabaseModel'
import Schema from './Schema'

export default class SchemaModel extends DatabaseModel {
  static entity = 'schemaModel'

  static fields () {
    return {
      ...super.fields(),
      schema_id: this.number(null).nullable()
    }
  }

  get schema() {
    return Schema.query().whereId([this.instance_id, this.database_id, this.schema_id]).first()
  }

  get schema_name() {
    return this.schema?.name
  }

  get path() {
    return `${this.database_name}/${this.schema_name}`
  }

  get location() {
    return `${this.schema_name}.${this.name}`
  }

  get quotedLocation() {
    return `"${this.schema_name}"."${this.name}"`
  }

  get label() {
    return `${this.name} (${this.path})`
  }

  static filterCommon =
    item => !item.schema_name?.match(Schema.filterCommonRE)
}
