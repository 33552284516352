// Global constants.

/* eslint-disable no-useless-escape */
export const passwordExpression = /^(?=.*[A-Z])(?=.*[!@#$&*%\^\.\?:;,<>\[\]\{\}\(\)=\+\-_`~\\/])(?=.*[0-9])(?=.*[a-z]).{8,}$/
export const passwordExpressionString = passwordExpression.toString().replace(new RegExp('^/(.*?)/$'), '$1')

export const contextPath = '/smc' // TODO: calculate this

export const mbeans = {
  // sorted list
  activeAlerts: 'io.yellowbrick:container=database,class=QUERY,name=yb_active_alert',
  activeSessions: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.active.sessions',
  activeQueries: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.active.queries',
  alertConfiguration: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,name=Alert Configuration',
  alertService: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,name=Alert Service',
  alerts: 'io.yellowbrick:container=database,class=QUERY,name=yb_alert',
  audit: 'io.yellowbrick:container=database,class=TABLE,name=audit',
  bbfsSize: 'io.yellowbrick:container=database,class=QUERY,name=bbfssize',
  bulk: 'io.yellowbrick.lime/io.yellowbrick:container=lime,name=Bulk Registry',
  bytesInterfaceRx: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.interface.CUST_bond.rx_bytes',
  bytesInterfaceTx: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.interface.CUST_bond.tx_bytes',
  bytesNetwork: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.io.bytes.network',
  bytesRead: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.io.bytes.read',
  bytesReadSpill: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.io.bytes.read.spill',
  bytesWritten: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.io.bytes.written',
  bytesWrittenSpill: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.io.bytes.written.spill',
  cacheEfficiency: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.io.cluster.cache.efficiency',
  chassisManager: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,name=Chassis Manager',
  compileRate: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.plan.compile.rate',
  config: 'hawtio:type=ConfigAdmin',
  configAdmin: 'org.apache.karaf:type=config,name=root',
  databaseReplica: 'io.yellowbrick:container=database,name=Replication Service',
  databaseReplicaRate: 'io.yellowbrick:container=database,class=QUERY,name=yb_replication_transfer_rate',
  databaseReplicaVolume: 'io.yellowbrick:container=database,class=QUERY,name=yb_replication_transfer_volume',
  databases: 'io.yellowbrick:container=database,class=QUERY,name=pg_database',
  datasources: 'io.yellowbrick:container=database,name=Service',
  dcsCollector: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,name=CollectorManager',
  dcsConfiguration: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,name=Configuration',
  dcsMFT: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,name=Communications',
  dcsTrust: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,name=Trust',
  drives: 'io.yellowbrick:container=database,class=QUERY,name=drives',
  executionRate: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.query.execution.rate',
  externalFormat: 'io.yellowbrick:container=database,class=QUERY,name=external_format',
  externalLocation: 'io.yellowbrick:container=database,class=QUERY,name=external_location',
  externalService: 'io.yellowbrick:container=database,name=Data Movement Service',
  externalStorage: 'io.yellowbrick:container=database,class=QUERY,name=external_storage',
  externalTable: 'io.yellowbrick:container=database,class=QUERY,name=external_table',
  flushRegistry: 'io.yellowbrick.lime/io.yellowbrick:container=lime,name=Flush Registry',
  groupsPG: 'io.yellowbrick:container=database,class=ENTITY,name=pg_group',
  hostCPULoad: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=os.systemCpuLoad',
  jmxQuery: 'io.yellowbrick:container=Common,name=JMX Query',
  ldap: 'io.yellowbrick.lime/io.yellowbrick:container=lime,name=LDAP',
  ldapSynchronizer: 'io.yellowbrick.lime/io.yellowbrick:container=lime,name=LDAP Synchronizer',
  lime: 'io.yellowbrick.lime/io.yellowbrick:container=lime,name=Controller',
  logAnalyze: 'io.yellowbrick:container=database,class=QUERY,name=log_analyze',
  logQuery: 'io.fabric8.insight:type=LogQuery',
  logReplication: 'io.yellowbrick:container=database,class=QUERY,name=log_replication',
  memoryUsed: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.worker.memory.used',
  physFreeMemory: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=os.freePhysicalMemorySize',
  processCPULoad: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=os.processCpuLoad',
  processMemoryUsed: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=memory.total.used',
  queryAlerts: 'io.yellowbrick:container=database,class=TABLE,name=yb_query_alert',
  queryCache: 'io.yellowbrick.lime/io.yellowbrick:container=lime,name=Query Cache',
  queryExecutionUsage: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_execution_usage',
  queryLog: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_log',
  queryMemory: 'io.yellowbrick:container=database,class=SERIES,name=mem_used_bytes',
  queryRate: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_rate',
  queryStatistics: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_statistics',
  queryStatisticsLog: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_statistics_log',
  rbacRegistry: 'hawtio:type=security,name=RBACRegistry',
  replica: 'io.yellowbrick:container=database,class=QUERY,name=sys_replica',
  requestCount: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.query.request.count',
  requestRate: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.query.request.rate',
  rolesPG: 'io.yellowbrick:container=database,class=QUERY,name=pg_roles',
  smc: 'io.yellowbrick:container=SMC,name=Controller',
  statActivity: 'io.yellowbrick:container=database,class=QUERY,name=pg_stat_activity',
  storageTotal: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.worker.storage.total',
  storageTotalGauge: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=gauges,name=lime.worker.storage.total',
  storageUsed: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.worker.storage.used',
  storageUsedGauge: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=gauges,name=lime.worker.storage.used',
  sysBackupRestore: 'io.yellowbrick:container=database,class=QUERY,name=sys_backuprestore',
  sysConfiguration: 'io.yellowbrick:container=SMC,name=Configuration',
  sysDatabase: 'io.yellowbrick:container=database,class=QUERY,name=sys_database',
  sysDriveStorage: 'io.yellowbrick:container=database,class=QUERY,name=sys_drive_storage',
  sysLoad: 'io.yellowbrick:container=database,class=QUERY,name=sys_load',
  sysLock: 'io.yellowbrick:container=database,class=QUERY,name=sys_lock',
  sysLogQuery: 'io.yellowbrick:container=database,class=QUERY,name=log_query',
  sysLogSession: 'io.yellowbrick:container=database,class=QUERY,name=sys_log_session',
  sysQuery: 'io.yellowbrick:container=database,class=QUERY,name=sys_query',
  sysReclaimableSpace: 'io.yellowbrick:container=database,class=QUERY,name=reclaimable_space',
  sysSchema: 'io.yellowbrick:container=database,class=QUERY,name=sys_schema',
  sysSession: 'io.yellowbrick:container=database,class=QUERY,name=sys_session',
  sysTable: 'io.yellowbrick:container=database,class=QUERY,name=sys_table',
  sysTableDetailStorage: 'io.yellowbrick:container=database,class=QUERY,name=sys_table_detail_storage',
  sysTableDriveStorage: 'io.yellowbrick:container=database,class=QUERY,name=sys_table_drive_storage',
  sysTableGeneral: 'io.yellowbrick:container=database,class=QUERY,name=sys_table_general',
  sysTablePartition: 'io.yellowbrick:container=database,class=QUERY,name=table_partition',
  sysTableStorage: 'io.yellowbrick:container=database,class=QUERY,name=sys_table_storage',
  sysTableSummary: 'io.yellowbrick:container=database,class=QUERY,name=sys_table_summary',
  sysTableWorkerStorage: 'io.yellowbrick:container=database,class=QUERY,name=sys_table_worker_storage',
  sysUnload: 'io.yellowbrick:container=database,class=QUERY,name=sys_unload',
  systemTime: 'io.yellowbrick:container=database,class=QUERY,name=systemtime',
  usedPhysicalMemorySize: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=os.usedPhysicalMemorySize',
  usersPG: 'io.yellowbrick:container=database,class=ENTITY,name=pg_user',
  waitRate: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=series,name=lime.query.wait.rate',
  wlm: 'io.yellowbrick.lime/io.yellowbrick:container=lime,name=Workload Manager',
  wlmClassificationRule: 'io.yellowbrick:container=database,class=TABLE,name=wlm_classification_rule',
  wlmProfile: 'io.yellowbrick:container=database,class=TABLE,name=wlm_profile',
  wlmResourcePool: 'io.yellowbrick:container=database,class=TABLE,name=wlm_resource_pool',
  workerCpu: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=histograms,name=worker.cpu.avg',
  workerMemory: 'io.yellowbrick.lime/io.yellowbrick:container=lime,sub=statistics,type=histograms,name=worker.memory.avg',
  ybQuery: 'io.yellowbrick:container=database,class=QUERY,name=yb_query',
  ybQueryExecution: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_execution',
  ybQueryExecutionAnalyze: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_execution_analyze',
  ybQueryExecutionAnalyzeDetail: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_execution_analyze_detail',
  ybQueryPlanNode: 'io.yellowbrick:container=database,class=QUERY,name=yb_query_plan_node'
}

export const patterns = {
  blade: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,componentType=DM,*',
  fan: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,componentType=FAN,*',
  psu: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,componentType=PWR,*',
  cmp: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,componentType=CMP,*',
  leader: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,componentType=MGMT_NODE,name=*',
  interface: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,parentComponentType=MGMT_NODE,parentComponentName=*,componentType=INTERFACE,name=*',
  nvme: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,parentComponentType=MGMT_NODE,parentComponentName=*,componentType=NVME_DRIVE,name=*',
  chassis: 'io.yellowbrick.dcs/io.yellowbrick:container=dcs,sub=Components,componentType=CHASSIS,name=chassis*'
}

export const colorYbGreen = '#86BC4D'
export const colorYbYellow = '#FFCD32'
export const colorSeafoam = '#55ACBA'
export const colorSteel = '#4B5357'
export const colorSandbox = '#CF8737'
export const colorAirforce = '#1F4471'
export const colorMerlot = '#8E2E63'
export const colorPumpkin = '#F29C11'
export const colorSage = '#2E7A50'
export const colorPlum = '#412250'
export const colorLava = '#D34A24'
export const colorBluebird = '#1C76E6'

export const fgDark = '#4A4A4A'
export const fgLight = '#F7F7F7'

export const seriesColors = [
  { bg: colorYbGreen, fg: fgLight },
  { bg: colorYbYellow, fg: fgDark },
  { bg: colorSeafoam, fg: fgDark },
  { bg: colorSteel, fg: fgLight },
  { bg: colorSandbox, fg: fgLight },
  { bg: colorAirforce, fg: fgLight },
  { bg: colorMerlot, fg: fgLight },
  { bg: colorPumpkin, fg: fgLight },
  { bg: colorSage, fg: fgLight },
  { bg: colorPlum, fg: fgLight },
  { bg: colorLava, fg: fgLight },
  { bg: colorBluebird, fg: fgLight }
]

export const rowsetLimit = 100 * 1000

// Internal build?  It's either running on (1) localhost, (2) yellowbrickcloud.com or (3) yellowbrick.io
const hostname = window?.location?.hostname || 'unknown'
const internalHost =
  hostname === 'localhost' ||
  hostname.match(/\.yellowbrickcloud\.com$/) ||
  hostname.match(/\.yellowbrick\.io$/)
export { internalHost }
