import { make } from '../pathify'

import { Logger } from '@/util'
import queryDetailService, { EMPTY_QUERY } from '@/services/queryDetailService'
import queryStatService from '@/services/queryStatService'
import { connectionService } from '@/services'

const log = Logger.get('yb-state.query-details')

const state = {
  loading: false,
  selectedQuery: Object.assign({}, EMPTY_QUERY),
  settings: {
    highlightBy: 'rows_actual',
    orientation: 'horizontal'
  }
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state)
  },
  actions: {
    async populate({ commit, state, dispatch }, { instance, query_id, ybSystemDebug, historical, force }) {
      if (!instance) {
        return
      }
      query_id = parseInt(query_id, 10)

      // Already using this query id?
      if (!query_id) {
        log.debug('Query not provided', query_id)
        return
      } else if (!force && query_id === state.selectedQuery.query_id) {
        log.debug('Already populated query', query_id)
        return
      }
      commit('loading', true)
      try {
        // Setup empty query state.
        let query = Object.assign({}, EMPTY_QUERY, { query_id })
        if (!force) {
          commit('selectedQuery', Object.freeze(Object.assign({}, query)))
        }

        // Get the query details
        await connectionService.connect(instance.id)
        query = await queryDetailService.getDetails(query_id, ybSystemDebug, historical)

        // Get query text if it looks like we need it.
        const queryText = String(query.query_text)
        if (queryText.match(/\.\.\.$/) || (queryText.length === 60000 && !query.query_text_retrieved)) {
          // Use non-blocking retrieval here.
          query.query_text_retrieved = true
          queryStatService.getQueryText(query_id)
            .then((query_text) => {
              dispatch('updateQueryText', { query_id, query_text })
            })
        }

        // Commit the query.
        commit('selectedQuery', Object.freeze(query))
      } finally {
        commit('loading', false)
      }
    },
    invalidate({ commit, state }) {
      commit('loading', true)
      try {
        // Setup empty query state.
        const query = Object.assign({}, EMPTY_QUERY)
        commit('selectedQuery', Object.freeze(Object.assign({}, query)))
      } finally {
        commit('loading', false)
      }
    },
    updateQueryText({ commit, state }, { query_id, query_text }) {
      if (state.selectedQuery.query_id === query_id) {
        const query = Object.freeze(Object.assign({}, state.selectedQuery, { query_text }))
        commit('selectedQuery', query)
      }
    }
  }
}
