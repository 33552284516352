import { reactive } from 'vue'
import { internalHost } from './constants'

const toggles = reactive({
  showUiPlaceholders: false,
  databaseFunctions: false,
  databaseCreateObjects: false,
  clusterDemoData: false,
  clusterEventsView: false,
  createInstanceExternalStorage: internalHost,
  createInstanceTypeSelectable: internalHost && false,
  shutdownInstance: false,
  queryDevStats: false,
  clusterHardwareChange: false,
  configuration: false,
  executionTimelineDebug: false,
  executionTimelineStartEmpty: false,
  executionTimelineCacophonize: false,
  workloadManagementDebug: false,
  accessControlDebug: false,
  clusterDebug: false,
  clusterOversubscribe: false,
  newConfiguration: false,
  injectCreateClusterError: false,
  boom: false,
  allowCreateInstanceFromInstanceLogin: true,
  licenseForceEmpty: false,
  licenseForceTrial: false,
  licenseForceTrialExpired: false,
  infrastructureAdmin: true
})

export default toggles
