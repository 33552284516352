export function indexBy(arr, keyOrFn) {
  if (typeof keyOrFn !== 'function') {
    const key = keyOrFn
    keyOrFn = value => value[key]
  }
  return arr.reduce(function(obj, value) {
    obj[keyOrFn(value)] = value
    return obj
  }, {})
}

export function groupBy(arr, keyOrFn) {
  if (typeof keyOrFn !== 'function') {
    const key = keyOrFn
    keyOrFn = value => value[key]
  }
  return arr.reduce(function(obj, value) {
    const key = keyOrFn(value)
    obj[key] = obj[key] ?? []
    obj[key].push(value)
    return obj
  }, {})
}

export function map(objOrArray, fn) {
  if (!objOrArray) {
    return
  }
  if (Array.isArray(objOrArray)) {
    return objOrArray.map(fn)
  } else {
    const result = {}
    for (const [key, value] of Object.entries(objOrArray)) {
      result[key] = fn(value, key)
    }
    return result
  }
}

export function sortBy(arr, key) {
  return arr.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
}

// Source: https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality
export function isEqualSets(a, b) {
  if (a === b) { return true }
  if (a.size !== b.size) { return false }
  for (const value of a) { if (!b.has(value)) { return false } }
  return true
}

export function setDifference(arr1, arr2, keyOrFn) {
  const m1 = indexBy(arr1, keyOrFn)
  const m2 = indexBy(arr2, keyOrFn)

  const k1 = new Set(Object.keys(m1))
  const r = []
  Object.entries(m2).forEach(([k, v]) => {
    if (!k1.has(k)) {
      r.push(v)
    }
  })

  return r
}

export function timeout(ms) {
  return new Promise((resolve, _reject) => {
    window.setTimeout(resolve, ms)
  })
}

const pathRE = /^(.*\/)([^/]*)$/
export function parsePath(path) {
  const parsed = pathRE.exec(path)
  return { path: parsed[1], name: parsed[2] }
}

export const sortByName = (d1, d2) => d1?.name?.localeCompare(d2?.name)

// Inspired by: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_keyBy
export function reduceIndexBy(key) {
  return (r, x) => ({ ...r, [key ? x[key] : x]: x })
}

export function reduce(objOrArray, fn, initial) {
  if (!objOrArray) {
    return initial
  }
  if (Array.isArray(objOrArray)) {
    return objOrArray.reduce(fn, initial)
  } else {
    return Object.entries(objOrArray).reduce((n, [k, v]) => fn(n, v, k), initial)
  }
}

export function min(objOrArray) {
  if (Array.isArray(objOrArray)) {
    return Math.min(...objOrArray)
  }
  return reduce(objOrArray, Math.min, Number.MAX_VALUE)
}

export function max(objOrArray) {
  if (Array.isArray(objOrArray)) {
    return Math.max(...objOrArray)
  }
  return reduce(objOrArray, Math.max, Number.MIN_VALUE)
}

export function sum(objOrArray) {
  return reduce(objOrArray, (s, v) => s + v, 0)
}

export function mean(objOrArray, accessorFn) {
  if (!objOrArray) {
    return NaN
  }
  let sum
  let count = 0
  if (!accessorFn) {
    accessorFn = function(d) {
      return d
    }
  }
  if (Array.isArray(objOrArray)) {
    count = objOrArray.length
    sum = objOrArray.reduce((s, v) => s + accessorFn(v), 0)
  } else {
    count = Object.keys(objOrArray).length
    sum = Object.values(objOrArray).reduce((s, v) => s + accessorFn(v), 0)
  }
  return count !== 0 ? sum / count : 0
}
