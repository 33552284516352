import { functions } from '@/util'
import { gettext, $gettext } from '@/services'

export const queryNodeTypes = {
  AggHashNode: $gettext('Group By'),
  AggNode: $gettext('Aggregate'),
  AggSimpleNode: $gettext('Aggregate'),
  AggSortedNode: $gettext('Aggregate Sorted'),
  AppendNode: $gettext('Append'),
  AppendScanNode: $gettext('Table Scan'),
  BackupNode: $gettext('Backup'),
  CountNode: $gettext('Count'),
  DataInputNode: $gettext('Data Input'),
  DebugDumpNode: $gettext('Debug Dump'),
  DebugPauseMonkeyNode: $gettext('Pause Monkey'),
  DistributeNode: $gettext('Distribute'),
  DistributeHashNode: $gettext('Distribute'),
  DistributeMapNode: $gettext('Distribute On'),
  DistributeSortMergeNode: $gettext('Distribute Merge'),
  EmptyScanNode: $gettext('Empty Scan'),
  ExpressionNode: $gettext('Expression'),
  FilterExpressionNode: $gettext('Filter'),
  FromPostgresNode: $gettext('From Row Store'),
  HashBuildNode: $gettext('Hash Build'),
  BuildNode: $gettext('Build'),
  HashEquiJoinNode: $gettext('Hash Join'),
  EquiJoinNode: $gettext('Join'),
  JoinNode: $gettext('Join'),
  LimitNode: $gettext('Limit'),
  BaseNode: $gettext('Base'),
  LoopJoinNode: $gettext('Loop Join'),
  ModifyTableNode: $gettext('Modify Table'),
  PartitionNode: $gettext('Partition'),
  PackerNode: $gettext('Packer'),
  PacketMixerNode: $gettext('Packet Mixer'),
  RestoreNode: $gettext('Restore'),
  SequenceNode: $gettext('Sequence'),
  SortNode: $gettext('Sort'),
  SortNode2: $gettext('Sort'),
  SortMergeNode: $gettext('Sort Merge'),
  SortLimitNode: $gettext('Sort Limit'),
  TableScanNode: $gettext('From Column Store'),
  SubqueryScanNode: $gettext('Subquery Table Scan'),
  TempHashWriteNode: $gettext('Temp Hash Write'),
  TempTableScanNode: $gettext('Temp Table Scan'),
  TempTableWriteNode: $gettext('Write Temp Table'),
  ToPostgresNode: $gettext('To Row Store'),
  TransposeRowToColumnNode: $gettext('Transpose:Columns'),
  TransposeColumnToRowNode: $gettext('Transpose:Rows'),
  VirtualTableScanNode: $gettext('Virtual Table Scan'),
  WriteShardNode: $gettext('Write Shard'),
  WindowAggNode: $gettext('Window Aggregate'),
  MaintenanceNode: $gettext('Maintenance'),
  PGTableScanNode: $gettext('From Row Store'),
  TableScanCompositeNode: $gettext('Table Scan'),
  SingleThreadNode: $gettext('Synchronize'),
  SetOpNode: $gettext('Set Operation'),
  IntersectNode: $gettext('Intersect'),
  IntersectAllNode: $gettext('Intersect All'),
  ExceptNode: $gettext('Except'),
  ExceptAllNode: $gettext('Except All'),
  RepeatNode: $gettext('Repeat'),
  SequenceNode: $gettext('Sequence'),
  'ANTI JOIN': $gettext('Anti Join'),
  'ANTI LOOP JOIN': $gettext('Anti Loop Join'),
  APPEND: $gettext('Append'),
  'APPEND SCAN': $gettext('Table Scan'),
  BACKUP: $gettext('Backup'),
  BUILD: $gettext('Build'),
  'BUILD HASH': $gettext('Build Hash'),
  'BUILD SORT': $gettext('Build Sort'),
  'CROSS JOIN': $gettext('Cross Join'),
  'CROSS LOOP JOIN': $gettext('Cross Loop Join'),
  CTAS: $gettext('Create Table As'),
  DATA: $gettext('Data Input'),
  'DEBUG DUMP': $gettext('Debug Dump'),
  'DEBUG REFLOW': $gettext('Debug Reflow'),
  'DELETE FROM': $gettext('Delete From'),
  'DISTRIBUTE HASH': $gettext('Distribute Hash'),
  'DISTRIBUTE MERGE': $gettext('Distribute Merge'),
  'DISTRIBUTE RANDOM': $gettext('Distribute Random'),
  'DISTRIBUTE REPLICATE': $gettext('Distribute Replicate'),
  'DISTRIBUTE SINGLE': $gettext('Distribute Single'),
  'DISTRIBUTE SORT': $gettext('Distribute Sort'),
  EXCEPT: $gettext('Except'),
  'EXCEPT ALL': $gettext('Except All'),
  EXPRESSION: $gettext('Expression'),
  FILTER: $gettext('Filter'),
  'FULL OUTER JOIN': $gettext('Full Outer Join'),
  'FULL OUTER LOOP JOIN': $gettext('Full Outer Loop Join'),
  'GROUP BY': $gettext('Group By'),
  'GROUP BY PARTIAL': $gettext('Group By Partial'),
  'GROUP BY SPILL': $gettext('Group By Spill'),
  'HASH AGGREGATE SIMPLE': $gettext('Hash Aggregate'),
  'INNER JOIN': $gettext('Inner Join'),
  'INNER LOOP JOIN': $gettext('Inner Loop Join'),
  'INSERT INTO': $gettext('Insert Into'),
  INTERSECT: $gettext('Intersect'),
  'INTERSECT ALL': $gettext('Intersect All'),
  'LEFT OUTER JOIN': $gettext('Left Outer Join'),
  'LEFT OUTER LOOP JOIN': $gettext('Left Outer Loop Join'),
  LIMIT: $gettext('Limit'),
  MAINTENANCE: $gettext('Maintenance'),
  PARTITION: $gettext('Partition'),
  'PARTITION EXPRESSION': $gettext('Partition Expression'),
  REPEAT: $gettext('Repeat'),
  RESTORE: $gettext('Restore'),
  'RIGHT OUTER JOIN': $gettext('Right Outer Join'),
  'RIGHT OUTER LOOP JOIN': $gettext('Right Outer Loop Join'),
  SCAN: $gettext('Column Store Scan'),
  'SCAN EMPTY': $gettext('Scan Empty'),
  'SCAN ROW STORE': $gettext('Row Store Scan'),
  'SCAN TEMP': $gettext('Scan Temp'),
  'SCAN VIRTUAL': $gettext('Scan Virtual'),
  SELECT: $gettext('To Row Store'),
  SEQUENCE: $gettext('Sequence'),
  'SEMI JOIN': $gettext('Semi Join'),
  'SEMI LOOP JOIN': $gettext('Semi Loop Join'),
  'SINGLE THREAD': $gettext('Single Thread'),
  SORT: $gettext('Sort'),
  'SORT LIMIT': $gettext('Sort Limit'),
  TRANSPOSE: $gettext('Transpose'),
  UPDATE: $gettext('Update'),
  'WINDOW AGGREGATE': $gettext('Window Aggregate'),
  'WRITE HASH': $gettext('Write Hash Table'),
  'WRITE ROW STORE': $gettext('Write Row Store'),
  'WRITE TEMP': $gettext('Write Temp')
}

functions.forEach(queryNodeTypes, (v, k) => {
  gettext.translations.en[k] = v
})

export const queryStatNames = {
  io_read_count: $gettext('I/O Reads'),
  io_read_bytes: $gettext('I/O Reads (in bytes)'),
  io_write_count: $gettext('I/O Writes'),
  io_write_bytes: $gettext('I/O Writes (in bytes)'),
  io_spill_read_bytes: $gettext('Temp Reads (in bytes)'),
  io_spill_write_bytes: $gettext('Temp Writes (in bytes)'),
  io_network_count: $gettext('# of Network Calls'),
  io_network_bytes: $gettext('I/O Network (in bytes)'),
  packet_alloc_count: $gettext('Row Packets Allocated'),
  packet_free_count: $gettext('Row Packets Freed'),
  fromLeaderQDepth: $gettext('From Cluster Manager Queue Depth'),
  memory_planned_bytes: $gettext('Memory Planned'),
  memory_actual_bytes: $gettext('Memory Used'),
  rows: $gettext('Total Rows'),
  packets: $gettext('Total Packets'),
  ticks: $gettext('Time Active'),
  runtime_ms: $gettext('Time Active'),
  link_bytes: $gettext('Link Data'),
  link_rows: $gettext('Link Rows'),
  rows_planned: $gettext('Rows Planned'),
  rows_actual: $gettext('Rows Actual'),
  row_size_planned_bytes: $gettext('Row Size Planned'),
  row_size_actual_bytes: $gettext('Row Size Actual'),
  skew: $gettext('Skew'),
  read_efficiency: $gettext('Read Efficiency'),
  rows_from_column_store: $gettext('Rows from Column Store'),
  packets_from_column_store: $gettext('Packets from Column Store'),
  rows_from_row_store: $gettext('Rows from Row Store'),
  shards_planned: $gettext('Shards Planned'),
  shards_scanned: $gettext('Shards Scanned'),
  column_parts_planned: $gettext('Column Parts Planned'),
  column_parts_scanned: $gettext('Column Parts Scanned'),
  row_groups_planned: $gettext('Row Groups Planned'),
  row_groups_considered: $gettext('Row Groups Considered'),
  row_groups_used: $gettext('Row Groups Used'),
  iterations: $gettext('Iterations'),
  cache_efficiency: $gettext('Cache Efficiency')
}

functions.forEach(queryStatNames, (v, k) => {
  gettext.translations.en[k] = v
})
