import userService from './userService'

// We need localStorage as a thing
export const localStorage = (function() {
  const globals = { global: true, smcSystemDebug: true }
  function prefix(name) {
    let subkey
    if (name.match(/^global/)) {
      subkey = ''
    } else if (globals[name] || !userService.currentUser) {
      subkey = 'global.'
      globals[name] = true
    } else {
      subkey = userService.currentUser + '.'
    }
    return `YM.${subkey}${name}`
  }
  const proxy = !window.Proxy
    ? window.localStorage
    : new window.Proxy(window.localStorage, {
      get: function(target, name) {
        if (name.match(/clear/)) {
          return target[name].bind(target)
        } else if (name.match(/getItem|setItem|removeItem/)) {
          return function(arg1, arg2) {
            return target[name].call(target, prefix(arg1), arg2)
          }
        }
        return target[prefix(name)]
      },
      set: function(target, name, value) {
        target[prefix(name)] = value
        return true
      },
      has: function(target, name) {
        return !!target[prefix(name)]
      },
      deleteProperty: function(target, name) {
        return delete target[prefix(name)]
      }
    })
  return proxy
})()

export default localStorage
