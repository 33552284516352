import { functions } from '@/util'

let store, settings

class VuexLocalStorage {
  constructor(appName) {
    this._appName = appName || 'yb.settings'
    this._username = null
  }

  get appName() {
    return this._appName
  }

  set appName(_) {
    this._appName = _
  }

  get userName() {
    return this._username
  }

  set userName(_) {
    this._username = _
    this.restore()
  }

  get key() {
    return this._username ? `${this.appName}:${this._username}` : this.appName
  }

  plugin(_) {
    store = _

    // Restore the state.
    this.restore()

    // Listen for changes on the store.
    store.subscribe(this.persist.bind(this))
  }

  persist(mutation, state) {
    // Only care about mutation type "settings"
    if (mutation && !String(mutation.type).match(/settings/i)) {
      return
    }

    // Find only settings and copy them here.
    function extractSettings(s, target, hadSettings) {
      if (hadSettings > 10) {
        throw new Error('Settings nesting limit reached')
      }
      if (typeof s.settings !== 'undefined') {
        target.settings = s.settings
        hadSettings = 0
      } else if (hadSettings > 2) {
        return
      }
      for (const p in s) {
        if (p !== 'settings') {
          const obj = s[p]
          if (obj && typeof obj === 'object') {
            const sub = extractSettings(obj, {}, hadSettings + 1)
            if (sub) {
              target[p] = sub
            }
          }
        }
      }
      return target.settings ? target : null
    }
    settings = extractSettings(state, {}, 0)

    // Only persist if we are logged in.
    if (this._username) {
      window.localStorage.setItem(this.key, JSON.stringify(settings))
    }
  }

  restore() {
    // Restore persisted store settings.
    try {
      const storedSettings = window.localStorage.getItem(this.key)
      if (storedSettings) {
        settings = JSON.parse(storedSettings) || {}

        // Remove settings for dynamically registered modules.
        function removeMissingModules(s, path) {
          Object.entries(s).forEach(([key, value]) => {
            if (key !== 'settings') {
              if (value && !Array.isArray(value) && typeof value === 'object') {
                if (!store._modulesNamespaceMap[path + key + '/']) {
                  delete s[key]
                }
              }
            }
          })
          return s
        }
        const restoredSettings = removeMissingModules(functions.copy(settings), '')
        if (Array.isArray(restoredSettings?.['query-activity']?.settings?.selectedColumns)) {
          restoredSettings['query-activity'].settings.selectedColumns = { query: [], session: [], load: [], unload: [], backup: [], restore: [] }
        }
        if (Array.isArray(restoredSettings?.['query-activity']?.settings?.filters)) {
          restoredSettings['query-activity'].settings.filters = { query: [], session: [], load: [], unload: [], backup: [], restore: [] }
        }
        if (Array.isArray(restoredSettings?.['query-activity']?.settings?.filterWidgets)) {
          restoredSettings['query-activity'].settings.filterWidgets = { query: [], session: [], load: [], unload: [], backup: [], restore: [] }
        }
        if (Array.isArray(restoredSettings?.['query-activity']?.settings?.presets?.myPresets)) {
          restoredSettings['query-activity'].settings.presets.myPresets = { query: [], session: [], load: [], unload: [], backup: [], restore: [] }
        }
        if (Array.isArray(restoredSettings?.['query-activity']?.settings?.presets?.defaultPresets)) {
          restoredSettings['query-activity'].settings.presets.defaultPresets = { query: restoredSettings?.['query-activity']?.settings?.presets?.defaultPresets, session: [], load: [], unload: [], backup: [], restore: [] }
        }
        if (!restoredSettings?.['query-activity']?.settings?.order?.query) {
          restoredSettings['query-activity'].settings.order = {
            query: {
              column: 'submit_time',
              order: 'DESC'
            },
            session: {
              column: 'session_id',
              order: 'DESC'
            },
            load: {
              column: 'start_time',
              order: 'DESC'
            },
            unload: {
              column: 'start_time',
              order: 'DESC'
            },
            backup: {
              column: 'start_time',
              order: 'DESC'
            },
            restore: {
              column: 'start_time',
              order: 'DESC'
            }
          }
        }
        // Restore state.
        store.replaceState(functions.merge({}, store.state, restoredSettings))
      }
    } catch (e) {
      console.error('Could not process stored settings')
      console.error(e)
      settings = {}
    }
  }

  reset() {
    window.localStorage.removeItem(this.key)
    window.localStorage.removeItem('YM.theme')
    window.localStorage.removeItem('YM.auth')
    window.location.reload()
  }

  static restoreModule(moduleName) {
    // Restore the settings.
    const moduleSettings = settings[moduleName] && settings[moduleName].settings
    if (moduleSettings) {
      // Use vuex-pathify to patch in settings here.
      store.set(moduleName + '/settings', moduleSettings)
    }
  }
}

export default new VuexLocalStorage()
