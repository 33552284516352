<template>
  <div class="yb-view">
    <div class="yb-view-left p-8" v-if="icon">
      <yb-icon :icon="icon" class="w-10 h-10 text-yb-bluebird dark:text-yb-blue-light" />
    </div>
    <div class="yb-view-content pt-8 pr-8">
      <slot />
      <div v-if="message" v-html="message" />
    </div>

    <div class="yb-view-footer flex flex-row justify-center pt-8 pb-4">
      <div class="button-group flex flex-row justify-center space-x-3">
        <yb-button
          v-for="(buttonLabel, index) in buttonLabels"
          :key="index"
          :label="buttonLabel"
          :class="index === buttonLabels.length - 1 ? 'yb-button-primary-xl' : 'yb-button-default-xl'"
          :data-test-id="'prompt-' + buttonLabel"
          @click="onClick(index)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  message: string,
  icon?: string,
  buttonLabels: string[],
  buttonChoice: number[],
  dialogController?: any
}>(), {
  icon: 'question'
})

const onClick = (index: number) => {
  props.buttonChoice[0] = index
  if (props.dialogController) {
    props.dialogController.close(index)
  }
}

</script>
