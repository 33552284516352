import localStorageService from './localStorageService'

let _baseUrl
const _proxy = '/instance'

/**
 * Configure logger.
 */
const debug = String(window.location.search).match(/debug/)
const logger = debug
  ? console
  : {
      log() {},
      error() {}
    }

// Loading interceptor support.
let loading
let loadingInterceptor = (value) => {}

// DEV remaps.
const devRemapShown = new Set()
const devRemap = (function devRemapImpl(devRemapConfig) {
  if (devRemapConfig) {
    const devRemap = JSON.parse(devRemapConfig)
    console.log('REMAP: config', devRemap)
    return (url) => {
      for (const [target, remap] of Object.entries(devRemap)) {
        if (url.includes(target)) {
          if (!devRemapShown.has(target)) {
            console.log('REMAP:', target, remap)
            devRemapShown.add(target)
          }
          return url.replace(target, remap)
        }
      }
      return url
    }
  }

  return url => url
})(import.meta.env.VITE_URL_REMAP)

export default {
  get: function() {
    let result = ''
    const configuredUrl = _baseUrl || localStorageService.getItem('url') || null
    if (configuredUrl && (configuredUrl === '' || configuredUrl.match(/^(instance|http|sql)/))) {
      result = configuredUrl
    }
    if (!result.endsWith('/')) {
      result += '/'
    }
    return result
  },

  set: function(_) {
    _baseUrl = _
    localStorageService.setItem('url', _)
  },

  proxy(_) {
    return _proxy
  },

  url(url) {
    return this.proxify(this.get(), url)
  },

  proxify(base, url) {
    let result = base + url
    logger.log(`proxify [${result}]?`)

    // HACK!  For dev only, we can support remapping some hosts for testing/hacking.
    result = devRemap(result)

    // If we're blending a proxy.
    if (_proxy) {
      let endpoint = ''

      // Proxy is enabled?
      const proxyEnabled = import.meta.env.VITE_PROXY_DISABLED === undefined || import.meta.env.VITE_PROXY_DISABLED === 'false'

      // Proxy is relative?
      if (!_proxy.match(/^http/) && proxyEnabled) {
        endpoint = import.meta.env.VITE_GRAPHQL_ENDPOINT || ''
        if (!!endpoint && endpoint.match(/\//)) {
          endpoint = endpoint.replace(/[^/]*$/, '')
          if (endpoint.endsWith('/')) {
            endpoint = endpoint.substring(0, endpoint.length - 1)
          }
        }
      }

      function equalOrigins(u1, u2) {
        try {
          const url1 = new URL(u1)
          const url2 = new URL(u2)
          return url1.origin === url2.origin
        } catch (e) {
          return false // One URL is bad, so false
        }
      }

      // Compare endpoint URL origin with our origin, and see if they match and need to proxy.
      logger.log(`comparing endpoint: [${result}] to window origin: [${window.location.origin}] for proxification via endpoint [${endpoint}]`)
      if (result.match(/^(http|instance)/) && !equalOrigins(result, window.location.origin) && !equalOrigins(result, endpoint)) {
        if (proxyEnabled) {
          result = `${endpoint}${_proxy}?target=${encodeURIComponent(result)}`
        }
        logger.log('Returning proxified', result)
      } else {
        logger.log('Returning NON-proxified', result)
      }
    } else {
      logger.log('Proxy not configured')
    }

    return result
  },

  setLoadingInterceptor(_) {
    loadingInterceptor = _
  },

  get loading() {
    return loading
  },

  set loading(_) {
    loading = _
    loadingInterceptor(loading)
  },

  get baseUrl() {
    return _baseUrl
  }
}
