import InstanceModel from './InstanceModel'

export default class Role extends InstanceModel {
  static entity = 'role'

  static primaryKey = ['instance_id', 'id']

  static fields () {
    return {
      ...super.fields(),
      id: this.number(null).nullable(),
      __type: this.attr(null), // set at populate time

      // Combo attributes between user/role.  TODO: separate objects?

      // Role
      create_role: this.boolean(false).nullable(),
      create_database: this.boolean(false).nullable(),
      superuser: this.boolean(false).nullable(),

      // User
      search_path: this.string(null).nullable(),
      default_cluster: this.string(null).nullable(),
      memberof: this.attr(null).nullable(),
      has_local_password: this.boolean(false).nullable(),
      ldap_synchronized: this.string(null).nullable(),

      is_member: this.boolean(null).nullable(),
      can_admin: this.boolean(false).nullable()
    }
  }
}
