import apollo from '@/apollo'
import CLOUD_PROVIDER_METADATA from "@/graphql/cloudProviderMetadata.gql"
import LICENSE_INFO from "@/graphql/licenseInfo.gql"
import LICENSE_INFO_ALL from "@/graphql/licenseInfoAll.gql"
import LICENSE_INSTALL from "@/graphql/licenseInstall.gql"
import LICENSE_DELETE from '@/graphql/licenseDelete.gql'
import CONFIGURATION from "@/graphql/configuration.gql"
import INSTANCES_CONFIGURED from "@/graphql/instancesConfigured.gql"
import OPERATOR_CONFIGURATION from "@/graphql/operatorConfiguration.gql"
import UPDATE_OPERATOR_CONFIGURATION from "@/graphql/operatorConfigurationUpdate.gql"
import MANAGER_CONFIGURATION from "@/graphql/managerConfiguration.gql"
import UPDATE_MANAGER_CONFIGURATION from "@/graphql/managerConfigurationUpdate.gql"
import OAUTH2_CONFIGURATION from "@/graphql/oauth2Configuration.gql"
import UPDATE_OAUTH2_CONFIGURATION from "@/graphql/oauth2ConfigurationUpdate.gql"
import METERING_STATUS from "@/graphql/meteringStatus.gql"
import METERING_TRIM from "@/graphql/meteringTrim.gql"
import { errorHandler } from '@/services'

class AccountService {

  async getCloudProviderMetadata() {
    try {
      const response = await apollo.query({ query: CLOUD_PROVIDER_METADATA })
      const { data: { cloudProviderMetadata } } = response
      return cloudProviderMetadata

    } catch (e) {
      errorHandler.thrown(e, 'Could not query for cloud provider metadata')
      return null
    }
  }

  async getAnyLicenseInfo() {
    return this.getInstanceLicenseInfo(null)
  }

  async getAllLicenseInfo() {
    try {
      const response = await apollo.query({
        query: LICENSE_INFO_ALL
      })
      const { data: { allLicenseInfo } } = response
      return allLicenseInfo || []
    } catch (e) {
      errorHandler.thrown(e, 'Could not query for license info')
      return null
    }
  }

  async getInstanceLicenseInfo(instanceId) {
    try {
      const response = await apollo.query({
        query: LICENSE_INFO,
        variables: {
          instanceId
        }
      })
      const { data: { licenseInfo } } = response
      return licenseInfo
    } catch (e) {
      errorHandler.thrown(e, 'Could not query for instance license info')
      return null
    }
  }

  getConfiguration(keyFilter) {
    return apollo.query({
      query: CONFIGURATION,
      variables: {
        keyFilter
      }
    })
  }

  getInstancesConfigured() {
    return apollo.query({
      query: INSTANCES_CONFIGURED
    })
  }

  async installLicense(license) {
    const response = await apollo.mutate({
      mutation: LICENSE_INSTALL,
      variables: {
        license
      }
    })
    const {
      data
    } = response
    return data
  }

  async deleteLicense(namespace, name) {
    const response = await apollo.mutate({
      mutation: LICENSE_DELETE,
      variables: {
        namespace,
        name
      }
    })
    const {
      data
    } = response
    return data
  }

  async getOperatorConfiguration() {
    try {
      const response = await apollo.query({
        query: OPERATOR_CONFIGURATION
      })
      const { data: { operatorConfiguration } } = response
      return operatorConfiguration || {}
    } catch (e) {
      errorHandler.thrown(e, 'Could not query for operator configuration')
      return null
    }
  }

  async setOperatorConfiguration(configuration) {
    const response = await apollo.mutate({
      mutation: UPDATE_OPERATOR_CONFIGURATION,
      variables: {
        configuration
      }
    })
    const {
      data
    } = response
    return data
  }

  async getManagerConfiguration() {
    try {
      const response = await apollo.query({
        query: MANAGER_CONFIGURATION
      })
      const { data: { managerConfiguration } } = response
      return managerConfiguration
    } catch (e) {
      errorHandler.thrown(e, 'Could not query for manager configuration')
      return null
    }
  }

  async setManagerConfiguration(managerConfiguration) {
    const response = await apollo.mutate({
      mutation: UPDATE_MANAGER_CONFIGURATION,
      variables: {
        configuration: managerConfiguration.configuration,
        allowlistIpCidrs: managerConfiguration.allowlistIpCidrs
      }
    })
    const {
      data
    } = response
    return data
  }

  async getOAuth2Configuration() {
    try {
      const response = await apollo.query({
        query: OAUTH2_CONFIGURATION
      })
      const { data: { oauth2Configuration } } = response
      return oauth2Configuration || {}
    } catch (e) {
      errorHandler.thrown(e, 'Could not query for single sign-on configuration')
      return null
    }
  }

  async setOAuth2Configuration(configuration) {
    const response = await apollo.mutate({
      mutation: UPDATE_OAUTH2_CONFIGURATION,
      variables: {
        configuration
      }
    })
    const {
      data
    } = response
    return data
  }

  async getMeteringStatus() {
    try {
      const response = await apollo.query({
        query: METERING_STATUS
      })
      const { data: { meteringStatus } } = response
      return meteringStatus || {}
    } catch (e) {
      errorHandler.thrown(e, 'Could not query for metering status')
      return null
    }
  }

  async operatorMeteringTrim(before) {
    const response = await apollo.mutate({
      mutation: METERING_TRIM,
      variables: {
        before
      }
    })
    const {
      data
    } = response
    return data
  }
}

const accountService = new AccountService()
export default accountService
