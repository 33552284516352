<template>
  <div class="yb-markdown prose max-w-none" v-html="markup" />
</template>

<script>
import MarkdownIt from 'markdown-it'
import MarkdownItAbbreviation from 'markdown-it-abbr'
import MarkdownItDeflist from 'markdown-it-deflist'
import MarkdownItEmoji from 'markdown-it-emoji'
import MarkdownItFootnote from 'markdown-it-footnote'
import MarkdownItInsert from 'markdown-it-ins'
import MarkdownItLinkAttributes from 'markdown-it-link-attributes'
import MarkdownItMark from 'markdown-it-mark'
import MarkdownItPrism from 'markdown-it-prism'
import MarkdownItReplaceLink from 'markdown-it-replace-link'
import MarkdownItSourceMap from 'markdown-it-source-map'
import MarkdownItSubscript from 'markdown-it-sub'
import MarkdownItSuperscript from 'markdown-it-sup'
import MarkdownItTasklists from 'markdown-it-task-lists'
import { fixups } from './prism'

export default {
  props: {
    source: {
      type: String,
      required: true
    },
    relativeUrl: String
  },
  data() {
    return {
      markup: null
    }
  },
  computed: {
    normalizedRelativeUrl() {
      let result = this.relativeUrl
      if (!!result && !result.endsWith('/')) {
        result += '/'
      }
      return result
    }
  },
  watch: {
    source(_) {
      this.update(_)
    }
  },
  mounted() {
    this.md = new MarkdownIt({
      linkify: true,
      typographer: true,
      replaceLink: this.replaceLink.bind(this)
    })
      .use(MarkdownItAbbreviation)
      .use(MarkdownItDeflist)
      .use(MarkdownItEmoji)
      .use(MarkdownItFootnote)
      .use(MarkdownItInsert)
      .use(MarkdownItLinkAttributes, {
        attrs: {
          className: 'yb-link',
          target: '_blank',
          rel: 'noopener'
        }
      })
      .use(MarkdownItMark)
      .use(MarkdownItPrism, {
        init(prism) {
          fixups(prism)
        }
      })
      .use(MarkdownItReplaceLink)
      .use(MarkdownItSourceMap)
      .use(MarkdownItSubscript)
      .use(MarkdownItSuperscript)
      .use(MarkdownItTasklists)
    this.update(this.source)
  },
  methods: {
    update(_) {
      this.markup = this.md.render(_)
    },
    replaceLink(link, env) {
      if (!this.normalizedRelativeUrl || String(link).match(/http[s]?:/)) {
        return link
      } else {
        return this.normalizedRelativeUrl + link
      }
    }
  }
}

</script>

<style scoped lang="postcss">

.yb-markdown {
  @apply yb-body-text;
}

.yb-markdown :deep(h1) {
  margin-top: 0.9em;
  margin-bottom: 0.9em;
  @apply text-2xl;
  @apply font-semibold;
  @apply yb-body-text;
}

.yb-markdown :deep(h2) {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  @apply text-xl;
  @apply font-semibold;
  @apply yb-body-text;
}

.yb-markdown :deep(h3) {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
  @apply text-lg;
  @apply font-semibold;
  @apply yb-body-text;
} /*font-size: 1.4em;*/

.yb-markdown :deep(h4) {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  @apply text-base;
  @apply font-semibold;
  @apply yb-body-text;
}

.yb-markdown :deep(a) {
  @apply yb-link;
}

.yb-markdown :deep(code[class*="language-"]),
.yb-markdown :deep(pre[class*="language-"]) {
  @apply bg-yb-gray-fainter text-yb-gray-dark text-sm;
  text-shadow: initial;
  font-size: 0.85rem;
}

.dark .yb-markdown :deep(code[class*="language-"]),
.dark .yb-markdown :deep(pre[class*="language-"]) {
  @apply bg-yb-gray-mud text-yb-gray-faint;
}

.yb-markdown :deep(pre[class*="language-"]) {
  @apply px-4 py-0;
}

.yb-markdown :deep(.prose),
.yb-markdown :deep(.prose) strong {
  @apply yb-body-text;
}

</style>
