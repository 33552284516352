import { make } from '../pathify'

const state = {
  settings: {
    tabs: [],
    tab: null
  },
  load: null
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations: {
    ...mutations
  },
  getters: {
  },
  actions: {
  }
}
