import Instance from './Instance'
import OwnerModel from './OwnerModel'
import Storage from './Storage'
import Format from './Format'

export default class Location extends OwnerModel {
  static entity = 'externalLocation'

  static primaryKey = ['instance_id', 'external_location_id']

  static fields () {
    return {
      ...super.fields(),
      external_location_id: this.number(null).nullable(),
      __type: this.attr('external_location'),
      external_storage_id: this.number(null).nullable(),
      external_format_id: this.number(null).nullable(),
      path: this.string(null).nullable(),
      usage_type: this.string(null).nullable(),
      creation_time: this.string(null).nullable(),
      modify_time: this.string(null).nullable(),
      can_alter: this.boolean(false).nullable(),
      can_drop: this.boolean(false).nullable(),
      can_usage: this.boolean(false).nullable()
    }
  }

  get id() {
    return this.external_location_id
  }

  get storage() {
    return Storage.query().where('instance_id', this.instance_id).where('external_storage_id', this.external_storage_id).first()
  }

  get format() {
    return !!this.external_format_id && Format.query().where('instance_id', this.instance_id).where('external_format_id', this.external_format_id).first()
  }
}
