<template>
  <button :type="type" :class="buttonClass" :data-test-id="dataTestId" :disabled="disabled">
    <yb-icon v-if="icon" :class="iconClass" :icon="icon" />
    <span v-if="label">{{ label }}</span>
    <slot v-else />
  </button>
</template>

<script>
import YbButtonMixin from './YbButtonMixin'

export default {
  mixins: [YbButtonMixin],
  props: {
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>
