import Instance from './Instance'
import InstanceModel from './InstanceModel'

export default class WLMProfile extends InstanceModel {
  static entity = 'wlmProfile'

  static primaryKey = ['instance_id', 'name']

  static fields () {
    return {
      ...super.fields(),
      __type: this.attr('wlm_profile'),
      default_pool: this.string(null).nullable(),
      active: this.boolean(null).nullable(),
      system: this.boolean(null).nullable(),
      clusters: this.string(null).nullable(),
      pending_count: this.number(null).nullable(),
      isWLMProfileTreeOpen: this.boolean(false)
    }
  }
}
