import { make } from '../pathify'

const state = {
  settings: {
    user: {
      email: '',
      name: '',
      roles: null
    },
    instance: {
      id: null,
      name: null,
      consumerAddress: null
    }
  },
  loading: false,
  connection: 0
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  getters: {
    isLoggedIn: s => !!s.settings.user.token
  },
  mutations
}
