import vuexLocalstorage from './vuexLocalStorage'

// An singleton store for all to use.

let store
const observers = []
const storeService = {
  get() {
    if (!store) {
      throw new Error('Store has not been set')
    }
    return store
  },

  set(_) {
    // Record the store.
    store = _

    return Promise.resolve(_).then((_s) => {
      // Notify observers of store.
      observers.forEach(fn => fn(store))
    })
  },

  observe(fn) {
    observers.push(fn)
    if (store) {
      fn(store)
    }
  },

  registerModules(requireModules) {
    if ('keys' in requireModules) {
      // Regsiter all submodules.
      requireModules.keys().forEach((fileName) => {
        if (fileName === './index.js') {
          return
        }

        // filter fullstops and extension
        const moduleName = fileName.replace(/(\.\/|\.js)/g, '')

        // add this module.
        this.registerModule(moduleName, requireModules(fileName).default)
      })
    } else if (typeof requireModules === 'object') {
      for (const p in requireModules) {
        this.registerModule(p, requireModules[p].default || requireModules[p])
      }
    } else {
      throw new TypeError('Unknown type for StoreService::registerModules')
    }
  },

  registerModule(moduleName, storeModule) {
    // Register this module.
    this.get().registerModule(moduleName, storeModule, { namespaced: true, preserveState: false })
    vuexLocalstorage.restoreModule(moduleName)
  },

  get userName() {
    return vuexLocalstorage.userName
  },

  set userName(_) {
    vuexLocalstorage.userName = _
    store.set('global/settings@user.email', _)
  },

  get roles() {
    return vuexLocalstorage.roles
  },

  set roles(_) {
    vuexLocalstorage.roles = _
    store.set('global/settings@user.roles', _)
  }

}
export { storeService as default }
